<template lang="pug">
div.rounded-circle(v-if="app" @focusout="onFocusOut" ref="$container")
  div.position-relative
    button.language-btn.rounded-circle.px-4(@click="showDropDown = !showDropDown") {{ locale }} 
    div.drop-down.rounded-lg.overflow-hidden.border(v-if="showDropDown")
      EatUl.bg-white
        EatLi.d-flex(v-for="locale, i in app.availableLanguages" :key="i")
          button.flex-grow-1.text-start(@click="setNewLanguage(locale)") {{ locale.toUpperCase() }}
</template>

<script setup lang="ts">
import { useApp } from "@/store/app";
import { ref } from "vue";
import { setNewLanguage } from "@/utils/localization";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const { app } = useApp();
const locale = ref(i18n.locale.value.toUpperCase());

const $container = ref<HTMLElement>();
const onFocusOut = (e: FocusEvent) => {
	if ($container.value?.contains(e.relatedTarget as Node)) return;
  showDropDown.value = false;
}

const showDropDown = ref(false);
</script>

<style lang="scss" scoped>
.language-btn {
  aspect-ratio: 1/1;
}
.drop-down {
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
}
</style>
