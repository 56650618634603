<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0.4 0.5 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-/-Navigation-24px-/-Grey-/-Close" fill="currentColor">
        <path
          d="M12,1 C12.4903007,1 12.894909,1.38142169 12.9542552,1.87441657 L12.9617486,1.99983357 L12.961,11.038 L22.0001664,11.0382514 C22.5523593,11.0382514 23,11.4688409 23,12 C23,12.4903007 22.6185783,12.894909 22.1255834,12.9542552 L22.0001664,12.9617486 L12.961,12.961 L12.9617486,22.0001664 C12.9617486,22.5523593 12.5311591,23 12,23 C11.5096993,23 11.105091,22.6185783 11.0457448,22.1255834 L11.0382514,22.0001664 L11.038,12.961 L1.99983357,12.9617486 C1.44764074,12.9617486 1,12.5311591 1,12 C1,11.5096993 1.38142169,11.105091 1.87441657,11.0457448 L1.99983357,11.0382514 L11.038,11.038 L11.0382514,1.99983357 C11.0382514,1.44764074 11.4688409,1 12,1 Z"
          id="Combined-Shape"
          transform="translate(11, -7) rotate(45.000000) "
        ></path>
      </g>
    </g>
  </svg>
</template>
