<template>
  <svg
    width="20px"
    height="22px"
    viewBox="0 0 20 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero" id="Vector">
        <path
          d="M18.6735783,5.47997594 C13.3835783,4.94997597 8.10357761,4.74997628 2.87357759,5.27997625 L0.833577633,5.47997594 C0.413577646,5.51997594 0.0435776799,5.21997622 0.00357768079,4.79997623 C-0.0364223183,4.37997624 0.263577789,4.019976 0.673577785,3.979976 L2.71357775,3.77997622 C8.03357792,3.2399762 13.4235778,3.44997603 18.8235779,3.979976 C19.2335779,4.019976 19.533578,4.38997623 19.493578,4.79997623 C19.463578,5.18997622 19.1335782,5.47997594 18.7535782,5.47997594 C18.7335782,5.47997594 18.7035783,5.47997594 18.6735783,5.47997594 Z"
        ></path>
        <path
          d="M6.12464803,4.46000004 C5.72464803,4.39000004 5.4446484,4 5.5146484,3.5999999 L5.7346482,2.28999996 C5.89464819,1.32999998 6.11464787,0 8.44464779,0 L11.0646486,0 C13.4046485,0 13.6246487,1.37999994 13.7746487,2.29999995 L13.994648,3.5999999 C14.064648,4.0099999 13.7846483,4.40000004 13.3846483,4.46000004 C12.9746483,4.53000004 12.5846487,4.24999991 12.5246487,3.8499999 L12.3046484,2.54999995 C12.1646484,1.67999995 12.1346478,1.50999999 11.0746479,1.50999999 L8.45464802,1.50999999 C7.39464808,1.50999999 7.37464848,1.64999998 7.22464848,2.53999996 L6.99464798,3.84000015 C6.93464798,4.21000016 6.61464816,4.46999979 6.25464815,4.46999979 C6.21464815,4.46999979 6.17464804,4.47000004 6.12464803,4.46000004 Z"
        ></path>
        <path
          d="M6.54429293,21.5000753 C3.05429292,21.5000753 2.91429339,19.5700755 2.80429339,18.0100756 L2.15429329,7.94007498 C2.12429329,7.53007498 2.44429311,7.17007575 2.85429311,7.14007575 C3.27429309,7.12007575 3.6242933,7.43007556 3.6542933,7.84007555 L4.30429339,17.9100752 C4.41429339,19.4300752 4.45429301,20.0000753 6.54429293,20.0000753 L12.9642935,20.0000753 C15.0642934,20.0000753 15.1042942,19.4300752 15.2042942,17.9100752 L15.8542938,7.84007555 C15.8842938,7.43007556 16.2442931,7.12007575 16.6542931,7.14007575 C17.0642931,7.17007575 17.3842938,7.52007499 17.3542938,7.94007498 L16.7042942,18.0100756 C16.5942942,19.5700755 16.4542935,21.5000753 12.9642935,21.5000753 L6.54429293,21.5000753 Z"
        ></path>
        <path
          d="M8.08398438,16 C7.67398438,16 7.33398438,15.66 7.33398438,15.25 C7.33398438,14.84 7.67398438,14.5 8.08398438,14.5 L11.4139843,14.5 C11.8239843,14.5 12.1639843,14.84 12.1639843,15.25 C12.1639843,15.66 11.8239843,16 11.4139843,16 L8.08398438,16 Z"
        ></path>
        <path
          d="M7.25390625,12 C6.84390625,12 6.50390625,11.66 6.50390625,11.25 C6.50390625,10.84 6.84390625,10.5 7.25390625,10.5 L12.2539062,10.5 C12.6639062,10.5 13.0039062,10.84 13.0039062,11.25 C13.0039062,11.66 12.6639062,12 12.2539062,12 L7.25390625,12 Z"
        ></path>
      </g>
    </g>
  </svg>
</template>
