import config from "@/config";
import { io } from "socket.io-client";
import type { Router } from "vue-router";
import type { Auth } from "@/plugins/auth";
import type { APIError } from "@/api/API";
import type { Order } from "@/models/Order";
import { type TableData, TableStatus } from "@/api/Table";
import { useGlobalState } from "..";
import { useOrderActions } from "./order";
import { useTableActions } from "./table";

export const useSyncActions = () => {
  const stopAutoAPISync = () => {
    const {
      sync: { interval }
    } = useGlobalState();
    if (interval.value) {
      clearInterval(interval.value);
    }
    interval.value = null;
  };

  const stopSocketSync = () => {
    const {
      sync: { socket }
    } = useGlobalState();
    socket.value?.disconnect();
  };

  const stopSync = () => {
    stopAutoAPISync();
    stopSocketSync();
  };

  const syncData = async (router: Router, auth: Auth) => {
    const { table } = useGlobalState();
    const { setTableData } = useTableActions();
    const { setTableOrder } = useOrderActions();

    try {
      await setTableData(router, auth);
      if (table.value.data?.status !== TableStatus.AVAILABLE) {
        await setTableOrder(router, auth);
      }
    } catch (e) {
      const error = e as APIError;
      if (error?.response?.status == 403) {
        stopSync();
        router.push({ name: "serviceUnavailable" });
      } else {
        throw error;
      }
    }
  };

  const startAutoAPISync = async (router: Router, auth: Auth) => {
    const {
      sync: { interval, initialized }
    } = useGlobalState();
    interval.value = setInterval(async () => {
      await syncData(router, auth);
    }, 30000) as unknown as number;
    if (!initialized.value) {
      initialized.value = true;
      await syncData(router, auth);
    }
  };

  const startSocketSync = (router: Router, auth: Auth) => {
    const {
      table,
      sync: { socket }
    } = useGlobalState();
    const { updateTableData } = useTableActions();
    const { updateOrder } = useOrderActions();

    if (!table.value.id) return;
    socket.value = io(config.apiURL, {
      transports: ["websocket"],
      upgrade: false,
      query: {
        tableId: table.value.id
      },
      auth: {
        token: `Bearer ${auth.clientToken}`
      },
      autoConnect: false
    });

    socket.value.on("connect_error", err => console.log(`connect_error due to ${err.message}`));
    /* socket.value.on("connect", () => { console.log("is connected: " + socket.value?.connected); }); */

    socket.value.connect();

    socket.value.on("table-updated", (tableData: TableData) =>
      updateTableData(tableData, router, auth)
    );
    socket.value.on("order-updated", (order: Order) => {
      updateOrder(order, router, auth);
    });

    socket.value.on("disconnect", () => {
      socket.value?.removeAllListeners();
    });
  };

  const startSync = async (router: Router, auth: Auth) => {
    await startAutoAPISync(router, auth);
    startSocketSync(router, auth);
  };

  return {
    startAutoAPISync,
    stopAutoAPISync,
    startSocketSync,
    stopSocketSync,
    startSync,
    stopSync
  };
};
