<template lang="pug">
div.elevation-3.rounded-sm.bg-white.addToHomePopOver(v-if="showDialog")
  div.pa-4.bg-white.rounded-sm
    div.d-flex.align-center
      button.close-btn.flex-grow-0.d-flex.align-center.justify-center(@click='showDialog=false')
        EatIcon(icon="$close" :size="16")
      div.text-body-1.flex-grow-1.ms-2.d-flex.justify-center
        div.text-center(style="max-width: 30ch")
          span {{ $t("app.addToHomePopOver.text1")}}
          EatIcon.share-ios-icon(icon="$shareIOS").mx-2
          span {{ $t("app.addToHomePopOver.text2")}}
  div.tip.elevation-3.bg-white
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { LS, LSKeys } from "@/utils/localStorage";
import { isDeviceIOSAndBrowserSafari } from "@/utils/userAgentData";

const showDialog = ref(false);

onMounted(() => {
  showDialog.value =
    isDeviceIOSAndBrowserSafari &&
    !(
      // @ts-ignore
      window.navigator.standalone === true ||
      window.matchMedia("(display-mode: standalone)").matches
    ) &&
    !LS.get(LSKeys.ADD_TO_HOME_IOS_POP_OVER);
  LS.set(LSKeys.ADD_TO_HOME_IOS_POP_OVER, true);
});
</script>

<style lang="scss" scoped>
.addToHomePopOver {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 20px;
  z-index: 2;
  .close-btn {
    height: 30px;
    width: 30px;
  }
  .share-image {
    height: 20px;
    transform: translateY(10%);
  }
  .share-ios-icon {
    position: relative;
    top: 4px;
  }
  .tip {
    position: absolute;
    bottom: -10px;
    width: 20px;
    height: 20px;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%) rotate(-45deg);
  }
}
</style>
