import type { Category, Menu, Product } from "@/models";
import { Saletype } from "@/models/App";
import { computed, ref, type Ref } from "vue";
import { get as getMenu } from "@/api/Menu";

const menus = ref<Partial<Record<Saletype, Menu>>>({});
const priceLists = ref<Partial<Record<Saletype, string>>>({});
const searchText = ref("");
const selectedTags: Ref<string[]> = ref([]);

export const usePriceLists = () => {
  const setPriceList = (saletype: Saletype, priceList: string) => {
    priceLists.value[saletype] = priceList;
  };

  const getPriceList = (saletype: Saletype) => {
    return priceLists.value[saletype] || "";
  };

  return {
    setPriceList,
    getPriceList
  };
};

export const useMenuFilters = () => {
  const onSelectedTagsChange = (tags: string[]) => {
    selectedTags.value = tags;
  };
  const onSearchTextChange = (text: string) => {
    searchText.value = text.trim().toLowerCase();
  };

  const toggleSelectedTag = (tag: string) => {
    const index = selectedTags.value.findIndex(selectedTag => selectedTag === tag);
    if (index >= 0) {
      selectedTags.value.splice(index, 1);
    } else {
      selectedTags.value.push(tag);
    }
  };

  return {
    onSelectedTagsChange,
    onSearchTextChange,
    toggleSelectedTag,
    searchText: searchText as Readonly<typeof searchText>,
    selectedTags: selectedTags as Readonly<typeof selectedTags>
  };
};

export const useMenu = (saletype: Saletype | undefined) => {
  const { getPriceList } = usePriceLists();

  const categories = ref<Category[]>([]);
  const fetchMenu = async (delay = 0) => {
    if (!saletype) return;
    if (!menus.value[saletype]) {
      menus.value[saletype] = await getMenu(saletype, getPriceList(saletype));
    } else {
      await new Promise(resolve => setTimeout(() => resolve(true), delay));
    }
    categories.value = menus.value[saletype]?.categories || [];
  };

  const refreshMenu = async () => {
    if (!saletype) return;
    menus.value[saletype] = undefined;
    await fetchMenu();
  };

  const tags = computed(() => {
    const products = categories.value?.map(category => category.products).flat() || [];
    const tags = new Set(products.map(product => product.tags || []).flat());
    return Array.from(tags);
  });

  const productHasTags = (product: Product): boolean => {
    return selectedTags.value.every(tag => !!product.tags?.includes(tag)); //filtro per tags
  };

  const productHasText = (product: Product): boolean => {
    if (!searchText.value?.length) return true;
    if (product.name.toLowerCase().includes(searchText.value)) return true; //filtro per name
    if (product.description?.toLowerCase().includes(searchText.value)) return true; //filtro per description
    if (product.category.toLowerCase().includes(searchText.value)) return true; //filtro per category
    return false;
  };

  const filteredCategories = computed(() => {
    return categories.value.reduce((categories, category) => {
      const filteredProducts = category.products.filter(
        product => productHasTags(product) && productHasText(product)
      );

      if (filteredProducts.length) {
        const filteredCategory: Category = { ...category, products: filteredProducts };
        categories.push(filteredCategory);
      }

      return categories;
    }, [] as Category[]);
  });

  const getCategoriesByIds = (ids: string[]) => {
    return categories.value.filter(category => ids.includes(category.id));
  };

  return {
    categories,
    tags,
    filteredCategories,
    getCategoriesByIds,
    fetchMenu,
    refreshMenu
  };
};
