import { computePrice } from "@/models/Product";
import { ref } from "vue";
import { useCart } from "@/store/cart";
import { Saletype } from "@/models/App";

const orderTotal = ref(0);
const totalWithVariation = ref(0);

export function useTotal() {
  const { cart } = useCart();

  const computeOrderItemsTotal = () => {
    if (!cart.value) return 0;

    const itemsPricesArray = cart.value?.items.map(item => {
      const variationObject = item.variation;
      if (variationObject) {
        return variationObject.finalPrice;
      } else {
        const price = computePrice(item.product, item.configuration);
        return price * item.quantity;
      }
    });

    if (itemsPricesArray && itemsPricesArray.length) {
      return itemsPricesArray.reduce((prev, curr) => prev + curr, 0);
    } else {
      return 0;
    }
  };

  const computeTotalWithVariation = () => {
    if (!cart.value) return 0;
    let total = computeOrderItemsTotal();
    if (cart.value.saletype === Saletype.DELIVERY && cart.value.deliveryPrice) {
      total += cart.value.deliveryPrice.isPercentage
        ? Math.round((cart.value.deliveryPrice.price / 100) * total * 100) / 100
        : cart.value?.deliveryPrice.price;
    }
    if (cart.value.affiliationDiscount) {
      total += cart.value.affiliationDiscount.price;
    }
    if (cart.value.subtotalVariation) {
      total += cart.value.subtotalVariation.priceVariation;
    }
    return total;
  };

  return {
    totalWithVariation,
    orderTotal,
    computeOrderItemsTotal,
    computeTotalWithVariation
  };
}
