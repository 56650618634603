const colors = [
    {
        base: '#1abc9c',
        lighten: {
            '1': '#31c3a6',
            '2': '#48c9b0',
            '3': '#5fd0ba',
            '4': '#76d7c4',
            '5': '#8ddece'
        },
        darken: {
            '1': '#17a98c',	
            '2': '#15967d',	
            '3': '#12846d',	
            '4': '#10715e',	
            '5': '#0d5e4e'
        },
        contrast: '#ffffff',
        selected: '#e8f8f5'
    },
    {
        base: '#2ecc71',
        lighten: {
            '1': '#43d17f',
            '2': '#58d68d',
            '3': '#6ddb9c',
            '4': '#82e0aa',
            '5': '#97e6b8'
        },
        darken: {
            '1': '#29b866',
            '2': '#25a35a',
            '3': '#208f4f',
            '4': '#1c7a44',
            '5': '#176639'
        },
        contrast: '#000000',
        selected: '#eafaf1'
    },
    {
        base: '#3498db',
        lighten: {
            '1': '#48a2df',
            '2': '#5dade2',
            '3': '#71b7e6',
            '4': '#85c1e9',
            '5': '#9acced'
        },
        darken: {
            '1': '#2f89c5',
            '2': '#2a7aaf',
            '3': '#246a99',
            '4': '#1f5b83',
            '5': '#1a4c6e'
        },
        contrast: '#ffffff',
        selected: '#ebedef'
    },
    {
        base: '#9b59b6',
        lighten: {
            '1': '#a56abd',
            '2': '#af7ac5',
            '3': '#b98bcc',
            '4': '#c39bd3',
            '5': '#cdacdb'
        },
        darken: {
            '1': '#8c50a4',
            '2': '#7c4792',
            '3': '#6d3e7f',
            '4': '#5d356d',
            '5': '#4e2d5b'
        },
        contrast: '#ffffff',
        selected: '#f5eef8'
    },
    {
        base: '#34495e',
        lighten: {
            '1': '#485b6e',
            '2': '#5d6d7e',
            '3': '#71808e',
            '4': '#85929e',
            '5': '#9aa4af'
        },
        darken: {
            '1': '#2f4255',
            '2': '#2a3a4b',
            '3': '#243342',
            '4': '#1f2c38',
            '5': '#1a252f'
        },
        contrast: '#ffffff',
        selected: '#ebedef'
    },
    {
        base: '#16a085',
        lighten: {
            '1': '#2daa91',
            '2': '#45b39d',
            '3': '#5cbdaa',
            '4': '#73c6b6',
            '5': '#8bd0c2'
        },
        darken: {
            '1': '#149078',
            '2': '#12806a',
            '3': '#0f705d',
            '4': '#0d6050',
            '5': '#0b5043'
        },
        contrast: '#000000',
        selected: '#e8f6f3'
    },
    {
        base: '#27ae60',
        lighten: {
            '1': '#3db670',
            '2': '#52be80',
            '3': '#68c690',
            '4': '#7dcea0',
            '5': '#93d7b0'
        },
        darken: {
            '1': '#239d56',
            '2': '#1f8b4d',
            '3': '#1b7a43',
            '4': '#17683a',
            '5': '#145730'
        },
        contrast: '#000000',
        selected: '#e9f7ef'
    },
    {
        base: '#2980b9',
        lighten: {
            '1': '#3e8dc0',
            '2': '#5499c7',
            '3': '#69a6ce',
            '4': '#7fb3d5',
            '5': '#94c0dc'
        },
        darken: {
            '1': '#2573a7',
            '2': '#216694',
            '3': '#1d5a82',
            '4': '#194d6f',
            '5': '#15405d'
        },
        contrast: '#000000',
        selected: '#eaf2f8'
    },
    {
        base: '#8e44ad',
        lighten: {
            '1': '#9957b5',
            '2': '#a569bd',
            '3': '#b07cc6',
            '4': '#bb8fce',
            '5': '#c7a2d6'
        },
        darken: {
            '1': '#803d9c',
            '2': '#72368a',
            '3': '#633079',
            '4': '#552968',
            '5': '#472257'
        },
        contrast: '#ffffff',
        selected: '#f4ecf7'
    },
    {
        base: '#2c3e50',
        lighten: {
            '1': '#415162',
            '2': '#566573',
            '3': '#6b7885',
            '4': '#808b96',
            '5': '#969fa8'
        },
        darken: {
            '1': '#283848',
            '2': '#233240',
            '3': '#1f2b38',
            '4': '#1a2530',
            '5': '#161f28'
        },
        contrast: '#ffffff',
        selected: '#eaecee'
    },
    {
        base: '#f1c40f',
        lighten: {
            '1': '#f2ca27',
            '2': '#f4d03f',
            '3': '#f5d657',
            '4': '#f7dc6f',
            '5': '#f8e287'
        },
        darken: {
            '1': '#d9b00e',
            '2': '#c19d0c',
            '3': '#a9890b',
            '4': '#917609',
            '5': '#796208'
        },
        contrast: '#000000',
        selected: '#fef9e7'
    },
    {
        base: '#e67e22',
        lighten: {
            '1': '#e98b38',
            '2': '#eb984e',
            '3': '#eea564',
            '4': '#f0b27a',
            '5': '#f3bf91'
        },
        darken: {
            '1': '#cf711f',
            '2': '#b8651b',
            '3': '#a15818',
            '4': '#8a4c14',
            '5': '#733f11'
        },
        contrast: '#000000',
        selected: '#fdf2e9'
    },
    {
        base: '#bfaf80',
        lighten: {
            '1': '#c5b78d',
            '2': '#ccbf99',
            '3': '#d2c7a6',
            '4': '#d9cfb3',
            '5': '#dfd7c0'
        },
        darken: {
            '1': '#ac9e73',
            '2': '#998c66',
            '3': '#867a5a',
            '4': '#73694d',
            '5': '#605840'
        },
        contrast: '#000000',
        selected: '#f9f7f2'
    },
    {
        base: '#59323c',
        lighten: {
            '1': '#6a4750',
            '2': '#7a5b63',
            '3': '#8b7077',
            '4': '#9b848a',
            '5': '#ac999e'
        },
        darken: {
            '1': '#502d36',
            '2': '#472830',
            '3': '#3e232a',
            '4': '#351e24',
            '5': '#2d191e'
        },
        contrast: '#ffffff',
        selected: '#eeebec'
    },
    {
        base: '#f39c12',
        lighten: {
            '1': '#f4a62a',
            '2': '#f5b041',
            '3': '#f7ba59',
            '4': '#f8c471',
            '5': '#f9ce89'
        },
        darken: {
            '1': '#db8c10',
            '2': '#c27d0e',
            '3': '#aa6d0d',
            '4': '#925e0b',
            '5': '#7a4e09'
        },
        contrast: '#000000',
        selected: '#fef5e7'
    },
    {
        base: '#d35400',
        lighten: {
            '1': '#d7651a',
            '2': '#dc7633',
            '3': '#e0874d',
            '4': '#e59866',
            '5': '#e9aa80'
        },
        darken: {
            '1': '#be4c00',
            '2': '#a94300',
            '3': '#943b00',
            '4': '#7f3200',
            '5': '#6a2a00'
        },
        contrast: '#000000',
        selected: '#fbeee6'
    },
    {
        base: '#c0392b',
        lighten: {
            '1': '#c64d40',
            '2': '#cd6155',
            '3': '#d3746b',
            '4': '#d98880',
            '5': '#e09c95'
        },
        darken: {
            '1': '#ad3327',
            '2': '#9a2e22',
            '3': '#86281e',
            '4': '#73221a',
            '5': '#601d16'
        },
        contrast: '#ffffff',
        selected: '#f9ebea'
    },
    {
        base: '#8c6954',
        lighten: {
            '1': '#987865',
            '2': '#a38776',
            '3': '#af9687',
            '4': '#baa598',
            '5': '#c6b4aa'
        },
        darken: {
            '1': '#7e5f4c',
            '2': '#705443',
            '3': '#624a3b',
            '4': '#543f32',
            '5': '#46352a'
        },
        contrast: '#ffffff',
        selected: '#f4f0ee'
    },
    {
        base: '#260126',
        lighten: {
            '1': '#3c1a3c',
            '2': '#513451',
            '3': '#674d67',
            '4': '#7d677d',
            '5': '#938093'
        },
        darken: {
            '1': '#220122',
            '2': '#1e011e',
            '3': '#1b011b',
            '4': '#170117',
            '5': '#130113'
        },
        contrast: '#ffffff',
        selected: '#e9e6e9'
    },
    {
        base: '#000000',
        lighten: {
            '1': '#1a1a1a',
            '2': '#333333',
            '3': '#4d4d4d',
            '4': '#666666',
            '5': '#808080'
        },
        darken: {
            '1': '#000000',
            '2': '#000000',
            '3': '#000000',
            '4': '#000000',
            '5': '#000000'
        },
        contrast: '#ffffff',
        selected: '#e6e6e6'
    },
];

export function setupColors(primary: string, secondary: string) {

    let primaryColors = '';
    let selectedColors = '';
    let secondaryColors = '';

    const defaultPrimarySet = colors.find(colorSet => colorSet.base.toLowerCase() === '#f39c12');
    const primarySet = colors.find(colorSet => colorSet.base.toLowerCase() === primary?.toLowerCase()) || defaultPrimarySet;
    if(primarySet) {
        const primaryBase = `--clr-primary: ${primarySet.base};`
        const primaryLighten = Object.entries(primarySet.lighten || {}).map(([index, value]) => {
            return `--clr-primary--lighten-${index}: ${value};`;
        }).join('\n');
        const primaryDarken = Object.entries(primarySet.darken || {}).map(([index, value]) => {
            return `--clr-primary--darken-${index}: ${value};`;
        }).join('\n');
        const primaryContrast = `--clr-primary-contrast: ${primarySet.contrast};`;

        primaryColors = [primaryBase, primaryLighten, primaryDarken, primaryContrast].join('\n');

        const selectedBase = `--clr-selected: ${primarySet.selected};`
        const selectedLighten = Object.entries(primarySet.lighten || {}).map(([index]) => {
            return `--clr-selected--lighten-${index}: ${primarySet?.selected};`;
        }).join('\n');
        const selectedDarken = Object.entries(primarySet.darken || {}).map(([index]) => {
            return `--clr-selected--darken-${index}: ${primarySet.selected};`;
        }).join('\n');
        const selectedContrast = `--clr-selected-contrast: ${primarySet.base};`;
        selectedColors = [selectedBase, selectedLighten, selectedDarken, selectedContrast].join('\n');
    }

    const defaultSecondarySet = colors.find(colorSet => colorSet.base.toLowerCase() === '#2c3e50');
    const secondarySet = colors.find(colorSet => colorSet.base.toLowerCase() === secondary?.toLowerCase()) || defaultSecondarySet;
    if(secondarySet) {
        const secondaryBase = `--clr-secondary: ${secondarySet.base};`
        const secondaryLighten = Object.entries(secondarySet.lighten || {}).map(([index, value]) => {
            return `--clr-secondary--lighten-${index}: ${value};`;
        }).join('\n');
        const secondaryDarken = Object.entries(secondarySet.darken || {}).map(([index, value]) => {
            return `--clr-secondary--darken-${index}: ${value};`;
        }).join('\n');
        const secondaryContrast = `--clr-secondary-contrast: ${secondarySet.contrast};`;
        secondaryColors = [secondaryBase, secondaryLighten, secondaryDarken, secondaryContrast].join('\n');
    }

    const css = `:root { 
        ${primaryColors}
        ${selectedColors}
        ${secondaryColors}
    }`;

    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(style);
    
}
