<template>
  <svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd" transform="translate(-2 -2)">
      <path d="M0 0h24v24H0z" />
      <path
        d="M17 4a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h10zm2.6 6.399H4.4V16a2.6 2.6 0 0 0 2.436 2.595L7 18.6h10a2.6 2.6 0 0 0 2.595-2.436L19.6 16v-5.601zM17 5.4H7a2.6 2.6 0 0 0-2.595 2.436L4.4 8v.999h15.2V8a2.6 2.6 0 0 0-2.436-2.595L17 5.4z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
