import { ref, type Ref } from "vue";
import type { AxiosError } from "axios";

export enum TypeSnackbar {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WARNING = "warning"
}

export interface SnackbarData {
  message: string;
  type: TypeSnackbar;
}

const snackbar: Ref<SnackbarData | null> = ref(null);
const timeout: Ref<number | null> = ref(null);
const freezed = ref(false);

export function useSnackbar() {
  return snackbar;
}

const extractErrorMessage = (error: Error | AxiosError | GeolocationPositionError) => {
  if ("response" in error && error.response) {
    return (error.response.data as { message: string })?.message || error.message;
  }
  return error.message;
};

export function freezeSnackbar() {
  freezed.value = true;
}

export function unfreezeSnackbar() {
  freezed.value = false;
}

export function setStaticSnackbar(message?: string, type = TypeSnackbar.ERROR) {
  if (timeout.value) clearTimeout(timeout.value);
  if (message) snackbar.value = { message, type };
}

export function setSnackbar(text = "", type = TypeSnackbar.ERROR, duration = 3000) {
  if (!freezed.value) {
    setStaticSnackbar(text, type);
    timeout.value = setTimeout(() => {
      snackbar.value = null;
      timeout.value = null;
    }, duration) as unknown as number;
  }
}

export function setStaticErrorSnackbar(error: Error | GeolocationPositionError) {
  if (error) snackbar.value = { message: extractErrorMessage(error), type: TypeSnackbar.ERROR };
}

export function setErrorSnackbar(error: Error | GeolocationPositionError, duration = 3000) {
  if (!freezed.value) {
    setStaticErrorSnackbar(error);
    timeout.value = setTimeout(() => {
      snackbar.value = null;
      timeout.value = null;
    }, duration) as unknown as number;
  }
}
