<template>
  <svg
    width="20px"
    height="21px"
    viewBox="0 0 20 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <path
          d="M13.26516,1.15116277 L14.8930669,2.79769257 C15.4446471,3.341819 15.8230222,4.03526021 15.9755495,4.78708715 C18.4268789,5.29072942 20,7.28966653 20,9.99772895 L20,14.6488917 C20,17.8488918 17.8511628,19.997729 14.6511628,19.997729 L5.34883721,19.997729 C2.14883716,19.997729 0,17.8488918 0,14.6488917 L0,9.99772895 C0,7.25847705 1.61556548,5.24198428 4.15569416,4.76931254 C4.28829148,4.56586734 4.45079892,4.36867052 4.64190389,4.18373022 L7.66515954,1.15116277 C9.20934555,-0.383720925 11.7209739,-0.383720925 13.26516,1.15116277 Z M14.6512063,6.04419534 L5.3488809,6.04419534 C5.12562509,6.04419534 4.91167172,6.06278863 4.69771823,6.09069561 C4.68198885,6.09594386 4.6633042,6.098234 4.64333001,6.09923334 L4.6976746,6.09074085 C2.65116292,6.35120597 1.39534884,7.83958948 1.39534884,9.99772895 L1.39534884,14.6488917 C1.39534884,17.0488917 2.94883728,18.6023801 5.34883721,18.6023801 L14.6511628,18.6023801 C16.9727065,18.6023801 18.5021792,17.1487989 18.5996955,14.8815218 L16.5116279,14.8814499 C15.0976744,14.8814499 13.9534884,13.7372638 13.9534884,12.3233103 C13.9534884,10.9093569 15.0976744,9.76517081 16.5116279,9.76517081 L18.5997426,9.76586346 C18.5130804,7.73808702 17.2841588,6.34619877 15.3319786,6.08976302 C15.2997236,6.09036407 15.2683802,6.08707225 15.237252,6.08141259 C15.0512055,6.05350561 14.8558574,6.04419534 14.6512063,6.04419534 Z M18.604,13.486 L18.604,11.16 L16.5116279,11.1605196 C15.8697674,11.1605196 15.3488372,11.6814499 15.3488372,12.3233103 C15.3488372,12.9651708 15.8697674,13.486101 16.5116279,13.486101 L18.604,13.486 Z M8.6419039,2.12792399 L6.14888103,4.6488465 L14.4837641,4.6488465 C14.3628339,4.33256743 14.167486,4.04420215 13.9070209,3.78373703 L12.2698105,2.12792399 C11.2744616,1.14187753 9.6465551,1.14187753 8.6419039,2.12792399 Z"
          id="Combined-Shape"
          fill="currentColor"
        ></path>
      </g>
    </g>
  </svg>
</template>
