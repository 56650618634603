import type { ComputedRef, Ref } from "vue";

// NullRef -> For non-initialized refs
export type NullRef<T> = Ref<T | null | undefined>;

// MaybeRef -> For things that could be refs or not
export type MaybeRef<T> = T | Ref<T> | ComputedRef<T>;

export enum View {
  MAIN,
  CONFIRM,
  SUCCESS,
  ERROR
}

/* export enum GoBackBehavior {
  DEFAULT = "DEFAULT"
} */

export type Form<T> = {
  [P in keyof T]: T[P] | null;
};

export function isEmptyObject(object: Record<string, any>) {
  return JSON.stringify(object) === "{}";
}

export function isEqualObject(obj1: any, obj2: any) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
