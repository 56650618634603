<template>
  <svg
    width="25px"
    height="23px"
    viewBox="0 0 25 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-favorite---big"
        transform="translate(0.000000, -1.000000)"
        fill="currentColor"
      >
        <path
          d="M16.3158382,1.10733788 C14.8859044,1.17081274 13.5284195,1.64453239 12.3826218,2.46760937 L12.1,2.68201835 L12.0779719,2.66372408 C10.7944259,1.65241947 9.21478851,1.1 7.55295492,1.1 C3.43493121,1.1 0.1,4.52039253 0.1,8.72104855 C0.1,15.3330339 5.25647649,21.1000903 10.8917906,23.0735063 C11.243248,23.2028337 11.6595761,23.2651376 12.1,23.2651376 C12.5404239,23.2651376 12.956752,23.2028337 13.3226086,23.0683388 C18.9488166,21.0982367 24.1,15.3403961 24.1,8.72104855 C24.1,4.52747475 20.7618002,1.1 16.6470456,1.1 L16.3158382,1.10733788 Z M16.6470456,3.08165138 C19.6554888,3.08165138 22.1183486,5.61037662 22.1183486,8.72104855 C22.1183486,14.3955447 17.5675484,19.4822947 12.6532578,21.2032174 C12.5116885,21.2551348 12.322238,21.2834862 12.1,21.2834862 C11.877762,21.2834862 11.6883115,21.2551348 11.5611414,21.2083849 C6.63786863,19.4841917 2.08165138,14.3884714 2.08165138,8.72104855 C2.08165138,5.60376326 4.54074622,3.08165138 7.55295492,3.08165138 C9.01073998,3.08165138 10.3739387,3.65974123 11.3891426,4.70528453 C11.7783068,5.10607896 12.4216932,5.10607896 12.8108574,4.70528453 C13.8260612,3.65974132 15.1892604,3.08165138 16.6470456,3.08165138 Z"
          id="favorite"
        ></path>
      </g>
    </g>
  </svg>
</template>
