<template>
  <svg
    width="16px"
    height="22px"
    viewBox="0 0 16 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Takeaway</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(4.000000, 1.000000)">
        <path
          d="M11.5841028,0 C12.0330234,0 12.44472,0.239168658 12.6594189,0.603492829 L12.708897,0.697115298 L13.7768213,2.77319409 C14.6159006,2.77971882 15.2940877,3.4619377 15.2940877,4.30255567 L15.2940877,4.30255567 L15.2940877,7.25212972 L14.347,7.252 L12.7838895,20.3723631 C12.7234316,21.2490022 12.0237289,21.9377727 11.1590277,21.9960086 L11.0401357,22 L4.34449546,22 C3.48950814,22 2.76659424,21.3830626 2.62376757,20.5635576 L2.60735831,20.4451522 L1.01,7.252 L0,7.25212972 L0,4.30255567 C0,3.45788653 0.68473963,2.7731469 1.52940877,2.7731469 L1.52940877,2.7731469 L1.536,2.773 L2.57362929,0.720589554 C2.76505768,0.314529345 3.15694723,0.0441252864 3.59861746,0.00492738522 L3.70998491,0 L11.5841028,0 Z M12.807,7.252 L2.551,7.252 L4.12716826,20.2745864 C4.13704328,20.3700449 4.2074004,20.4458638 4.29780798,20.4655782 L4.34449546,20.4705912 L11.0401357,20.4705912 C11.1385639,20.4705912 11.222594,20.4052815 11.2518069,20.2906661 L11.261658,20.2293238 L12.807,7.252 Z M13.7646789,4.30255567 L1.52940877,4.30255567 L1.52940877,5.72228291 L13.7646789,5.72228291 L13.7646789,4.30255567 Z M11.4170365,1.52864407 L3.87781594,1.52864407 L3.249,2.773 L12.057,2.773 L11.4170365,1.52864407 Z"
          id="Combined-Shape"
          fill="currentColor"
        ></path>
      </g>
    </g>
  </svg>
</template>
