<template>
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-info---big"
        transform="translate(-1.000000, -1.000000)"
        fill="currentColor"
      >
        <path
          d="M12,1.25 C17.9299998,1.25 22.75,6.07000017 22.75,12 C22.75,17.9299998 17.9299998,22.75 12,22.75 C6.07000017,22.75 1.25,17.9299998 1.25,12 C1.25,6.07000017 6.07000017,1.25 12,1.25 Z M12,2.75 C6.9000001,2.75 2.75,6.9000001 2.75,12 C2.75,17.0999999 6.9000001,21.25 12,21.25 C17.0999999,21.25 21.25,17.0999999 21.25,12 C21.25,6.9000001 17.0999999,2.75 12,2.75 Z M12,10.4998829 C12.41,10.4998829 12.75,10.8660368 12.75,11.3075752 L12.75,16.6921906 C12.75,17.1337291 12.41,17.4998829 12,17.4998829 C11.59,17.4998829 11.25,17.1337291 11.25,16.6921906 L11.25,11.3075752 C11.25,10.8660368 11.59,10.4998829 12,10.4998829 Z M12,7.25 C12.13,7.25 12.2600001,7.27999992 12.3800001,7.32999992 C12.5,7.37999992 12.61,7.45000091 12.71,7.54000092 C12.8,7.64000092 12.8700001,7.74000084 12.9200001,7.87000084 C12.9700001,7.99000084 13,8.12000006 13,8.25000006 C13,8.38000005 12.9700001,8.51000018 12.9200001,8.63000017 C12.8700001,8.75000017 12.8,8.8600001 12.71,8.9600001 C12.61,9.0500001 12.5,9.12000012 12.3800001,9.17000012 C12.1400001,9.27000012 11.8599999,9.27000012 11.6199999,9.17000012 C11.5,9.12000012 11.39,9.0500001 11.29,8.9600001 C11.2,8.8600001 11.1299999,8.75000017 11.0799999,8.63000017 C11.0299999,8.51000018 11,8.38000005 11,8.25000006 C11,8.12000006 11.0299999,7.99000084 11.0799999,7.87000084 C11.1299999,7.74000084 11.2,7.64000092 11.29,7.54000092 C11.39,7.45000091 11.5,7.37999992 11.6199999,7.32999992 C11.7399999,7.27999992 11.87,7.25 12,7.25 Z"
          id="info"
        ></path>
      </g>
    </g>
  </svg>
</template>
