<template>
  <svg
    width="22px"
    height="20px"
    viewBox="0 0 22 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>map</title>
    <defs>
      <filter color-interpolation-filters="auto" id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.239216 0 0 0 0 0.282353 0 0 0 0 0.321569 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="map" transform="translate(-1.000000, -2.000000)" filter="url(#filter-1)">
        <g transform="translate(1.539063, 2.583984)">
          <path
            d="M12.8999996,18.5661716 L7.64999962,15.9361715 C7.34999961,15.7861715 6.76000026,15.7961703 6.47000027,15.9661703 L4.11000013,17.3161716 C3.09000015,17.8961716 2.04000002,17.9761722 1.25,17.5061722 C0.449999988,17.0461722 0,16.1061713 0,14.9261713 L0,5.20617104 C0,4.29617101 0.600000083,3.2661714 1.3900001,2.81617141 L5.72000027,0.336171359 C6.45000029,-0.0838286281 7.56000042,-0.113828808 8.31000042,0.266171187 L13.5600004,2.89617133 C13.8600004,3.04617134 14.4400007,3.02617112 14.7400007,2.86617112 L17.0900002,1.52617145 C18.1100001,0.946171463 19.1600007,0.86617139 19.9500008,1.33617139 C20.7500008,1.7961714 21.2000008,2.73617136 21.2000008,3.91617131 L21.2000008,13.6461706 C21.2000008,14.5561706 20.6000014,15.5861729 19.8100014,16.0361729 L15.4800005,18.5161724 C15.1000005,18.7161724 14.5999997,18.8261719 14.1099997,18.8261719 C13.6799996,18.8261719 13.2499996,18.7361716 12.8999996,18.5661716 Z M8.31000042,14.5961714 L13.5600004,17.2261715 C13.8600004,17.3761715 14.4400007,17.3561727 14.7400007,17.1961727 L19.0699997,14.7161703 C19.3899997,14.5361703 19.7000008,13.9961704 19.7000008,13.6361704 L19.7000008,3.90617108 C19.7000008,3.27617109 19.5199986,2.80617136 19.1899986,2.62617135 C18.8699986,2.44617134 18.3700019,2.51617116 17.8300018,2.82617116 L15.4800005,4.16617107 C14.7500005,4.58617106 13.6400003,4.61617124 12.8900003,4.23617125 L7.64000034,1.60617137 C7.34000033,1.45617136 6.76000005,1.47617111 6.46000004,1.6361711 L2.13000011,4.11617088 C1.81000012,4.29617089 1.5,4.83617103 1.5,5.20617104 L1.5,14.9361715 C1.5,15.5661715 1.68000001,16.0361713 2,16.2161713 C2.31999999,16.4061713 2.81999987,16.3261724 3.36999989,16.0161724 L5.72000027,14.6761713 C6.11000025,14.4461713 6.61000037,14.3361712 7.10000038,14.3361712 C7.53000039,14.3361712 7.96000043,14.4261714 8.31000042,14.5961714 Z"
            id="Vector"
            fill="#292D32"
          ></path>
          <path
            d="M6.27148438,14.4160156 L6.27148438,1.41601562 C6.27148438,1.00601563 6.61148486,0.666015625 7.02148485,0.666015625 C7.43148485,0.666015625 7.77148438,1.00601563 7.77148438,1.41601562 L7.77148438,14.4160156 C7.77148438,14.8260156 7.43148485,15.1660156 7.02148485,15.1660156 C6.61148486,15.1660156 6.27148438,14.8260156 6.27148438,14.4160156 Z"
            id="Vector"
            fill="#292D32"
            fill-rule="nonzero"
          ></path>
          <path
            d="M13.4414062,17.4161329 L13.4414062,4.03613281 C13.4414062,3.62613282 13.7814063,3.28613281 14.1914062,3.28613281 C14.6014062,3.28613281 14.9414062,3.62613282 14.9414062,4.03613281 L14.9414062,17.4161329 C14.9414062,17.8261329 14.6014062,18.1661329 14.1914062,18.1661329 C13.7814063,18.1661329 13.4414062,17.8261329 13.4414062,17.4161329 Z"
            id="Vector"
            fill="#292D32"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
