import type { Auth } from "@/plugins/auth";
import { useCart } from "./cart";
import { useDeliveryAddresses } from "./delivery/deliveryAddresses";
import { initData } from "./init/initData";
import { useCartItems } from "./cart/cartItems";

const useLoginLogout = () => {
  const logout = async (auth: Auth) => {
    const { cart, cartPrefix } = useCart();
    const { removePromoCodeById } = useCartItems();
    const { onLogoutKeepCartDeliveryPrice } = useDeliveryAddresses();

    for (const promoCode of cart.value?.promoCodes || []) {
      removePromoCodeById(promoCode.promoCodeId);
    }
    auth.logout();
    onLogoutKeepCartDeliveryPrice();
    if (cartPrefix.value && cart.value?.saletype) {
      await initData(cart.value.saletype, auth);
    }
  };

  return { logout };
};

export default useLoginLogout;
