<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-1" points="0 0 24 0 24 23.9906586 0 23.9906586"></polygon>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Marchio-CMYK-stampa" transform="translate(0.000000, 0.000000)">
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-1"></use>
        </mask>
        <g id="Clip-2"></g>
        <path
          d="M19.1835191,0.640885207 C19.4200562,0.404348189 19.2525786,0 18.9179571,0 L13.3468264,0 L16.5862826,3.23845534 L19.1835191,0.640885207"
          id="Fill-1"
          fill="#DD443E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M11.3981484,8.42625594 L7.41371737,4.44249215 L0.110094804,11.7464483 C-0.036698268,11.8932414 -0.036698268,12.1307793 0.110094804,12.2775724 L7.41371737,19.5815285 L11.3981484,15.5974311 L7.81239401,12.0120103 L11.3981484,8.42625594"
          id="Fill-3"
          fill="#DD443E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M16.585949,20.7852317 L13.3605049,24.0106759 L18.9046123,24.0106759 C19.2392338,24.0106759 19.407045,23.6063277 19.1705079,23.3697907 L16.585949,20.7852317"
          id="Fill-4"
          fill="#DD443E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M23.8899052,11.7464483 L12.253218,0.109761183 C12.1858267,0.0423698185 12.0950819,0.00533792988 12,0.00233534432 C11.9956629,0.00200172371 11.9919931,0 11.987656,0 L5.08170925,0 C4.74742139,0 4.57961022,0.404348189 4.81614724,0.640885207 L16.1872724,12.0120103 L4.82982568,23.3697907 C4.59295504,23.6063277 4.76076622,24.0106759 5.09505407,24.0106759 L12.0010009,24.0106759 C12.1007534,24.0106759 12.1961689,23.9713086 12.2665629,23.9009147 L23.8899052,12.2775724 C24.0370319,12.1307793 24.0370319,11.893575 23.8899052,11.7464483"
          id="Fill-5"
          fill="#DD443E"
          mask="url(#mask-2)"
        ></path>
      </g>
    </g>
  </svg>
</template>
