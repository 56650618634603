<template>
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Importo</title>
        <defs>
            <filter color-interpolation-filters="auto" id="filter-1">
                <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0.282353 0 0 0 0 0.321569 0 0 0 1.000000 0"></feColorMatrix>
            </filter>
        </defs>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Importo" transform="translate(-1.000000, -1.000000)" filter="url(#filter-1)">
                <g transform="translate(1.250000, 1.250000)">
                    <path d="M10.75,0 C16.6799998,0 21.5,4.82000017 21.5,10.75 C21.5,16.6799998 16.6799998,21.5 10.75,21.5 C4.82000017,21.5 0,16.6799998 0,10.75 C0,4.82000017 4.82000017,0 10.75,0 Z M10.75,1.5 C5.6500001,1.5 1.5,5.6500001 1.5,10.75 C1.5,15.8499999 5.6500001,20 10.75,20 C15.8499999,20 20,15.8499999 20,10.75 C20,5.6500001 15.8499999,1.5 10.75,1.5 Z M10.75,4 C11.16,4 11.5,4.34 11.5,4.75 L11.5,5.33 L11.8601561,5.33007812 C13.500156,5.33007812 14.8301563,6.710078 14.8301563,8.41007805 C14.8301563,8.82007805 14.4901563,9.16007805 14.0801563,9.16007805 C13.6701563,9.16007805 13.3301563,8.82007805 13.3301563,8.41007805 C13.3301563,7.54007804 12.6701561,6.83007812 11.8601561,6.83007812 L11.5,6.83 L11.5,10.218 L13.0101566,10.7500782 C13.7901566,11.0200782 14.8401566,11.6000786 14.8401566,13.3900785 C14.8301566,14.9200785 13.630156,16.1700783 12.150156,16.1700783 L11.5,16.17 L11.5,16.75 C11.5,17.16 11.16,17.5 10.75,17.5 C10.34,17.5 10,17.16 10,16.75 L10,16.17 L9.64015675,16.1700783 C8.00015676,16.1700783 6.67015648,14.7900784 6.67015648,13.0900784 C6.67015648,12.6800784 7.01015648,12.3400784 7.42015648,12.3400784 C7.83015648,12.3400784 8.17015648,12.6800784 8.17015648,13.0900784 C8.17015648,13.9600784 8.83015674,14.6700783 9.64015675,14.6700783 L10,14.67 L10,11.282 L8.49015617,10.7500782 C7.7101562,10.4800782 6.66015625,9.90007782 6.66015625,8.11007786 C6.66015625,6.5700779 7.87015676,5.33007812 9.35015678,5.33007812 L10,5.33 L10,4.75 C10,4.34 10.34,4 10.75,4 Z M11.5,11.811 L11.5,14.67 L12.150156,14.6700783 C12.800156,14.6700783 13.3401566,14.0900785 13.3401566,13.3900785 C13.3401566,12.5200785 13.0301569,12.3500783 12.5201569,12.1700783 L11.5,11.811 Z M10,6.83 L9.35015678,6.83007812 C8.70015681,6.83007812 8.16015625,7.41007787 8.16015625,8.11007786 C8.16015625,8.98007786 8.47015595,9.15007812 8.98015594,9.33007812 L10,9.689 L10,6.83 Z" id="Combined-Shape" fill="#292D32" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </svg>
</template>
