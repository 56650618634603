<template lang="pug">
div.text-caption.mb-3.mt-1(
  v-if="message?.key || hint" 
  :class="message.key ? 'text-error' : 'text--disabled'"
) {{ message?.key ? $t(message.key, { ...(message.params || {}), label: $t(label)}) : $t(hint) }}
div.mb-8.mt-1(v-else)
</template>

<script setup lang="ts">
import { useFieldError } from 'vee-validate';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  errorMessage: {
    type: String,
    default: ''
  },
  hint: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  }
});


const fieldError = useFieldError(() => props.name) as unknown as ComputedRef<{ key: string, params: Record<string, string> }>;
const label = computed(() => fieldError.value?.params?.label || props.label);

const message = computed(() => {
  return { 
    key: fieldError.value?.key || props.errorMessage, 
    params: { ...fieldError.value?.params, label }
  }
})
</script>