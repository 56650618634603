import type { Socket } from "socket.io-client";
import type { TableData } from "@/api/Table";
import type { Order } from "@/models/Order";
import type { Auth } from "@/plugins/auth";
import { ref, type Ref } from "vue";
import type { Router } from "vue-router";
import { useSyncActions } from "./modules/sync";
import { useTableActions } from "./modules/table";
import type { PaymentFlow } from "@/models/App";
interface Table {
  id: string | null;
  data: TableData | null;
}

interface ServerToClientEvents {
  "table-updated": (response: TableData) => void;
  "order-updated": (order: Order) => void;
}

interface ClientToServerEvents {
  hi: () => void;
}

export interface CartState {
  note: string;
  isSendingOrder: boolean;
}

export enum PaymentErrors {
  REMAINING_CENTS_TO_PAY = "remainingCentsToPay",
  PAYMENT_EXEEDS_TOTAL = "paymentExceedsTotal",
  PAYMENT_FLOW_MISMATCH = "paymentFlowMismatch",
  DUTCH_DIVIDER_MISMATCH = "dutchDividerMismatch",
  ORDER_ITEMS_ALREADY_PAID = "orderItemsAlreadyPaid"
}

export interface PaymentErrorsState {
  remainingCents?: number;
  dutchDividerMismatch?: boolean;
}

export interface PaidItem {
  orderItemId: string;
  quantity: number;
}

export interface State {
  table: Ref<Table>;
  order: Ref<Order | null>;
  sync: {
    interval: Ref<number | null>;
    socket: Ref<Socket<ServerToClientEvents, ClientToServerEvents> | null>;
    initialized: Ref<boolean>;
  };
  payment: Ref<{
    flow: PaymentFlow | null;
    errors: PaymentErrorsState | null;
    payingAmount: number;
    dutch: {
      dutchDivider: number;
      dutchPaying: number;
    } | null;
    split: {
      payingItems: PaidItem[];
    } | null;
  }>;
  cart: Ref<CartState>;
}

const state: State = {
  table: ref({
    id: null,
    data: null
  }),
  order: ref(null),
  sync: {
    interval: ref(null),
    socket: ref(null),
    initialized: ref(false)
  },
  cart: ref({
    note: "",
    isSendingOrder: false
  }),
  payment: ref({
    flow: null,
    errors: null,
    payingAmount: 0,
    dutch: null,
    split: null
  })
};

export const useGlobalState = () => {
  return state;
};

export const useGlobalActions = () => {
  const initTable = async (router: Router, auth: Auth) => {
    const { startSync } = useSyncActions();
    const { setTableId } = useTableActions();
    setTableId();
    await startSync(router, auth);
  };

  return {
    initTable
  };
};
