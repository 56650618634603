<template>
  <svg
    width="20px"
    height="19px"
    viewBox="0 0 20 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-surname"
        transform="translate(-2.000000, -3.000000)"
        fill="currentColor"
      >
        <path
          d="M20,3.161 C21.1,3.161 22,4.1735 22,5.411 L22,5.411 L22,18.911 C22,20.1485 21.1,21.161 20,21.161 L20,21.161 L4,21.161 C2.9,21.161 2,20.1485 2,18.911 L2,18.911 L2,5.411 C2,4.1735 2.9,3.161 4,3.161 L4,3.161 Z M12,13.9 C10,13.9 6,15 6,17 L6,17 L6,18 L18,18 L18,17 C18,15 14,13.9 12,13.9 Z M12,6 C10.34,6 9,7.34 9,9 C9,10.6595 10.34,12 12,12 C13.66,12 15,10.6595 15,9 C15,7.34 13.66,6 12,6 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
</template>
