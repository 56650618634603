import Allergen from "@/assets/icons/Allergen.vue";
import Amount from "@/assets/icons/Amount.vue";
import AmountEuro from "@/assets/icons/AmountEuro.vue";
import ApplePay from "@/assets/icons/ApplePay.vue";
import ApplePayColor from "@/assets/icons/ApplePayColor.vue";
import Back from "@/assets/icons/Back.vue";
import Calendar from "@/assets/icons/Calendar.vue";
import CalendarBold from "@/assets/icons/navbar/CalendarBold.vue";
import CalendarLight from "@/assets/icons/navbar/CalendarLight.vue";
import MenuBold from "@/assets/icons/navbar/MenuBold.vue";
import MenuLight from "@/assets/icons/navbar/MenuLight.vue";
import Call from "@/assets/icons/Call.vue";
import Camera from "@/assets/icons/Camera.vue";
import CartBold from "@/assets/icons/navbar/CartBold.vue";
import CartLight from "@/assets/icons/navbar/CartLight.vue";
import Cash from "@/assets/icons/Cash.vue";
import Check from "@/assets/icons/Check.vue";
import CheckboxOff from "@/assets/icons/CheckboxOff.vue";
import CheckboxOn from "@/assets/icons/CheckboxOn.vue";
import ChevronDown from "@/assets/icons/ChevronDown.vue";
import ChevronRight from "@/assets/icons/ChevronRight.vue";
import ChevronUp from "@/assets/icons/ChevronUp.vue";
import Clock from "@/assets/icons/Clock.vue";
import Close from "@/assets/icons/Close.vue";
import CreditCard from "@/assets/icons/CreditCard.vue";
import CreditCardColor from "@/assets/icons/CreditCardColor.vue";
import Cutlery from "@/assets/icons/Cutlery.vue";
import Dot from "@/assets/icons/Dot.vue";
import Deselect from "@/assets/icons/Deselect.vue";
import Directions from "@/assets/icons/Directions.vue";
import DutchPayment from "@/assets/icons/DutchPayment.vue";
import Facebook from "@/assets/icons/Facebook.vue";
import Favorite from "@/assets/icons/Favorite.vue";
import Fidelity from "@/assets/icons/Fidelity.vue";
import FidelityPay from "@/assets/icons/FidelityPay.vue";
import FidelityPayColor from "@/assets/icons/FidelityPayColor.vue";
import Flag from "@/assets/icons/Flag.vue";
import FlagBold from "@/assets/icons/FlagBold.vue";
import Forward from "@/assets/icons/Forward.vue";
import GooglePay from "@/assets/icons/GooglePay.vue";
import GooglePayColor from "@/assets/icons/GooglePayColor.vue";
import Hamburger from "@/assets/icons/Hamburger.vue";
import Heart from "@/assets/icons/Heart.vue";
import History from "@/assets/icons/History.vue";
import Info from "@/assets/icons/Info.vue";
import Instagram from "@/assets/icons/Instagram.vue";
import KeyboardCancel from "@/assets/icons/KeyboardCancel.vue";
import Language from "@/assets/icons/Language.vue";
import LocationBold from "@/assets/icons/navbar/LocationBold.vue";
import LocationLight from "@/assets/icons/navbar/LocationLight.vue";
import Lock from "@/assets/icons/Lock.vue";
import LockMessage from "@/assets/icons/LockMessage.vue";
import LockOpen from "@/assets/icons/LockOpen.vue";
import LockSolid from "@/assets/icons/LockSolid.vue";
import Logout from "@/assets/icons/Logout.vue";
import Mail from "@/assets/icons/Mail.vue";
import MailMessage from "@/assets/icons/MailMessage.vue";
import Map from "@/assets/icons/Map.vue";
import Minus from "@/assets/icons/Minus.vue";
import Mobile from "@/assets/icons/Mobile.vue";
import More from "@/assets/icons/More.vue";
import Oops from "@/assets/icons/Oops.vue";
import Order from "@/assets/icons/Order.vue";
import OrderBold from "@/assets/icons/navbar/OrderBold.vue";
import OrderLight from "@/assets/icons/navbar/OrderLight.vue";
import PayPal from "@/assets/icons/PayPal.vue";
import PayPalColor from "@/assets/icons/PayPalColor.vue";
import Pencil from "@/assets/icons/Pencil.vue";
import People from "@/assets/icons/People.vue";
import PeopleBold from "@/assets/icons/navbar/PeopleBold.vue";
import PeopleLight from "@/assets/icons/navbar/PeopleLight.vue";
import PersonalizedAmount from "@/assets/icons/PersonalizedAmount.vue";
import Phone from "@/assets/icons/Phone.vue";
import Plus from "@/assets/icons/Plus.vue";
import Point from "@/assets/icons/Point.vue";
import PointPlus from "@/assets/icons/PointPlus.vue";
import Position from "@/assets/icons/Position.vue";
import Promotion from "@/assets/icons/Promotion.vue";
import Satispay from "@/assets/icons/Satispay.vue";
import SatispayColor from "@/assets/icons/SatispayColor.vue";
import Search from "@/assets/icons/Search.vue";
import Select from "@/assets/icons/Select.vue";
import Share from "@/assets/icons/Share.vue";
import ShareIOS from "@/assets/icons/ShareIOS.vue";
import SplitPayment from "@/assets/icons/SplitPayment.vue";
import SuccessMessage from "@/assets/icons/SuccessMessage.vue";
import Surname from "@/assets/icons/Surname.vue";
import Table from "@/assets/icons/Table.vue";
import Takeaway from "@/assets/icons/Takeaway.vue";
import Trash from "@/assets/icons/Trash.vue";
import Waiter from "@/assets/icons/Waiter.vue";
import WaiterBig from "@/assets/icons/WaiterBig.vue";
import Wallet from "@/assets/icons/Wallet.vue";
import Wink from "@/assets/icons/Wink.vue";
import Whatsapp from "@/assets/icons/Whatsapp.vue";
import Sorry from "@/assets/icons/Sorry.vue";

const icons = {
  iconfont: "mdi",
  values: {
    allergen: {
      component: Allergen
    },
    amount: {
      component: Amount
    },
    amountEuro: {
      component: AmountEuro
    },
    applePay: {
      component: ApplePay
    },
    applePayColor: {
      component: ApplePayColor
    },
    back: {
      component: Back
    },
    calendar: {
      component: Calendar
    },
    calendarBold: {
      component: CalendarBold
    },
    calendarLight: {
      component: CalendarLight
    },
    call: {
      component: Call
    },
    camera: {
      component: Camera
    },
    cartBold: {
      component: CartBold
    },
    cartLight: {
      component: CartLight
    },
    cash: {
      component: Cash
    },
    check: {
      component: Check
    },
    checkboxOn: {
      component: CheckboxOn
    },
    checkboxOff: {
      component: CheckboxOff
    },
    chevronDown: {
      component: ChevronDown
    },
    chevronRight: {
      component: ChevronRight
    },
    chevronUp: {
      component: ChevronUp
    },
    clock: {
      component: Clock
    },
    close: {
      component: Close
    },
    creditCard: {
      component: CreditCard
    },
    creditCardColor: {
      component: CreditCardColor
    },
    cutlery: {
      component: Cutlery
    },
    deselect: {
      component: Deselect
    },
    directions: {
      component: Directions
    },
    dot: {
      component: Dot
    },
    dutchPayment: {
      component: DutchPayment
    },
    facebook: {
      component: Facebook
    },
    favorite: {
      component: Favorite
    },
    fidelity: {
      component: Fidelity
    },
    fidelityPay: {
      component: FidelityPay
    },
    fidelityPayColor: {
      component: FidelityPayColor
    },
    flag: {
      component: Flag
    },
    flagBold: {
      component: FlagBold
    },
    forward: {
      component: Forward
    },
    googlePay: {
      component: GooglePay
    },
    googlePayColor: {
      component: GooglePayColor
    },
    hamburger: {
      component: Hamburger
    },
    heart: {
      component: Heart
    },
    history: {
      component: History
    },
    info: {
      component: Info
    },
    instagram: {
      component: Instagram
    },
    keyboardCancel: {
      component: KeyboardCancel
    },
    language: {
      component: Language
    },
    locationBold: {
      component: LocationBold
    },
    locationLight: {
      component: LocationLight
    },
    lock: {
      component: Lock
    },
    lockMessage: {
      component: LockMessage
    },
    lockOpen: {
      component: LockOpen
    },
    lockSolid: {
      component: LockSolid
    },
    logout: {
      component: Logout
    },
    mail: {
      component: Mail
    },
    mailMessage: {
      component: MailMessage
    },
    map: {
      component: Map
    },
    menuBold: {
      component: MenuBold
    },
    menuLight: {
      component: MenuLight
    },
    minus: {
      component: Minus
    },
    mobile: {
      component: Mobile
    },
    more: {
      component: More
    },
    oops: {
      component: Oops
    },
    order: {
      component: Order
    },
    orderBold: {
      component: OrderBold
    },
    orderLight: {
      component: OrderLight
    },
    payPal: {
      component: PayPal
    },
    payPalColor: {
      component: PayPalColor
    },
    pencil: {
      component: Pencil
    },
    people: {
      component: People
    },
    peopleBold: {
      component: PeopleBold
    },
    peopleLight: {
      component: PeopleLight
    },
    personalizedAmount: {
      component: PersonalizedAmount
    },
    phone: {
      component: Phone
    },
    plus: {
      component: Plus
    },
    point: {
      component: Point
    },
    pointPlus: {
      component: PointPlus
    },
    position: {
      component: Position
    },
    promotion: {
      component: Promotion
    },
    satispay: {
      component: Satispay
    },
    satispayColor: {
      component: SatispayColor
    },
    search: {
      component: Search
    },
    select: {
      component: Select
    },
    share: {
      component: Share
    },
    shareIOS: {
      component: ShareIOS
    },
    splitPayment: {
      component: SplitPayment
    },
    successMessage: {
      component: SuccessMessage
    },
    surname: {
      component: Surname
    },
    table: {
      component: Table
    },
    takeaway: {
      component: Takeaway
    },
    trash: {
      component: Trash
    },
    waiter: {
      component: Waiter
    },
    waiterBig: {
      component: WaiterBig
    },
    wallet: {
      component: Wallet
    },
    whatsapp: {
      component: Whatsapp
    },
    wink: {
      component: Wink
    },
    sorry: {
      component: Sorry
    }
  }
};

export default icons;
