<template>
  <svg
    width="24px"
    height="20px"
    viewBox="0 0 24 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Logout</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-logout---big"
        transform="translate(0.000000, -2.000000)"
        fill="currentColor"
      >
        <path
          d="M14,2.1 C19.467619,2.1 23.9,6.53238098 23.9,12 C23.9,17.467619 19.467619,21.9 14,21.9 C11.1065561,21.9 8.41335271,20.6504878 6.54551031,18.5148335 C6.21828132,18.1406863 6.25631604,17.5721085 6.63046324,17.2448795 C7.00461044,16.9176505 7.57318818,16.9556852 7.90041717,17.3298324 C9.42997294,19.0786965 11.6312945,20.1 14,20.1 C18.4735065,20.1 22.1,16.4735065 22.1,12 C22.1,7.52649353 18.4735065,3.9 14,3.9 C11.6151792,3.9 9.40031778,4.93535999 7.87024003,6.70486856 C7.54512715,7.080856 6.97677305,7.12209799 6.6007856,6.79698511 C6.22479816,6.47187223 6.18355618,5.90351813 6.50866905,5.52753069 C8.37713341,3.36668383 11.0868471,2.1 14,2.1 Z M4.80357546,8.05439951 C5.09348924,8.34431329 5.09348924,8.82514591 4.80357546,9.11505968 L2.66815425,11.2499642 L13.5861666,11.2508608 C13.7982986,11.2508608 13.9821464,11.3357133 14.1164967,11.4700636 C14.250847,11.6044139 14.3356994,11.7882616 14.3356994,12.0003936 C14.3356994,12.4105156 13.9962885,12.7499264 13.5861666,12.7499264 L2.66715425,12.7489642 L4.80357546,14.8850509 C5.09348924,15.1749647 5.09348924,15.6557973 4.80357546,15.9457111 C4.51366168,16.2356249 4.03282906,16.2356249 3.74291529,15.9457111 L0.327589587,12.5303854 C0.0376758093,12.2404716 0.0376758093,11.759639 0.327589587,11.4697252 L3.74291529,8.05439951 C4.03282906,7.76448573 4.51366168,7.76448573 4.80357546,8.05439951 Z"
          id="Logout"
        ></path>
      </g>
    </g>
  </svg>
</template>
