<template lang="pug">
div.position-relative.overflow-hidden.d-flex.align-start.border.rounded-lg.pa-4.bg-white(:class="{ 'text-area__error': errored }")
  EatIcon.text-muted(:icon="icon")
  textarea.ms-3.flex-grow-1(:rows="rows" spellcheck="true" :placeholder="placeholder ? $t(placeholder) : ''" v-model="localValue" @blur="onBlur")
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  rows: {
    type: [String, Number],
    default: 2
  },
  placeholder: {
    type: String
  },
  icon: {
    type: String,
    default: "$pencil"
  },
  errored: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue", "blur"]);

const localValue = computed({
  get: () => props.modelValue,
  set: value => { if(!props.disabled) emit("update:modelValue", value) }
});

const onBlur = (e: FocusEvent) => emit("blur", e);
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  line-height: 24px;
  &:focus {
    outline: none;
  }
}

.text-area__error::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--clr-error);
}
</style>
