import { createI18n } from "vue-i18n";
import { LS, LSKeys } from "@/utils/localStorage";
import it from "./translations/it.json";

const messages = { it };

export function getEnabledLocale(defaultLocale: string, availableLocales: Array<string>) {
  if (!(defaultLocale in messages)) throw new Error("Default locale is not loaded by default!");

  let selectedLocale = defaultLocale;
  // see if the navigator language is in the available languages
  let browserLocale = navigator.language;
  if (browserLocale) {
    browserLocale = browserLocale.toLowerCase().split("-")[0];
    if (availableLocales.includes(browserLocale)) {
      selectedLocale = browserLocale;
    }
  }
  // see if a language was selected before (and make sure it is an available language)
  if (LS.get(LSKeys.LOCALE) !== null && LS.get(LSKeys.LOCALE) !== "null") {
    const savedLocale = LS.get(LSKeys.LOCALE);
    if (savedLocale && availableLocales.includes(savedLocale)) {
      selectedLocale = savedLocale;
    }
  }
  return selectedLocale;
}

export const i18n = createI18n({
  legacy: false,
  mode: 'composition',
  global: true,
  globalInjection: true,
  locale: "it",
  fallbackLocale: "it",
  messages
});
