import type { Address } from "@/models/Address";
import type { DeliveryPrice, KmRangeDeliveryPrice, ZipCodeDeliveryPrice } from "@/models/App";
import { computed, ref } from "vue";
import { useApp } from "../app";

const deliveryAddress = ref();

export function useDeliveryAddressSelector() {
  const { deliveryService } = useApp();

  const getZipCodeDeliveryCost = (
    zipCodeDeliveryCosts: ZipCodeDeliveryPrice[],
    zipCode: string
  ) => {
    const currentDeliveryCost = zipCodeDeliveryCosts.find(cost => cost.zipCode == zipCode);
    return currentDeliveryCost || null;
  };

  const getKmRangeCost = (
    kmRangeDeliveryCosts: KmRangeDeliveryPrice[],
    distanceKm: number
  ): KmRangeDeliveryPrice | null => {
    const fartherDeliveryCosts: KmRangeDeliveryPrice[] = kmRangeDeliveryCosts.filter(
      // Ottiene i range più grandi della distanza dell'indirizzo dell'utente
      cost => parseInt(cost.kmRange) > distanceKm
    );
    if (fartherDeliveryCosts.length) {
      const currentDeliveryCost: KmRangeDeliveryPrice = fartherDeliveryCosts.reduce(
        // Estrae il range più piccolo (più vicino alla distanza dell'indirizzo di consegna)
        (prev, curr) => (prev.kmRange < curr.kmRange ? prev : curr)
      );
      return currentDeliveryCost || null;
    } else {
      return null;
    }
  };

  const getDeliveryPrice = (address?: Address | null) => {
    if (!address || !deliveryService.value) return null;
    const {
      kmRangeDeliveryCosts,
      zipCodeDeliveryCosts,
      defaultDeliveryPrice
    } = deliveryService.value;
    const { distanceKm, zipCode } = address;

    let deliveryPrice: DeliveryPrice | null = null;
    if (kmRangeDeliveryCosts?.length && distanceKm) {
      deliveryPrice = getKmRangeCost(kmRangeDeliveryCosts, distanceKm);
    } else if (zipCodeDeliveryCosts?.length) {
      deliveryPrice = getZipCodeDeliveryCost(zipCodeDeliveryCosts, zipCode);
    }
    return deliveryPrice || defaultDeliveryPrice || null;
  };

  const deliveryPrice = computed(() => {
    return getDeliveryPrice(deliveryAddress.value);
  });

  const selectedDeliveryMinPurchase = computed(() => {
    return deliveryPrice.value?.minPurchase || deliveryService.value?.minPurchase || null;
  });

  return {
    deliveryPrice,
    deliveryAddress,
    selectedDeliveryMinPurchase,
    getDeliveryPrice
  };
}
