<template>
  <svg
    width="13px"
    height="20px"
    viewBox="0 0 13 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-mobile02"
        transform="translate(-6.000000, -2.000000)"
        fill="currentColor"
      >
        <path
          d="M16.9090909,2.00909091 L7.81818182,2 C6.81818182,2 6,2.81818182 6,3.81818182 L6,20.1818182 C6,21.1818182 6.81818182,22 7.81818182,22 L16.9090909,22 C17.9090909,22 18.7272727,21.1818182 18.7272727,20.1818182 L18.7272727,3.81818182 C18.7272727,2.81818182 17.9090909,2.00909091 16.9090909,2.00909091 L16.9090909,2.00909091 Z M7.81818182,5.63636364 L16.9090909,5.63636364 L16.9090909,18.3636364 L7.81818182,18.3636364 L7.81818182,5.63636364 Z"
          id="Fill-277"
        ></path>
      </g>
    </g>
  </svg>
</template>
