<template lang="pug">
div(ref="$field" @focusout="onFocusOut")
  slot(:value="value" :handleChange="handleChange" :handleReset="handleReset" :errorMessage="errorMessage" :errored="!!errorMessage")
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import { watch } from "vue";
import { ref } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  hint: {
    type: String,
    required: false
  }
});

const emit = defineEmits(["input"]);

const { value, handleChange, handleBlur, handleReset, errorMessage } = useField(() => props.name);

const $field = ref<HTMLElement>();
const onFocusOut = (e: FocusEvent) => {
	if ($field.value?.contains(e.relatedTarget as Node)) return;
  handleBlur(e, true);
}
</script>
