import type { Menu } from "@/models";
import { Saletype } from "@/models/App";
import { get as apiGet } from "./API";

export async function get(service: Saletype, priceList?: string | null): Promise<Menu> {
  const options = {
    params: {
      saletype: service,
      pricelistid: priceList
    }
  };

  const menu: Menu = {
    saletype: service,
    categories: []
  };
  menu.categories = await apiGet("/menu", options);

  return menu;
}
