<template lang="pug">
div.position-relative.cursor-pointer(v-if="options.length" @focusout="onFocusOut" ref="$container")
  button.w-100.position-relative.overflow-hidden.d-flex.align-center.text-left.border.px-4.bg-white(type="button" @click.stop="onSelectClick" :class="[ errored ? 'select__error' : '', small ? 'rounded-lg py-3' : 'rounded-xl py-4']")
    EatIcon.mr-3(:icon="showDropDown ? '$chevronUp' : '$chevronDown'" size="16px")
    div.selected(style="line-height: 16px;" :class="{ 'placeholder-color': !localValue, 'text-body-2': small }") {{ $t(selectedLabel || placeholder) }}

  div.drop-down.border.elevation-1.rounded-lg.bg-white.overflow-auto(v-if="showDropDown && options && !readOnly")
    button.w-100.text-left.option.px-4.py-2.d-flex.align-center(
      type="button"
      v-for="option, i in selectOptions" 
      :key="i" 
      :class="{ 'text-body-2': small, 'border-b': i < selectOptions.length - 1, 'rounded-t-lg': i === 0, 'rounded-b-lg': i === selectOptions.length - 1 }" 
      @click="changeSelected(option.value)"
    ) 
      EatIcon.me-3(:class="{ invisible: option.value !== localValue}" icon="$check" size="14")
      div {{ option.label ? $t(option.label) : '' }}
</template>

<script setup lang="ts">
import {
  onMounted,
  ref,
  type PropType,
  computed
} from "vue";

export interface SelectData {
  label: string;
  value: string;
}

const props = defineProps({
  options: {
    type: Array as PropType<SelectData[]>,
    required: true
  },
  placeholder: {
    type: String,
    default: ""
  },
  blankOption: {
    type: Boolean,
    default: false
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String
  },
  small: {
    type: Boolean,
    default: false
  },
  errored: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue", "blur"]);

const showDropDown = ref(false);
const selectOptions = ref<SelectData[]>([]);

const $container = ref<HTMLElement>();
const onFocusOut = (e: FocusEvent) => {
	if ($container.value?.contains(e.relatedTarget as Node)) return;
  onCloseDropDown()
}

const localValue = computed({
  get: () => props.modelValue,
  set: value => { if(!props.disabled) emit("update:modelValue", value) }
});

const selectedLabel = computed(() => {
  return localValue.value
    ? selectOptions.value.find(option => option.value === localValue.value)?.label || ""
    : props.placeholder;
});

const onSelectClick = () => {
  if (props.readOnly) return;
  showDropDown.value = !showDropDown.value;
};

const onCloseDropDown = () => {
  setTimeout(() => (showDropDown.value = false), 300);
}

const changeSelected = (value: string) => {
  localValue.value = value;
  onCloseDropDown();
};

onMounted(() => {
  selectOptions.value.push(...props.options);
  if (props.blankOption) {
    selectOptions.value.unshift({ label: "", value: "" });
  }
});
</script>

<style lang="scss" scoped>

.select__error::after {
  content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--clr-error);
}

.selected {
  position: relative;
  color: var(--clr-black);
  transition: color 0.3s ease-in-out;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder-color {
  color: var(--border-clr--type-a);
}

.drop-down {
  position: absolute;
  top: 105%;
  left: 0;
  right: 0;
  width: 100%;
  max-height: calc(4.5 * 41px);
  z-index: 2;
  .option {
    min-height: 41px;

    .invisible {
      opacity: 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
