<template lang="pug">
div.position-relative(:style="`height: ${height}px; width: ${width}px`")
  div.stepper__container.d-flex.justify-end.overflow-hidden(:class="{ active: value > 0 || !contractable, 'border': !(value === 0 && transparent), 'rounded': small, 'rounded-lg': !small }")
    div.h-100.d-flex.align-center.justify-space-around.pa-1.flex-grow-1.bg-white(:class="{ 'px-4': !small }")
      EatIconBtn.stepper-btn(type="button" icon="$minus" :size="iconSize" :padding="0" @click.stop="onDecrease" v-if="value > 0 || !contractable") 
      div.stepper-counter.text-h6.font-weight-regular.flex-grow-1(v-if="value > 0 || !contractable") {{ value }}
      EatIconBtn.stepper-btn(type="button" icon="$plus" :size="iconSize" :padding="0" @click.stop="onIncrease") 
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  value: {
    type: Number,
    required: true
  },
  small: {
    type: Boolean,
    default: false
  },
  contractable: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  transparent: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["decrease", "increase"]);

const iconSize = computed(() => props.small ? 15 : 20);
const height = computed(() => props.small ? 32 : 54);
const width = computed(() => props.small ? 76 : 133);

const onDecrease = () => {
  if (!props.disabled) emit("decrease");
};

const onIncrease = () => {
  if (!props.disabled) emit("increase");
};
</script>

<style lang="scss" scoped>
.stepper__container {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  transition: left 0.2s;
  &.active {
    left: 0;
  }
  .stepper-counter {
    line-height: 24px;
    text-align: center;
  }
  .stepper-btn {
    min-width: 22px;
    min-height: 22px;
    display: grid;
    place-content: center;
  }
}
</style>
