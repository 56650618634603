import { CartPrefix } from "@/models/Cart";

export enum LSKeys {
  CART = "cart",
  ADDRESSES = "addresses",
  ADD_TO_HOME_IOS_POP_OVER = "addToHomeIOSPopOver",
  ONBOARDING = "onboarding",
  TABLE = "table",
  LOCALE = "locale"
}

const lsKeys: Record<LSKeys, string> = {
  cart: "",
  addresses: "",
  addToHomeIOSPopOver: "AddToHomeIOSSafariPopOver",
  onboarding: "Onboarding",
  table: "Table_table",
  locale: "App_locale"
};

export const LS = {
  init: (cartPrefix: CartPrefix, channel: string) => {
    lsKeys.cart = `Cart_${cartPrefix}`;
    lsKeys.addresses = `addresses-${channel}`;
  },
  get: (key: LSKeys) => {
    const itemKey = lsKeys[key];
    const JSONItem = window.localStorage.getItem(itemKey);
    if (JSONItem) {
      let value: any;
      try {
        value = JSON.parse(JSONItem);
      } catch (e) {
        LS.remove(key);
        location.reload();
      }
      return value;
    } else {
      return null;
    }
  },
  set: (key: LSKeys, value: any) => {
    const item = lsKeys[key];
    if (item && value) {
      window.localStorage.setItem(lsKeys[key], JSON.stringify(value));
    }
  },
  remove: (key: LSKeys) => {
    const item = lsKeys[key];
    if (item) {
      window.localStorage.removeItem(lsKeys[key]);
    }
  }
};

export enum SSKeys {
  AGE_CONFIRMED = "ageConfirmed"
}

const ssKeys: Record<SSKeys, string> = {
  ageConfirmed: "AgeConfirmed"
};

export const SS = {
  get: (key: SSKeys) => {
    const itemKey = ssKeys[key];
    const JSONItem = window.sessionStorage.getItem(itemKey);
    if (JSONItem) {
      let value: any;
      try {
        value = JSON.parse(JSONItem);
      } catch (e) {
        SS.remove(key);
        location.reload();
      }
      return value;
    } else {
      return null;
    }
  },
  set: (key: SSKeys, value: any) => {
    const item = ssKeys[key];
    if (item && value) {
      window.sessionStorage.setItem(ssKeys[key], JSON.stringify(value));
    }
  },
  remove: (key: SSKeys) => {
    const item = ssKeys[key];
    if (item) {
      window.sessionStorage.removeItem(ssKeys[key]);
    }
  }
};
