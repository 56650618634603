import type { PaymentFlow } from "@/models/App";
import { computed } from "vue";
import { type PaidItem, useGlobalState } from "..";

export const usePaymentGetters = () => {
  const paymentFlow = computed(() => {
    const { payment } = useGlobalState();
    return payment.value.flow;
  });
  const payingAmount = computed(() => {
    const { payment } = useGlobalState();
    return payment.value.payingAmount;
  });
  const dutch = computed(() => {
    const { payment } = useGlobalState();
    return payment.value.dutch;
  });
  const split = computed(() => {
    const { payment } = useGlobalState();
    return payment.value.split;
  });

  return {
    paymentFlow,
    payingAmount,
    dutch,
    split
  };
};

const usePaymentMutations = () => {
  const SET_PAYMENT_FLOW = (newPaymentFlow: PaymentFlow | null) => {
    const { payment } = useGlobalState();
    payment.value.flow = newPaymentFlow;
  };

  const UPDATE_DUTCH = (dutchDivider: number, dutchPaying: number) => {
    const { payment } = useGlobalState();
    payment.value.dutch = {
      dutchDivider: dutchDivider,
      dutchPaying: dutchPaying
    };
  };

  const UPDATE_SPLIT = (payingItems: PaidItem[]) => {
    const { payment } = useGlobalState();
    payment.value.split = { payingItems };
  };

  const CLEAR_PAYMENT = () => {
    const { payment } = useGlobalState();
    payment.value.payingAmount = 0;
    payment.value.flow = null;
    payment.value.dutch = null;
    payment.value.errors = null;
  };

  const UPDATE_PAYING_AMOUNT = (amount: number) => {
    const { payment } = useGlobalState();
    if (amount > 0) payment.value.payingAmount = amount;
  };

  return {
    SET_PAYMENT_FLOW,
    UPDATE_DUTCH,
    UPDATE_SPLIT,
    UPDATE_PAYING_AMOUNT,
    CLEAR_PAYMENT
  };
};

export const usePaymentActions = () => {
  const setPaymentFlow = (paymentFlow: PaymentFlow | null) => {
    const { SET_PAYMENT_FLOW } = usePaymentMutations();
    SET_PAYMENT_FLOW(paymentFlow);
  };

  const clearPayment = () => {
    const { CLEAR_PAYMENT } = usePaymentMutations();
    CLEAR_PAYMENT();
  };

  const updatePayingAmount = (amount: number) => {
    const { UPDATE_PAYING_AMOUNT } = usePaymentMutations();
    UPDATE_PAYING_AMOUNT(amount);
  };

  const updateDutchPayment = (dutchDivider: number, dutchPaying: number, amount: number) => {
    const { UPDATE_DUTCH, UPDATE_PAYING_AMOUNT } = usePaymentMutations();
    UPDATE_DUTCH(dutchDivider, dutchPaying);
    UPDATE_PAYING_AMOUNT(amount);
  };

  const updateSplitPayment = (itemsPaying: PaidItem[], payingAmount: number) => {
    const { UPDATE_SPLIT, UPDATE_PAYING_AMOUNT } = usePaymentMutations();
    UPDATE_SPLIT(itemsPaying);
    UPDATE_PAYING_AMOUNT(payingAmount);
  };

  return {
    setPaymentFlow,
    clearPayment,
    updatePayingAmount,
    updateDutchPayment,
    updateSplitPayment
  };
};
