<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <g opacity=".54">
        <path d="M0 0h24v24H0z" />
      </g>
      <g>
        <path
          d="M16.75 3c3.65 0 5.75 2.1 5.75 5.75v7c0 3.65-2.1 5.75-5.75 5.75h-10C3.1 21.5 1 19.4 1 15.75v-7C1 5.1 3.1 3 6.75 3h10zm0 1.5h-10C3.89 4.5 2.5 5.89 2.5 8.75v7C2.5 18.61 3.89 20 6.75 20h10c2.86 0 4.25-1.39 4.25-4.25v-7c0-2.86-1.39-4.25-4.25-4.25zm.579 4.28c.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 0 1 .93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
