<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M15.262 4.106a5.455 5.455 0 0 0-2.95 1.02l-.212.16-.017-.013A5.46 5.46 0 0 0 8.69 4.1C5.6 4.1 3.1 6.665 3.1 9.816c0 4.959 3.867 9.284 8.094 10.764.263.097.576.144.906.144.33 0 .643-.047.917-.148C17.237 19.1 21.1 14.78 21.1 9.816c0-3.145-2.504-5.716-5.59-5.716l-.248.006zm.248 1.48c2.257 0 4.104 1.897 4.104 4.23 0 4.256-3.413 8.07-7.1 9.361-.105.04-.247.06-.414.06-.167 0-.309-.02-.404-.056-3.693-1.293-7.11-5.115-7.11-9.365 0-2.338 1.845-4.23 4.104-4.23a3.98 3.98 0 0 1 2.877 1.218c.292.3.774.3 1.066 0a3.98 3.98 0 0 1 2.877-1.218z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
