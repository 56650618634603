import { open as openTable, check as checkTable } from "@/api/Table";
import { type TableData, TableStatus } from "@/api/Table";
import { Saletype } from "@/models/App";
import { CartPrefix } from "@/models/Cart";
import type { Auth } from "@/plugins/auth";
import { useCart } from "@/store/cart";
import { LS, LSKeys } from "@/utils/localStorage";
import { getQueryParam } from "@/utils/queryParams";
import { computed } from "vue";
import type { Router } from "vue-router";
import { useGlobalState } from "..";
import { useSyncActions } from "./sync";

export const useTableGetters = () => {
  const id = computed(() => {
    const { table } = useGlobalState();
    return table.value.id;
  });
  const name = computed(() => {
    const { table } = useGlobalState();
    return table.value.data?.name;
  });
  const maxSittings = computed(() => {
    const { table } = useGlobalState();
    return table.value.data?.maxSittings;
  });
  const currentSittings = computed(() => {
    const { table } = useGlobalState();
    return table.value.data?.currentSittings;
  });
  const priceList = computed(() => {
    const { table } = useGlobalState();
    return table.value.data?.priceLists.length ? table.value.data?.priceLists[0].id : null;
  });
  const status = computed(() => {
    const { table } = useGlobalState();
    return table.value.data?.status || null;
  });

  return {
    id,
    status,
    name,
    maxSittings,
    currentSittings,
    priceList
  };
};

const useTableMutations = () => {
  const SET_TABLE_ID = (id: string) => {
    const { table } = useGlobalState();
    table.value.id = id;
  };
  const CLEAR_TABLE = () => {
    const { table } = useGlobalState();
    table.value.id = null;
    table.value.data = null;
  };
  const SET_TABLE_DATA = (data: TableData) => {
    if (!data) return CLEAR_TABLE();
    const { table } = useGlobalState();
    table.value.data = data;
  };

  return {
    SET_TABLE_ID,
    CLEAR_TABLE,
    SET_TABLE_DATA
  };
};

export const useTableActions = () => {
  const getLSTable = () => {
    return LS.get(LSKeys.TABLE) as { id: string } | null;
  };

  const clearTable = () => {
    const { CLEAR_TABLE } = useTableMutations();
    LS.remove(LSKeys.TABLE);
    CLEAR_TABLE();
  };

  const setTableId = () => {
    const LSTable = getLSTable();
    const id = getQueryParam("tavolo") || LSTable?.id || null;
    const { SET_TABLE_ID } = useTableMutations();
    if (id) {
      LS.set(LSKeys.TABLE, { id });
      SET_TABLE_ID(id);
    } else {
      clearTable();
    }
  };

  const joinTable = async (data: { currentSittings: number }) => {
    const { table } = useGlobalState();
    if (!table.value.id) return;
    await openTable(table.value.id, data);
  };

  const isTableClosed = (tableData: TableData | null, router: Router) => {
    return (
      tableData?.status === TableStatus.AVAILABLE && !/(start)/.test(router.currentRoute.value.name as string || "")
    );
  };

  const mustNewOrdersBeAvoided = (tableData: TableData | null, router: Router) => {
    return (
      tableData?.status === TableStatus.PAYING &&
      !/(checkout|payment)/.test(router.currentRoute.value.name as string || "")
    );
  };

  const handleTableChanges = (router: Router, auth: Auth) => {
    const { startAutoAPISync, stopAutoAPISync } = useSyncActions();
    stopAutoAPISync();
    startAutoAPISync(router, auth);
    const { table } = useGlobalState();
    const { cart, clearCart, initCart } = useCart();
    if (!cart.value) throw new Error("This should never happen");
    const priceList = table.value.data?.priceLists[0].id || "";
    cart.value.priceList = priceList;

    if (isTableClosed(table.value.data, router)) {
      clearCart();
      initCart({ prefix: CartPrefix.TABLE, saletype: Saletype.ORDER_FROM_TABLE, priceList });
      return router.push({ name: "tableClosed" });
    }
    if (mustNewOrdersBeAvoided(table.value.data, router)) {
      clearCart();
      initCart({ prefix: CartPrefix.TABLE, saletype: Saletype.ORDER_FROM_TABLE, priceList });
      return router.push({ name: "checkout" });
    }
  };

  const updateTableData = (tableData: TableData, router: Router, auth: Auth) => {
    const { SET_TABLE_DATA } = useTableMutations();
    SET_TABLE_DATA(tableData);
    handleTableChanges(router, auth);
  };

  const setTableData = async (router: Router, auth: Auth) => {
    const { table } = useGlobalState();
    if (!table.value.id) {
      if (!/(noTable)/.test(router.currentRoute.value.name as string || "")) router.push({ name: "noTable" });
      return;
    }
    const tableData = await checkTable(table.value.id);
    updateTableData(tableData, router, auth);
  };

  return {
    setTableId,
    clearTable,
    joinTable,
    updateTableData,
    setTableData
  };
};
