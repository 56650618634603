<template lang="pug">
div.overflow-hidden(ref="$container")
  img.d-block.h-100.w-100(:src="src" :loading="lazyLoading ? 'lazy' : 'eager'" ref="$img")
</template>

<script setup lang="ts">
import { useApp } from "@/store/app";
import { watch } from "vue";
import { onMounted, ref } from "vue";

const props = defineProps({
  src: {
    type: String,
    required: true
  },
  fallback: {
    type: String,
    required: false
  },
  aspectRatio: {
    type: String,
    default: "1/1"
  },
  lazyLoading: {
    type: Boolean,
    default: false
  },
  height: {
    type: [Number, String]
  },
  contain: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['loaded']);

const { location } = useApp();

const $container = ref();
const $img = ref();

const removeImage = () => {
  $img.value?.remove($img.value);
};

onMounted(() => {
  $img.value.addEventListener(
    "error",
    () => {
      if ($img.value && (props.fallback || location.value?.logoUrl)) {
        $img.value.src = props.fallback || location.value?.logoUrl;
        $img.value.addEventListener(
          "error",
          () => {
            removeImage();
          },
          { once: true }
        );
      } else removeImage();
    },
    { once: true }
  );
  $img.value.addEventListener("load", () => emit('loaded'));
  $img.value.style.objectFit = props.contain ? "contain" : "cover";
  $container.value.style.aspectRatio = props.aspectRatio;
  if (props.height) $container.value.style.height = `${props.height}px`;
});

watch(() => props.aspectRatio, (value) => {
  $container.value.style.aspectRatio = value;
});
</script>
