export function getQueryParam(key: string) {
  /* STEP 1: Recupera e uniforma tutte le query strings sotto forma di URLSearchParams */

  // Gestisce il caso in cui l'url abbia il pattern "webappdev.ipratico.it?orderId=order-123123123123#/pagamento/successo"
  // Nel caso in cui il pattern dell'url fosse "webappdev.ipratico.it?orderId=order-13241234123/#/pagamento/successo" rimuovo lo slash di troppo prima del #
  const realQueryStrings =
    window.location.search.match(/.*(?=\/)/)?.toString() || window.location.search;
  const realSearchParams = realQueryStrings ? new URLSearchParams(realQueryStrings) : null;
  // Gestisce il caso in cui l'url di redirect abbia il pattern "webappdev.ipratico.it/#/pagamento/successo?orderId=order-123123123123"
  const hashQueryStrings = location.hash.match(/\?.*/)?.toString() || null;
  const hashSearchParams = hashQueryStrings ? new URLSearchParams(hashQueryStrings) : null;

  /* STEP 2: Merge degli UrlSearchParams */
  let searchParams: Record<string, string> = {};
  if (realSearchParams) {
    searchParams = {
      ...Object.fromEntries(realSearchParams)
    };
  }
  if (hashSearchParams) {
    searchParams = {
      ...searchParams,
      ...Object.fromEntries(hashSearchParams)
    };
  }

  /* STEP 3: Recupera il valore della chiave passata alla funzione */
  const urlSearchParams = new URLSearchParams(searchParams);
  return urlSearchParams.get(key) || "";
}
