<template lang="pug">
div.landscape-message__container.d-flex.flex-column.justify-center.align-center
  EatImage.landscape-message__image.rounded-circle(:src="getSaletypeImage(cart?.saletype || Saletype.DELIVERY)")
  div.landscape-message__text.text-center.text-white.mt-8 {{ $t("app.mobileLandscapeMessage.message") }}
</template>

<script setup lang="ts">
import { Saletype } from "@/models/App";
import { useApp } from "@/store/app";
import { useCart } from "@/store/cart";

const { cart } = useCart();
const { getSaletypeImage } = useApp();

</script>

<style lang="scss" scoped>
.landscape-message__container {
  height: 100%;
  width: 100%;
  .landscape-message__image {
    height: 50%;
  }
  .landscape-message__text {
    max-width: 30ch;
  }
}
</style>
