import { useApp } from "../app";
import { CartPrefix } from "@/models/Cart";
import type { Auth } from "@/plugins/auth";
import { Saletype } from "@/models/App";
import { useCart } from "../cart";
import { useDeliveryAddresses } from "../delivery/deliveryAddresses";
import { LS } from "@/utils/localStorage";
import { useUser } from "../user";
import { getQueryParam } from "@/utils/queryParams";
import { usePriceLists } from "../menu";

export async function initData(saletype: Saletype, auth: Auth) {
  const { cart, loadCart, cartPrefix, loadRestaurantCart } = useCart();
  const { initLocalStorageAddressService } = useDeliveryAddresses(auth);
  const { setPriceList, getPriceList } = usePriceLists();
  const { app, activeSaletypes, hasOnlyExternalOrder } = useApp();
  const { refreshUser } = useUser();

  const prefix = cartPrefix.value;
  const channel = app.value?.channel;

  if (prefix && channel) {
    LS.init(cartPrefix.value || CartPrefix.RESTAURANT, app.value?.channel || "");
  } else {
    throw Error("This should never happen");
  }

  await initLocalStorageAddressService();

  // Recupero il carrello o ne genero uno nuovo con impostazioni di default
  if (cartPrefix.value === CartPrefix.RESTAURANT) {

    if(!hasOnlyExternalOrder.value) {
      const defaultSaletype = app.value?.defaultService || Saletype.TAKEAWAY;
      if (activeSaletypes.value.includes(defaultSaletype)) {
        saletype = defaultSaletype;
      } else {
        if(activeSaletypes.value.includes(Saletype.DELIVERY)) {
          saletype = Saletype.DELIVERY;
        } else if(activeSaletypes.value.includes(Saletype.TAKEAWAY)) {
          saletype = Saletype.TAKEAWAY;
        }
      }
  
      if(saletype) {
        await loadRestaurantCart(saletype);
        if(cart.value?.saletype && !activeSaletypes.value.includes(cart.value?.saletype)) {
          loadCart({ prefix: cartPrefix.value, saletype });
        }
      }
    }

  } else {
    //Permette di indicare nell'url quale listino prezzi mostrare
    const priceList = getQueryParam("priceList");
    if (priceList) setPriceList(saletype, priceList);
    if (cartPrefix.value) loadCart({ prefix: cartPrefix.value, saletype, priceList: getPriceList(saletype) });
  }

  if (auth.check()) await refreshUser();
}
