<template>
  <svg
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-/-Navigation-24px-/-location---tab" fill="currentColor">
        <path
          d="M13.8266452,0.620216958 L22.8266452,7.52832853 C23.5663685,8.09611641 24,8.97560227 24,9.90811157 L24,21.0128204 C24,22.6696746 22.6568542,24.0128204 21,24.0128204 L15.1861455,24.0128204 L15.1861455,15.6037628 C15.1861455,14.5733062 14.4000854,12.4215118 12,12.4215118 C9.59991455,12.4215118 8.70935181,14.5733062 8.70935181,15.6037628 L8.70935181,24.0128204 L3,24.0128204 C1.34314575,24.0128204 0,22.6696746 0,21.0128204 L0,9.90811157 C0,8.97560227 0.433631532,8.09611641 1.1733548,7.52832853 L10.1733548,0.620216958 C11.2507264,-0.206738986 12.7492736,-0.206738986 13.8266452,0.620216958 Z M12.7306581,2.04808678 C12.3356218,1.7448696 11.7990238,1.71960151 11.3803939,1.97228249 L11.2693419,2.04808678 L2.26934192,8.95619836 C2.01043878,9.15492411 1.84527623,9.44910738 1.80803095,9.76943146 L1.8,9.90811157 L1.8,21.0128204 C1.8,21.6341407 2.27219968,22.1451729 2.87730697,22.2066249 L3,22.2128204 L6.909,22.2121426 L6.90935181,15.6037628 C6.90935181,13.2186688 8.7915464,10.7547539 11.7571844,10.6267259 L12,10.6215118 C15.0383125,10.6215118 16.887081,12.9967484 16.9822805,15.408206 L16.9861455,15.6037628 L16.986,22.2121426 L21,22.2128204 C21.6213203,22.2128204 22.1323525,21.7406207 22.1938045,21.1355134 L22.2,21.0128204 L22.2,9.90811157 C22.2,9.58173332 22.0672003,9.27159349 21.8357777,9.04700878 L21.7306581,8.95619836 L12.7306581,2.04808678 Z"
          id="Location"
        ></path>
      </g>
    </g>
  </svg>
</template>
