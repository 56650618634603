<template>
  <svg
    width="24px"
    height="22px"
    viewBox="0 0 24 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-card06"
        transform="translate(0.000000, -1.000000)"
        fill="#0742A6"
      >
        <path
          d="M18.6666667,1 C21.6121853,1 24,3.38781467 24,6.33333333 L24,17 C24,19.9455187 21.6121853,22.3333333 18.6666667,22.3333333 L5.33333333,22.3333333 C2.38781467,22.3333333 0,19.9455187 0,17 L0,6.33333333 C0,3.38781467 2.38781467,1 5.33333333,1 L18.6666667,1 Z M22.133,10.066 L1.866,10.066 L1.86666667,17 C1.86666667,18.8409492 3.30165144,20.3467397 5.11409588,20.4598464 L5.33333333,20.4666667 L18.6666667,20.4666667 C20.5076158,20.4666667 22.0134063,19.0316819 22.1265131,17.2192375 L22.1333333,17 L22.133,10.066 Z M18.6666667,2.86666667 L5.33333333,2.86666667 C3.49238417,2.86666667 1.98659365,4.30165144 1.87348691,6.11409588 L1.86666667,6.33333333 L1.866,7.666 L22.133,7.666 L22.1333333,6.33333333 C22.1333333,4.49238417 20.6983486,2.98659365 18.8859041,2.87348691 L18.6666667,2.86666667 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
</template>
