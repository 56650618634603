export interface WarehouseItem {
  availableStockQuantity: number;
}

export interface ModuleProduct {
  id: string;
  name: string;
  description?: string;
  priceUpselling?: number;
  priceVariation?: number;
  images: string[];
  instantMenu: boolean;
  warehouseItem?: WarehouseItem;
  allergens: Allergen[];
}

export interface Module {
  name: string;
  maxCustomerChoices: number;
  minCustomerChoices?: number;
  products: ModuleProduct[];
}

export interface ProductComponent {
  productId: string;
  name: string;
  isAllergen: string;
}

export interface MandatoryComponentsGroup {
  name: string;
  components: MandatoryComponent[];
}

export interface MandatoryComponent extends AddableComponent {
  isDefault: boolean;
}

export interface AddableComponent extends ProductComponent {
  addingPrice: number;
}

export interface RemovableComponent extends ProductComponent {
  removingPrice: number;
}

export type ArticleAttributeFieldType = "string" | "boolean" | "number";

export interface ArticleAttribute {
  name: string;
  attributeTypeId: string;
  isMandatory: boolean;
  fieldType: ArticleAttributeFieldType;
}

export interface StringArticleAttribute extends ArticleAttribute {
  fieldType: "string";
  valueSet: string[];
}

export interface NumberArticleAttribute extends ArticleAttribute {
  fieldType: "number";
}

export interface BooleanArticleAttribute extends ArticleAttribute {
  fieldType: "boolean";
}

export type ArticleAttributeValue = string | boolean | number;
export interface ConfigurationArticleAttribute {
  name: string;
  value: ArticleAttributeValue;
}

export interface ProductConfiguration {
  addedComponents: AddableComponent[];
  removedComponents: RemovableComponent[];
  mandatoryComponents: MandatoryComponent[];
  articleAttributes: {
    [id: string]: ConfigurationArticleAttribute;
  };
  modules: {
    [key: string]: ModuleProduct[];
  };
  upsellingModules: {
    [key: string]: ModuleProduct[];
  };
}

export enum AllergenType {
  MILK = "allergen:milk",
  GLUTEN = "allergen:gluten",
  CRUSTACEANS = "allergen:crustaceans",
  EGGS = "allergen:eggs",
  PEANUTS = "allergen:peanuts",
  SOYA = "allergen:soya",
  MUSTARD = "allergen:mustard",
  FISH = "allergen:fish",
  CELERY = "allergen:celery",
  LUPIN = "allergen:lupin",
  MOLLUSCS = "allergen:molluscs",
  NUTS = "allergen:nuts",
  SESAME = "allergen:sesame",
  SULPHITES = "allergen:sulphites",
  DEFAULT = "allergen:default",
}

export interface Allergen {
  name: string;
  imageUrl: string;
  code: AllergenType;
}

export interface Product {
  id: string;
  name: string;
  description?: string;
  isHarmful: boolean;
  price: number;
  category: string;
  categoryId: string;
  tags?: string[];
  allergens: Allergen[];
  images: string[];
  standardComponents: ProductComponent[];
  mandatoryComponentsGroups: MandatoryComponentsGroup[];
  addableComponents: AddableComponent[];
  removableComponents: RemovableComponent[];
  articleAttributes?: ArticleAttribute[];
  isModular?: boolean;
  modules: Module[];
  warehouseItem?: WarehouseItem;
}

export function isValid(configuration: ProductConfiguration, product: Product) {
  if (product.isModular) {
    for (const module of product.modules) {
      if (!(module.name in configuration.modules)) {
        return false;
      }
      if (module.minCustomerChoices) {
        if (configuration.modules[module.name].length < module.minCustomerChoices) {
          return false;
        }
      }
    }
    // at least 1 module selected
    const noneSelected = product.modules.every(module => {
      if (!(module.name in configuration.modules)) {
        return true;
      }
      if (configuration.modules[module.name].length == 0) {
        return true;
      }
      return false;
    });
    if (noneSelected) {
      return false;
    }
  }
  // check article attributes
  if (product.articleAttributes && product.articleAttributes.length) {
    for (const attribute of product.articleAttributes) {
      if (attribute.isMandatory) {
        if (!(attribute.attributeTypeId in configuration.articleAttributes)) {
          return false;
        }
        const value = configuration.articleAttributes[attribute.attributeTypeId].value;
        if (value === null) {
          return false;
        }
        if (typeof value === "string" && value.length == 0) {
          return false;
        }
      }
    }
  }
  return true;
}

export function computePrice(product: Product, configuration: ProductConfiguration): number {
  let total = product.price;
  if (configuration.mandatoryComponents && configuration.mandatoryComponents instanceof Array) {
    for (const comp of configuration.mandatoryComponents) {
      total += comp?.addingPrice || 0;
    }
  }
  for (const comp of configuration.addedComponents) {
    total += comp.addingPrice;
  }
  for (const comp of configuration.removedComponents) {
    total += comp.removingPrice;
  }
  for (const moduleName in configuration.modules) {
    for (const moduleProduct of configuration.modules[moduleName]) {
      total += moduleProduct.priceVariation || 0;
    }
  }
  for (const upsellingName in configuration.upsellingModules) {
    for (const upsellingProduct of configuration.upsellingModules[upsellingName]) {
      total += upsellingProduct.priceUpselling || 0;
    }
  }
  return total;
}
