<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    preserve-aspect-ratio
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Icons-/-Navigation-24px-/-Grey-/-flag---big" fill="currentColor">
      <path
        d="M3,3 L22.4350586,3 L22.4350586,3 L17.5318944,9.26769321 C16.9655642,9.99163061 16.9655642,11.0083694 17.5318944,11.7323068 L22.4350586,18 L22.4350586,18 L4.74572754,18 L4.74572754,21.3774414 L1,21.3774414 L1,5 C1,3.8954305 1.8954305,3 3,3 Z"
        id="Flag-bold"
      ></path>
    </g>
  </svg>
</template>
