<template lang="pug">
component(v-if="icon" :is="iconComponentName" :style="`height: ${size}px; width: ${size}px;`")
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps({
  icon: {
    type: String
  },
  size: {
    type: [Number, String],
    default: 24
  }
});

const iconComponentName = ref("");

watch(
  () => props.icon,
  () => {
    iconComponentName.value = props.icon?.replace("$", "icon-") || "";
  },
  { immediate: true }
);
</script>
