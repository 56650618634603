<template lang="pug">
transition(name="grow-left-fade")
  div.text-field__compressed.bg-white.rounded-xl.d-flex.align-center.justify-center(v-if="expandable && !isExpanded")
    EatIconBtn(:icon="icon" v-if="!isInputFocused" @click="onExpandClick") 
  div.text-field__container.rounded-xl.overflow-hidden(v-else)
    div.text-field.bg-white
      div.text-field__prepend
        EatIcon.mt-2.ms-2(:icon="icon" v-if="!localValue && !isInputFocused")
        EatIconBtn(v-else icon="$back" @click="onExitClick") 
      input.text-field__input.py-4.px-13(v-model="localValue" :name="fieldName" autocomplete="off" :placeholder="placeholder" v-bind="$attrs" ref="inputRef" @focus="onFocusInput" @blur="onBlurInput")
      div.text-field__append
        transition(name="fade" mode="out-in")
          div
            EatIconBtn(v-if="localValue" icon="$close" @click="onClearInput")
            EatIconBtn(v-else-if="appendIcon" :icon="appendIcon" style="color: #b2c0cd;" @click="$emit('append-icon-click')")
            div(v-else)
              slot(name="append-content")
</template>

<script setup lang="ts">
import { computed, ref, nextTick } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
  rules: {
    type: String,
    default: null
  },
  fieldName: {
    type: String,
    default: null
  },
  icon: {
    type: String,
    default: null
  },
  appendIcon: {
    type: String,
    default: null
  },
  hint: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: ""
  },
  geolocation: {
    type: Boolean,
    default: false
  },
  expandable: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue", "blur", "append-icon-click"]);

const isInputFocused = ref(false);
const isExpanded = ref(false);

const inputRef = ref<HTMLElement>();

//Gestisce il v-model sul value dell'input
const localValue = computed({
  get: () => props.modelValue,
  set: (localValue: string) => emit("update:modelValue", localValue)
});

const onClearInput = () => {
  localValue.value = "";
  inputRef.value?.focus();
};

const onBlurInput = () => {
  isInputFocused.value = false;
  if (!localValue.value) isExpanded.value = false;
};

const onFocusInput = () => {
  isInputFocused.value = true;
};

const onExitClick = () => {
  localValue.value = "";
  isInputFocused.value = false;
  isExpanded.value = false;
  emit("blur");
};

const onExpandClick = () => {
  isExpanded.value = true;
  nextTick(() => inputRef.value?.focus());
};
</script>

<style lang="scss" scoped>
.text-field__compressed {
  min-height: 48px;
  max-width: 48px;
}

.text-field__container {
  border: 1px solid #b2c0cd;
  min-height: 48px;
  width: 100%;
}

.text-field {
  position: relative;

  &.text-field__error::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #f23a30;
  }

  .text-field__prepend {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }

  .text-field__append {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .text-field__input {
    &:focus {
      outline: none;
    }
    height: 48px;
    width: 100%;
  }
}

.text-field__messages {
  height: 24px;
  line-height: 24px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.grow-left-fade-enter-active,
.grow-left-fade-leave-active {
  transition: all 0.2s ease;
  transform: scaleX(1);
}

.grow-left-fade-enter-from,
.grow-left-fade-leave-to {
  opacity: 0;
  transform: scaleX(0);
}
</style>
