<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="calendar">
        <!-- filter="url(#filter-1)" -->
        <g transform="translate(0.250000, 0.000000)">
          <path
            d="M7.05599861,14.2024665 C6.88699862,14.1372857 6.75700119,14.0460039 6.62700119,13.9286785 C6.39300118,13.6809914 6.25,13.3550985 6.25,13.0031221 C6.25,12.833652 6.28900238,12.6641756 6.35400238,12.5077417 C6.41900238,12.3513077 6.51000119,12.2079275 6.62700119,12.0775659 C6.75700119,11.9602404 6.88699862,11.8689586 7.05599861,11.8037778 C7.52399863,11.6082354 8.10899881,11.7125534 8.47299881,12.0775659 C8.70699882,12.3252529 8.85,12.664182 8.85,13.0031221 C8.85,13.0813391 8.8369994,13.1726082 8.8239994,13.2638613 C8.81099941,13.3420783 8.78499762,13.4202857 8.74599762,13.4985027 C8.71999762,13.5767196 8.68100238,13.6549271 8.62900238,13.733144 C8.59000238,13.7983248 8.52499881,13.8634977 8.47299881,13.9286785 C8.22599881,14.1633294 7.88799999,14.3067383 7.55,14.3067383 C7.38100001,14.3067383 7.21199861,14.2676473 7.05599861,14.2024665 Z"
            id="Vector"
            fill="currentColor"
            fill-rule="nonzero"
          ></path>
          <path
            d="M11.1559986,14.2024665 C10.9869986,14.1372857 10.8570012,14.0460039 10.7270012,13.9286785 C10.4930012,13.6809914 10.35,13.3550985 10.35,13.0031221 C10.35,12.833652 10.3890024,12.6641756 10.4540024,12.5077417 C10.5190024,12.3513077 10.6100012,12.2079275 10.7270012,12.0775659 C10.8570012,11.9602404 10.9869986,11.8689586 11.1559986,11.8037778 C11.6239986,11.6082354 12.2089988,11.7125534 12.5729988,12.0775659 C12.8069988,12.3252529 12.95,12.664182 12.95,13.0031221 C12.95,13.0813391 12.9369994,13.1726082 12.9239994,13.2638613 C12.9109994,13.3420783 12.8849976,13.4202857 12.8459976,13.4985027 C12.8199976,13.5767196 12.7810024,13.6549271 12.7290024,13.733144 C12.6900024,13.7983248 12.6249988,13.8634977 12.5729988,13.9286785 C12.3259988,14.1633294 11.988,14.3067383 11.65,14.3067383 C11.481,14.3067383 11.3119986,14.2676473 11.1559986,14.2024665 Z"
            id="Vector"
            fill="currentColor"
            fill-rule="nonzero"
          ></path>
          <path
            d="M15.2559986,14.2024665 C15.0869986,14.1372857 14.9570012,14.0460039 14.8270012,13.9286785 C14.5930012,13.6809914 14.45,13.3550985 14.45,13.0031221 C14.45,12.833652 14.4890024,12.6641756 14.5540024,12.5077417 C14.6190024,12.3513077 14.7100012,12.2079275 14.8270012,12.0775659 C14.9570012,11.9602404 15.0869986,11.8689586 15.2559986,11.8037778 C15.7239986,11.6082354 16.3089988,11.7125534 16.6729988,12.0775659 C16.9069988,12.3252529 17.05,12.664182 17.05,13.0031221 C17.05,13.0813391 17.0369994,13.1726082 17.0239994,13.2638613 C17.0109994,13.3420783 16.9849976,13.4202857 16.9459976,13.4985027 C16.9199976,13.5767196 16.8810024,13.6549271 16.8290024,13.733144 C16.7900024,13.7983248 16.7249988,13.8634977 16.6729988,13.9286785 C16.4259988,14.1633294 16.088,14.3067383 15.75,14.3067383 C15.581,14.3067383 15.4119986,14.2676473 15.2559986,14.2024665 Z"
            id="Vector"
            fill="currentColor"
            fill-rule="nonzero"
          ></path>
          <path
            d="M7.05599861,18.2024665 C6.88699862,18.1372857 6.75700119,18.0460039 6.62700119,17.9286785 C6.39300118,17.6809914 6.25,17.3550985 6.25,17.0031221 C6.25,16.833652 6.28900238,16.6641756 6.35400238,16.5077417 C6.41900238,16.3513077 6.51000119,16.2079275 6.62700119,16.0775659 C6.75700119,15.9602404 6.88699862,15.8689586 7.05599861,15.8037778 C7.52399863,15.6082354 8.10899881,15.7125534 8.47299881,16.0775659 C8.70699882,16.3252529 8.85,16.664182 8.85,17.0031221 C8.85,17.0813391 8.8369994,17.1726082 8.8239994,17.2638613 C8.81099941,17.3420783 8.78499762,17.4202857 8.74599762,17.4985027 C8.71999762,17.5767196 8.68100238,17.6549271 8.62900238,17.733144 C8.59000238,17.7983248 8.52499881,17.8634977 8.47299881,17.9286785 C8.22599881,18.1633294 7.88799999,18.3067383 7.55,18.3067383 C7.38100001,18.3067383 7.21199861,18.2676473 7.05599861,18.2024665 Z"
            id="Vector"
            fill="currentColor"
            fill-rule="nonzero"
          ></path>
          <path
            d="M11.1559986,18.2024665 C10.9869986,18.1372857 10.8570012,18.0460039 10.7270012,17.9286785 C10.4930012,17.6809914 10.35,17.3550985 10.35,17.0031221 C10.35,16.833652 10.3890024,16.6641756 10.4540024,16.5077417 C10.5190024,16.3513077 10.6100012,16.2079275 10.7270012,16.0775659 C10.8570012,15.9602404 10.9869986,15.8689586 11.1559986,15.8037778 C11.6239986,15.6082354 12.2089988,15.7125534 12.5729988,16.0775659 C12.8069988,16.3252529 12.95,16.664182 12.95,17.0031221 C12.95,17.0813391 12.9369994,17.1726082 12.9239994,17.2638613 C12.9109994,17.3420783 12.8849976,17.4202857 12.8459976,17.4985027 C12.8199976,17.5767196 12.7810024,17.6549271 12.7290024,17.733144 C12.6900024,17.7983248 12.6249988,17.8634977 12.5729988,17.9286785 C12.3259988,18.1633294 11.988,18.3067383 11.65,18.3067383 C11.481,18.3067383 11.3119986,18.2676473 11.1559986,18.2024665 Z"
            id="Vector"
            fill="currentColor"
            fill-rule="nonzero"
          ></path>
          <path
            d="M15.2559986,18.2024665 C15.0869986,18.1372857 14.9570012,18.0460039 14.8270012,17.9286785 C14.5930012,17.6809914 14.45,17.3550985 14.45,17.0031221 C14.45,16.833652 14.4890024,16.6641756 14.5540024,16.5077417 C14.6190024,16.3513077 14.7100012,16.2079275 14.8270012,16.0775659 C14.9570012,15.9602404 15.0869986,15.8689586 15.2559986,15.8037778 C15.7239986,15.6082354 16.3089988,15.7125534 16.6729988,16.0775659 C16.9069988,16.3252529 17.05,16.664182 17.05,17.0031221 C17.05,17.0813391 17.0369994,17.1726082 17.0239994,17.2638613 C17.0109994,17.3420783 16.9849976,17.4202857 16.9459976,17.4985027 C16.9199976,17.5767196 16.8810024,17.6549271 16.8290024,17.733144 C16.7900024,17.7983248 16.7249988,17.8634977 16.6729988,17.9286785 C16.4259988,18.1633294 16.088,18.3067383 15.75,18.3067383 C15.581,18.3067383 15.4119986,18.2676473 15.2559986,18.2024665 Z"
            id="Vector"
            fill="currentColor"
            fill-rule="nonzero"
          ></path>
          <path
            d="M6.9,24 C2.51999989,24 0,21.4800001 0,17.1 L0,6.9 C0,2.51999989 2.51999989,0 6.9,0 L16.5,0 C20.8800001,0 23.4,2.51999989 23.4,6.9 L23.4,17.1 C23.4,21.4800001 20.8800001,24 16.5,24 L6.9,24 Z M1.8,6.9 L1.8,17.1 C1.8,20.5319999 3.46800013,22.2 6.9,22.2 L16.5,22.2 C19.9319999,22.2 21.6,20.5319999 21.6,17.1 L21.6,6.9 C21.6,3.46800013 19.9319999,1.8 16.5,1.8 L6.9,1.8 C3.46800013,1.8 1.8,3.46800013 1.8,6.9 Z"
            id="Vector"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
