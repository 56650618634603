import { get as getApp } from "@/api/App";
import type { App } from "@/models";
import { type CustomField, PaymentMethod, Saletype } from "@/models/App";
import { CartPrefix } from "@/models/Cart";
import type { NullRef } from "@/utils";
import { setCurrencyCode } from "@/utils/formatPrice";
import { ref, computed } from "vue";
import { useCart } from "./cart";
import { isDeviceAndroid, isDeviceIOS } from "@/utils/userAgentData";

const app: NullRef<App> = ref(null);

// @ts-ignore 
export function useApp() { 
  
  const channel = computed(() => app.value?.channel || '');

  const location = computed(() => app.value?.location);
  const locationAddress = computed(() => location.value?.address + " " + location.value?.city);

  const deliveryService = computed(() => app.value?.services[Saletype.DELIVERY]);
  const takeawayService = computed(() => app.value?.services[Saletype.TAKEAWAY]);
  const reservationService = computed(() => app.value?.services[Saletype.RESERVATION]);
  const takeawayOnSiteService = computed(() => app.value?.services[Saletype.TAKEAWAY_ON_SITE]);
  const orderFromTableService = computed(() => app.value?.services[Saletype.ORDER_FROM_TABLE]);
  const otherServiceService = computed(() => app.value?.services[Saletype.OTHER_SERVICES]);

  const appSaletypes = computed(() => {
    const { cartPrefix } = useCart();
    if (cartPrefix.value === CartPrefix.RESTAURANT)
      return [Saletype.RESERVATION, Saletype.DELIVERY, Saletype.TAKEAWAY, Saletype.OTHER_SERVICES];
    if (cartPrefix.value === CartPrefix.TABLE) {
      if (orderFromTableService.value?.embedDigitalMenu)
        return [Saletype.ORDER_FROM_TABLE, Saletype.DIGITAL_MENU];
      else return [Saletype.ORDER_FROM_TABLE];
    }
    if (cartPrefix.value === CartPrefix.TAKEAWAY_ON_SITE) return [Saletype.TAKEAWAY_ON_SITE];
    if (cartPrefix.value === CartPrefix.DIGITAL_MENU) return [Saletype.DIGITAL_MENU];
    else return [];
  });

  const activeSaletypes = computed(() => {
    if (!app.value) return [];
    const activeServices: Saletype[] = [];
    for (const service in app.value.services) {
      if (app.value.services[service as Saletype].active) activeServices.push(service as Saletype);
    }
    activeServices.push(Saletype.DIGITAL_MENU);
    return appSaletypes.value.filter(saletype => activeServices.includes(saletype));
  });

  const hasOnlyExternalOrder = computed(() => {
    return !activeSaletypes.value.includes(Saletype.TAKEAWAY) && activeSaletypes.value.includes(Saletype.DELIVERY) && deliveryService.value?.externalUrl;
  })

  const saletypeOpeningHours = computed(() => {
    const { cart } = useCart();
    if (cart.value?.saletype === Saletype.DELIVERY) return deliveryService.value?.openingHours;
    if (cart.value?.saletype === Saletype.TAKEAWAY) return takeawayService.value?.openingHours;
  });

  const isAgeRestrictionEnabled = computed(() => {
    const { cart } = useCart();
    return cart.value?.saletype
      ? app.value?.services[cart.value?.saletype]?.ageRestriction?.enabled
      : false;
  });

  const isAuthenticationEnabled = computed(() => {
    const { cart } = useCart();
    return cart.value?.saletype
      ? !app.value?.services[cart.value?.saletype]?.disableAuthentication
      : true;
  })

  const getFromToTimeSlots = (saletype: Saletype) => {
    if (saletype === Saletype.DELIVERY) return !!deliveryService.value?.showTimeSlots;
    if (saletype === Saletype.TAKEAWAY) return !!takeawayService.value?.showTimeSlots;
    if (saletype === Saletype.RESERVATION) return !!reservationService.value?.showTimeSlots;
    return false;
  };

  const customFields = computed(() => {
    return app.value?.services[Saletype.TAKEAWAY_ON_SITE].customFields;
  });

  const refreshApp = async () => {
    app.value = await getApp();
    setCurrencyCode(app.value.currency.acronym);
  }

  const loadApp = async () => {
    if (!app.value) {
      await refreshApp();
    }
  };

  const getPaymentMethodTranslationKey = (method: PaymentMethod) => {
    const isKnownPaymentMethod = [
      PaymentMethod.POSTE_VIRTUAL_POS,
      PaymentMethod.NEXI_XPAY,
      PaymentMethod.CASH_ON_DELIVERY,
      PaymentMethod.FIDELITY_CARD,
      PaymentMethod.PAYPAL,
      PaymentMethod.SATISPAY,
      PaymentMethod.GOOGLE_PAY,
      PaymentMethod.APPLE_PAY,
      PaymentMethod.WORLDLINE
    ].includes(method);
    if (!isKnownPaymentMethod) return `general.paymentMethod.payAtTheCashier`;
    const { cart } = useCart();
    const isCashOnDelivery = method === PaymentMethod.CASH_ON_DELIVERY;
    const isSaletypeDelivery = cart.value?.saletype === Saletype.DELIVERY;
    const paymentMethodKey = isCashOnDelivery && !isSaletypeDelivery ? "payAtTheCashier" : method;
    return `general.paymentMethod.${paymentMethodKey}`;
  };

  const getPaymentIcon = (paymentMethod: string) => {
    if (paymentMethod === PaymentMethod.POSTE_VIRTUAL_POS) return "$creditCardColor";
    if (paymentMethod === PaymentMethod.NEXI_XPAY) return "$creditCardColor";
    if (paymentMethod === PaymentMethod.WORLDLINE) return "$creditCardColor";
    if (paymentMethod === PaymentMethod.CASH_ON_DELIVERY) return "$cash";
    if (paymentMethod === PaymentMethod.FIDELITY_CARD) return "$fidelityPayColor";
    if (paymentMethod === PaymentMethod.PAYPAL) return "$payPalColor";
    if (paymentMethod === PaymentMethod.SATISPAY) return "$satispayColor";
    if (paymentMethod === PaymentMethod.GOOGLE_PAY) return "$googlePayColor";
    if (paymentMethod === PaymentMethod.APPLE_PAY) return "$applePayColor";
    return "$creditCardColor";
  };

  const isMethodToBeShown = (method: PaymentMethod) => {
    if (method === PaymentMethod.GOOGLE_PAY) return isDeviceAndroid;
    if (method === PaymentMethod.APPLE_PAY) return isDeviceIOS;
    if (moneyTypes.value.length && method === PaymentMethod.CASH_ON_DELIVERY) return false;
    return true;
  };

  const moneyTypes = computed(() => {
    const { cart } = useCart();
    const moneyTypes = cart.value ? app.value?.services[cart.value.saletype].enabledMoneyTypes || [] : [];
    return moneyTypes.map(moneyType => ({ ...moneyType, value: `${PaymentMethod.CASH_ON_DELIVERY}|${moneyType.id}` }));
  });

  const paymentMethods = computed(() => {
    const { cart } = useCart();
    const paymentMethods = cart.value ? app.value?.services[cart.value.saletype].paymentMethods || [] : [];
    return paymentMethods.filter(isMethodToBeShown);
  });

  const hasPayAtTheCashier = computed(() => {
    const { cart } = useCart();
    const paymentMethods = cart.value ? app.value?.services[cart.value.saletype].paymentMethods || [] : [];
    return paymentMethods.includes(PaymentMethod.CASH_ON_DELIVERY);
  });

  const hasOnlyPayAtTheCashier = computed(() => {
    const { cart } = useCart();
    const paymentMethods = cart.value ? app.value?.services[cart.value.saletype].paymentMethods || [] : [];
    return paymentMethods.length === 1 && hasPayAtTheCashier.value;
  });

  const getSaletypeImage = (saletype: Saletype) => {
    if(saletype === Saletype.DELIVERY) {
      return new URL('@/assets/images/delivery.jpg', import.meta.url).toString();
    }
    if(saletype === Saletype.TAKEAWAY) {
      return new URL('@/assets/images/takeaway.jpg', import.meta.url).toString();
    }
    if(saletype === Saletype.TAKEAWAY_ON_SITE) {
      return new URL('@/assets/images/takeawayOnSite.jpg', import.meta.url).toString();
    }
    if(saletype === Saletype.ORDER_FROM_TABLE) {
      return new URL('@/assets/images/orderFromTable.jpg', import.meta.url).toString();
    }
    if(saletype === Saletype.DIGITAL_MENU) {
      return new URL('@/assets/images/digitalMenu.jpg', import.meta.url).toString();
    }
  }

  return {
    loadApp,
    refreshApp,
    app,
    channel,
    location,
    locationAddress,
    activeSaletypes,
    hasOnlyExternalOrder,
    deliveryService,
    takeawayService,
    reservationService,
    takeawayOnSiteService,
    orderFromTableService,
    otherServiceService,
    saletypeOpeningHours,
    customFields,
    paymentMethods,
    moneyTypes,
    hasPayAtTheCashier,
    hasOnlyPayAtTheCashier,
    isAgeRestrictionEnabled,
    isAuthenticationEnabled,
    getFromToTimeSlots,
    getPaymentIcon,
    getPaymentMethodTranslationKey,
    getSaletypeImage
  };
}
