import { Saletype } from "@/models/App";
import type { ApiTimeSlot, TimeSlot } from "@/models/TimeSlot";
import { DateTime } from "luxon";
import { get as apiGet, post as apiPost } from "./API";

export interface GetAvailableSlotsParams {
  saleType: Saletype;
  date: DateTime;
  orderItems: {
    productId: string;
    quantity: number;
    removedComponents: string[];
    addedComponents: string[];
  }[];
}

export function mapOrderAvailableSlots(slots: ApiTimeSlot[], fromToTimeSlots: boolean): TimeSlot[] {
  return slots.map(slot => {
    return {
      ...slot,
      isPast: DateTime.fromISO(slot.from) < DateTime.now(),
      time: fromToTimeSlots
        ? `${DateTime.fromISO(slot.from).toFormat("T")} - ${DateTime.fromISO(slot.to).toFormat(
            "T"
          )}`
        : DateTime.fromISO(slot.to).toFormat("T")
    };
  });
}

export function mapReserveAvailableSlots(
  slots: ApiTimeSlot[],
  fromToTimeSlots: boolean
): TimeSlot[] {
  return slots.map(slot => {
    return {
      ...slot,
      isPast: DateTime.fromISO(slot.from) < DateTime.now(),
      time: fromToTimeSlots
        ? `${DateTime.fromISO(slot.from).toFormat("T")} - ${DateTime.fromISO(slot.to).toFormat(
            "T"
          )}`
        : DateTime.fromISO(slot.from).toFormat("T")
    };
  });
}

export async function getOrderAvailableSlots(
  params: GetAvailableSlotsParams,
  fromToTimeSlots: boolean
): Promise<TimeSlot[]> {
  const slots = await apiPost("orders/available-slots", {
    ...params,
    date: params.date.set({ hour: 12 }).toString()
  });
  return mapOrderAvailableSlots(slots as ApiTimeSlot[], fromToTimeSlots);
}

export async function getReservationAvailableSlots(
  date: DateTime,
  fromToTimeSlots: boolean
): Promise<TimeSlot[]> {
  const slots = await apiGet("reservations/available-slots", {
    params: {
      date: date.toString()
    }
  });
  return mapReserveAvailableSlots(slots as ApiTimeSlot[], fromToTimeSlots);
}
