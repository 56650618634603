import { type Address } from "@/models/Address";
import { type DeliveryPrice, Saletype } from "@/models/App";
import { type Cart, CartPrefix, type InitCartOptions } from "@/models/Cart";
import { type Ref, ref, watch } from "vue";
import { DateTime } from "luxon";
import { type DateTimeSlot, useDateTimeSlots } from "@/store/timeSlots/dateTimeSlots";
import { useDeliveryAddresses } from "./delivery/deliveryAddresses";
import { useDeliveryAddressSelector } from "./delivery/deliveryAddress";
import { useTotal } from "./cart/total";
import { LS, LSKeys } from "@/utils/localStorage";
import type { CartPriceVariation, AffiliationDiscount } from "@/models/PriceVariation";
import type { AppliedPromoCode } from "@/models/PromoCode";
import { useApp } from "./app";

const cart = ref<Cart | null>(null);
const cartPrefix: Ref<CartPrefix | null> = ref(null);
const priceList: Ref<string | null> = ref(null);

watch(
  cart,
  () => {
    if (!cart.value) return LS.remove(LSKeys.CART);
    const { computeOrderItemsTotal, computeTotalWithVariation } = useTotal();

    cart.value.subTotal = computeOrderItemsTotal();
    cart.value.total = computeTotalWithVariation();
    LS.set(LSKeys.CART, cart.value);
  },
  { deep: true }
);

export function useCart() {
  const isCartTooOld = (cart: Cart) => {
    if (cart && cart.createdAt) {
      const now = DateTime.now();
      const createdAt = DateTime.fromISO(cart.createdAt);
      const timeDifference = Math.floor(now.diff(createdAt).as("minutes"));
      return timeDifference >= 0 ? timeDifference > 60 : true;
    } else {
      return true;
    }
  };

  const isCartPriceListObsolete = (cart: Cart, priceList?: string | null) => {
    return priceList ? cart?.priceList !== priceList : false;
  };

  const isSaletypeAvailable = (cart: Cart) => {
    const { activeSaletypes } = useApp();
    return activeSaletypes.value.includes(cart.saletype)
  }

  const isCartInvalid = (cart: Cart, newPriceList?: string | null) => {
    return !isSaletypeAvailable(cart) || isCartTooOld(cart) || isCartPriceListObsolete(cart, newPriceList);
  };

  const initCart = (options: InitCartOptions) => {
    cart.value = {
      createdAt: DateTime.now().toString(),
      prefix: options.prefix,
      saletype: options.saletype,
      items: [],
      priceList: options.priceList || null,
      deliveryAddress: options.deliveryAddress ? { ...options.deliveryAddress } : null,
      deliveryPrice: options.deliveryPrice || null,
      subtotalVariation: null,
      affiliationDiscount: null,
      promoCodes: [],
      orderTimeSlot: null,
      total: 0,
      subTotal: 0
    };
  };

  const setCartDeliveryAddress = (address: Address | null, deliveryPrice: DeliveryPrice | null) => {
    if (cart.value) {
      cart.value.deliveryAddress = address;
      cart.value.deliveryPrice = deliveryPrice;
    }
  };

  const setCartOrderTimeSlot = (slot: DateTimeSlot) => {
    if (cart.value) cart.value.orderTimeSlot = slot;
  };

  const setCartPromoCodes = (promoCodes: AppliedPromoCode[]) => {
    if (cart.value) cart.value.promoCodes = promoCodes;
  };

  const setCartSubtotalVariation = (variation: CartPriceVariation | null) => {
    if (cart.value) cart.value.subtotalVariation = variation;
  };

  const setCartMembershipDiscount = (discount: AffiliationDiscount | null) => {
    if (cart.value) cart.value.affiliationDiscount = discount;
  };

  const loadCart = (options: InitCartOptions) => {   
    const cartJSON = LS.get(LSKeys.CART) as Cart | null | "null";
    if (!cartJSON || cartJSON === "null" || isCartInvalid(cartJSON, options.priceList)) {
      if (options.priceList) options = { ...options, priceList: options.priceList };
      initCart(options);
    } else {
      cart.value = cartJSON as Cart;
      cart.value.createdAt = DateTime.now().toString();
    }
    if (!cart.value) return;
    if (!cart.value.deliveryAddress || !cart.value.deliveryPrice) {
      if (options.deliveryAddress && options.deliveryPrice)
        setCartDeliveryAddress(options.deliveryAddress, options.deliveryPrice);
    }
  };

  const setInitialCartDeliveryAddress = () => {
    const { getInitialDeliveryAddress } = useDeliveryAddresses();
    const { getDeliveryPrice } = useDeliveryAddressSelector();
    const deliveryAddress = getInitialDeliveryAddress();
    const deliveryPrice: DeliveryPrice | null = getDeliveryPrice(deliveryAddress);
    setCartDeliveryAddress(deliveryAddress, deliveryPrice);
  };

  const loadRestaurantCart = async (saletype: Saletype) => {
    loadCart({ prefix: CartPrefix.RESTAURANT, saletype: saletype || Saletype.DELIVERY });

    if (cart.value && [Saletype.DELIVERY, Saletype.TAKEAWAY].includes(saletype)) {

      const { isDateTimeStillValid, getTodaysFirstAvailableOrderDateTimeSlot } = useDateTimeSlots();
      const isValid = cart.value?.orderTimeSlot && (await isDateTimeStillValid(cart.value?.orderTimeSlot));
      if (!isValid) cart.value.orderTimeSlot = await getTodaysFirstAvailableOrderDateTimeSlot();

      if (saletype === Saletype.DELIVERY && (!cart.value.deliveryAddress || !cart.value.deliveryPrice)) {
        setInitialCartDeliveryAddress(); // Imposto l'indirizzo di delivery
      }
      
    }
  };

  const initRestaurantCart = async (saletype: Saletype) => {
    initCart({ prefix: CartPrefix.RESTAURANT, saletype });

    const { getTodaysFirstAvailableOrderDateTimeSlot } = useDateTimeSlots();
    if (cart.value && (saletype === Saletype.DELIVERY || saletype === Saletype.TAKEAWAY)) {
      cart.value.orderTimeSlot = await getTodaysFirstAvailableOrderDateTimeSlot();
      if (saletype === Saletype.DELIVERY) {
        // Imposto l'indirizzo di delivery
        setInitialCartDeliveryAddress();
      }
    }
  };

  const clearCart = () => {
    if (!cart.value) throw new Error("Cart can't be null");
    cart.value = null;
    LS.remove(LSKeys.CART);
  };

  return {
    cart,
    cartPrefix,
    priceList,
    loadCart,
    initCart,
    clearCart,
    setCartDeliveryAddress,
    setCartOrderTimeSlot,
    setCartPromoCodes,
    setCartSubtotalVariation,
    setCartMembershipDiscount,
    loadRestaurantCart,
    initRestaurantCart,
    setInitialCartDeliveryAddress
  };
}
