<template>
  <svg
    width="20px"
    height="17px"
    viewBox="0 0 20 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Table" transform="translate(-2.000000, -4.000000)">
        <g transform="translate(2.000000, 4.500000)">
          <path
            d="M17.5,3.83333333 L18.2779008,15.5018457 C18.3065423,15.9314685 17.9814828,16.3029651 17.5518601,16.3316066 L17.5,16.3333333 L17.5,16.3333333 C17.0310583,16.3333333 16.6424277,15.9697488 16.6112342,15.5018457 L15.944,5.499 L4.055,5.499 L3.38876584,15.5018457 C3.3575723,15.9697488 2.96894174,16.3333333 2.5,16.3333333 C2.06942358,16.3333333 1.72037242,15.9842822 1.72037242,15.5537058 C1.72037242,15.5364047 1.72094833,15.5191084 1.72209918,15.5018457 L2.5,3.83333333 L17.5,3.83333333 Z M19,0 C19.5522847,-1.01453063e-16 20,0.44771525 20,1 L20,2.5 L0,2.5 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L19,0 Z"
            id="Combined-Shape"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
