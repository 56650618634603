import type { AppLocation, OpeningHours } from "./AppLocation";

export enum Saletype {
  DELIVERY = "delivery",
  TAKEAWAY = "takeaway",
  RESERVATION = "reservation",
  TAKEAWAY_ON_SITE = "takeawayOnSite",
  ORDER_FROM_TABLE = "orderFromTable",
  DIGITAL_MENU = "digitalMenu",
  OTHER_SERVICES = "otherServices"
}

export enum PaymentMethod {
  POSTE_VIRTUAL_POS = "posteVirtualPos",
  NEXI_XPAY = "nexiXPay",
  CASH_ON_DELIVERY = "cashOnDelivery",
  FIDELITY_CARD = "fidelityCardPrepaidAmount",
  PAYPAL = "payPal",
  SATISPAY = "satispay",
  GOOGLE_PAY = "googlePay",
  APPLE_PAY = "applePay",
  WORLDLINE = "worldLine"
}

export enum PaymentFlow {
  STANDARD = "standard",
  DEPOSIT = "deposit",
  SPLIT = "split",
  DUTCH = "dutch"
}

export interface MoneyType {
  id: string;
  label: string;
}

export interface ServiceData {
  active: boolean;
  asking?: {
    cutlery: boolean;
  };
  paymentMethods: PaymentMethod[];
  openingHours?: OpeningHours[];
  crossSelling?: {
    message: string;
    categories: string[];
    image: string;
  };
  showTimeSlots?: boolean;
  showQrCode: boolean;
  orderConfirmedMessage?: string;
  ageRestriction: {
    enabled: boolean;
    threshold: number;
  };
  enabledMoneyTypes?: MoneyType[]
  disableAuthentication?: boolean
}

export interface DeliveryPrice {
  isPercentage: boolean;
  price: 0;
  serviceChargeId: string;
  minPurchase?: number;
}

export interface ZipCodeDeliveryPrice extends DeliveryPrice {
  zipCode: string;
}

export interface KmRangeDeliveryPrice extends DeliveryPrice {
  kmRange: string;
}

export interface DeliveryService extends ServiceData {
  maxDeliveryDistance: number;
  externalUrl: string;
  defaultDeliveryPrice?: DeliveryPrice;
  zipCodeDeliveryCosts?: ZipCodeDeliveryPrice[];
  kmRangeDeliveryCosts?: KmRangeDeliveryPrice[];
  minPurchase: number;
  allowedPostalCodes: string[];
}

export interface ReservationService extends ServiceData {
  externalUrl: string;
  maxCoversForTableReservation?: number;
  slots: OpeningHours[];
}

export interface CustomField {
  name: string; // nome campo
  label: string; // etichetta da mostrare nella ui
  type: string; // tipo di input
  isMandatory: boolean; // obbligatorietà della compilazione
  isHidden: boolean; // campo visibile o meno
  default: string; // valore di default
  rules: string;
  value: any;
}

export interface TakeawayOnSiteService extends ServiceData {
  customFields?: CustomField[];
}

export type TakewayService = ServiceData;
export type OtherServicesService = ServiceData;

export interface OrderFromTableService extends ServiceData {
  skipCovers: boolean;
  enableSplitAndDutchPayments: boolean;
  embedDigitalMenu: boolean;
}

type HTMLColor = string;
export enum MandatoryFields {
  GENDER = "gender",
  BIRTH_DATE = "birthDate"
}
export interface App {
  appUrl?: string;
  companyVAT?: string;
  appMasterUrl?: string;
  primaryColor: HTMLColor;
  secondaryColor: HTMLColor;
  availableLanguages: Array<string>;
  coverImageUrl: string;
  urlPrivacyPolicy: string;
  urlTermsOfSales: string;
  urlMarketing: string;
  mandatoryFields: Record<MandatoryFields, boolean>;
  defaultService: Saletype;
  profiling?: {
    isHidden: boolean;
    pdfUrl: string;
  };
  currency: {
    acronym: string;
  };
  location: AppLocation;
  services: {
    [Saletype.RESERVATION]: ReservationService;
    [Saletype.DELIVERY]: DeliveryService;
    [Saletype.TAKEAWAY]: TakewayService;
    [Saletype.TAKEAWAY_ON_SITE]: TakeawayOnSiteService;
    [Saletype.ORDER_FROM_TABLE]: OrderFromTableService;
    [Saletype.OTHER_SERVICES]: OtherServicesService;
    [Saletype.DIGITAL_MENU]: ServiceData;
  };
  channel?: string;
  GTMContainerId?: string;
  pdfFileAllergens?: string;
}

export const hasService = (app: App, saletype: Saletype) => {
  return app.services[saletype].active;
};
