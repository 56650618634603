import { getUserPlaces, updatePlaces } from "@/api/User";
import { type Address, isNotDuplicateAddress } from "@/models/Address";
import type { Auth } from "@/plugins/auth";
import { LS, LSKeys } from "@/utils/localStorage";

export function useLocalStorageAddressesService(auth?: Auth) {
  // Fa l'update degli indirizzi in locale e, se l'utente è loggato, in modo asincrono aggiorna anche le api
  const updateLocalStorageAddresses = (addresses: Address[]) => {
    LS.set(LSKeys.ADDRESSES, addresses);
    if (auth && auth.check()) {
      updatePlaces(JSON.parse(JSON.stringify(addresses)));
    }
  };

  // Concatena due array di address rimuovendo i duplicati e salva il tutto in locale (e tramite api)
  const mergeAddresses = (localAddresses: Address[], addressesToBeMerged: Address[]) => {
    if (localAddresses.length) {
      const uniqueAddresses = localAddresses
        .filter((address: Address) => isNotDuplicateAddress(addressesToBeMerged, address))
        .map(address => ({ ...address, isDefaultDeliveryAddress: false }));
      addressesToBeMerged.push(...uniqueAddresses);
    }
    updateLocalStorageAddresses(addressesToBeMerged);
    return addressesToBeMerged;
  };

  // Permette di mergiare gli indirizzi presenti in locale con quelli provenienti dall'api che ritorna il profilo dell'user
  const mergeLocalStorageAndApiAddresses = async (addresses: Address[]) => {
    const userPlaces = await getUserPlaces();
    addresses = mergeAddresses(addresses, userPlaces || []);
    return addresses;
  };

  const getLocalStorageAddresses = async (): Promise<Address[]> => {
    let addresses: string | null | Address[] = LS.get(LSKeys.ADDRESSES);
    if (addresses && addresses !== "null" && addresses.length) {
      return addresses as Address[];
    } else {
      if (auth && auth.check()) {
        addresses = await mergeLocalStorageAndApiAddresses([]);
      } else {
        addresses = [];
        updateLocalStorageAddresses(addresses);
      }
      return addresses;
    }
  };

  const clearAddresses = () => {
    LS.set(LSKeys.ADDRESSES, []);
  };

  return {
    updateLocalStorageAddresses,
    getLocalStorageAddresses,
    mergeAddresses,
    clearAddresses,
    mergeLocalStorageAndApiAddresses
  };
}
