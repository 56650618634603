<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-/-Navigation-24px-/-Grey-/-fidelity---big" fill="currentColor">
        <path
          d="M16.75,0 C21.1300001,0 23.65,2.51999989 23.65,6.9 L23.65,17.1 C23.65,21.4800001 21.1300001,24 16.75,24 L7.15,24 C2.76999989,24 0.25,21.4800001 0.25,17.1 L0.25,6.9 C0.25,2.51999989 2.76999989,0 7.15,0 L16.75,0 Z M16.75,1.8 L7.15,1.8 C3.71800013,1.8 2.05,3.46800013 2.05,6.9 L2.05,17.1 C2.05,20.5319999 3.71800013,22.2 7.15,22.2 L16.75,22.2 C20.1819999,22.2 21.85,20.5319999 21.85,17.1 L21.85,6.9 C21.85,3.46800013 20.1819999,1.8 16.75,1.8 Z M11.302243,5.60867505 C11.6078408,5.06377498 12.3921592,5.06377498 12.697757,5.60867505 L12.697757,5.60867505 L14.416,8.673 L17.8628925,9.36136567 C18.439519,9.47643145 18.6881307,10.1439556 18.3620625,10.6047811 L18.3620625,10.6047811 L18.29413,10.6885783 L15.91,13.27 L16.3212238,16.7605593 C16.3899771,17.3445209 15.8319492,17.787241 15.2929175,17.6195347 L15.2929175,17.6195347 L15.1922293,17.5808218 L12,16.111 L8.80777069,17.5808218 C8.27363607,17.8266641 7.68014415,17.4327561 7.67307239,16.8682823 L7.67307239,16.8682823 L7.67877621,16.7605593 L8.089,13.27 L5.70586999,10.6885783 C5.30700332,10.2565555 5.49823307,9.5703869 6.03289419,9.38922928 L6.03289419,9.38922928 L6.13710751,9.36136567 L9.583,8.673 Z M12,7.635 L10.7986891,9.77748224 C10.6843776,9.98130684 10.4866584,10.1249582 10.2574855,10.1706897 L10.2574855,10.1706897 L7.849,10.651 L9.51503451,12.4557188 C9.64713827,12.5988038 9.7216014,12.784063 9.72693973,12.9761517 L9.72693973,12.9761517 L9.72175588,13.0919418 L9.434,15.531 L11.6655178,14.5041675 C11.842422,14.422745 12.0416243,14.4091746 12.2259612,14.4634563 L12.2259612,14.4634563 L12.3344822,14.5041675 L14.565,15.531 L14.2782441,13.0919418 C14.2554732,12.898535 14.3041238,12.7048888 14.412712,12.546348 L14.412712,12.546348 L14.4849655,12.4557188 L16.15,10.651 L13.7425145,10.1706897 C13.5515371,10.1325801 13.3824025,10.0264706 13.2651768,9.87420535 L13.2651768,9.87420535 L13.2013109,9.77748224 L12,7.635 Z"
          id="fidelity"
        ></path>
      </g>
    </g>
  </svg>
</template>
