<template>
  <svg
    width="200px"
    height="200px"
    viewBox="0 0 200 200"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="09.-Table-order" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="9-7-13.-Shop-/-cart-/-checkout-/-message"
        transform="translate(-107.000000, -100.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <path
          d="M207,100 C233.521648,100 258.957041,110.535685 277.710679,129.289323 C296.464317,148.042957 307,173.47835 307,200 C307,255.228472 262.228472,300 207,300 C151.771527,300 107,255.228473 107,200 C107,144.771526 151.771526,100 207,100 Z M207,115.12605 C160.125413,115.12605 122.12605,153.125413 122.12605,200 C122.12605,246.874586 160.125414,284.87395 207,284.87395 C253.874585,284.87395 291.87395,246.874585 291.87395,200 C291.87395,177.490028 282.931899,155.902006 267.014947,139.985057 C251.097993,124.068102 229.50997,115.12605 207,115.12605 Z M174.444825,223.705574 C176.384899,228.759339 179.563895,233.24452 183.655882,236.720451 C197.069087,247.979069 216.589121,248.121383 230.13641,237.082994 C234.321516,233.638687 237.575543,229.199369 239.600457,224.171638 C241.160912,220.297127 245.566823,218.421214 249.441334,219.98167 C253.315845,221.542125 255.191758,225.948036 253.631302,229.822547 C250.660939,237.197771 245.887577,243.709845 239.719761,248.78579 C220.516214,264.433086 192.904576,264.231778 173.897355,248.277553 C167.839594,243.13205 163.17205,236.546707 160.323548,229.126546 C158.826588,225.227064 160.774218,220.852382 164.673701,219.355422 C168.573184,217.858462 172.947865,219.806091 174.444825,223.705574 Z M251.095211,168.978812 C253.351391,171.234992 253.539406,174.776166 251.659256,177.246512 L251.095211,177.891923 L242.34819,186.645159 L251.095211,195.385388 C253.351391,197.641568 253.539406,201.182742 251.659256,203.653088 L251.095211,204.298499 C248.839031,206.554679 245.297857,206.742694 242.827511,204.862544 L242.1821,204.298499 L228.978812,191.095211 C226.722632,188.839031 226.534617,185.297857 228.414767,182.827511 L228.978812,182.1821 L242.1821,168.978812 C244.643388,166.517525 248.633923,166.517525 251.095211,168.978812 Z M176,171 C184.836556,171 192,178.163444 192,187 C192,195.836556 184.836556,203 176,203 C167.163444,203 160,195.836556 160,187 C160,178.163444 167.163444,171 176,171 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
</template>
