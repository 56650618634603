import type { APIError } from "@/api/API";
import config from "@/config";
import { setErrorSnackbar } from "@/store/layout/snackbar";
import useLoginLogout from "@/store/loginLogout";
import axios, { type AxiosInstance, type InternalAxiosRequestConfig } from "axios";
import { DateTime } from "luxon";
import { type Router } from "vue-router";
import { ref, type App } from "vue";
/**
 * Compute the clientId for a certain hostname
 * @param hostname Hostname for which to compute the client ID
 */
export function getClientId(location: Location): string {
  const testHostnames = ["localhost", "0.0.0.0", "127.0.0.1", "192.168.0.230"];

  if (testHostnames.includes(location.hostname)) {
    if (location.pathname.includes("multilocation")) return "webappdevcompany";
    else return "webappdev";
  }

  const parts = location.hostname.split(".");
  while (parts.length > 3) {
    parts.shift();
  }
  return parts[0];
}

/**
 * Generate OAuth base token to be used for requesting the other tokens.
 * It is computed as the base64(clientId:someSecret)
 */
export function getOAuthBaseToken(): string {
  const clientId = getClientId(window.location);
  const oauthToken = btoa(`${clientId}:${config.oauthClientSecret}`);
  return oauthToken;
}

interface AuthOptions {
  oauthURL: string;
  baseToken: string;
  appAuthScope: string;
}

enum AuthLocalStorage {
  AuthUserToken = "Auth_userToken",
  AuthUserTokenRefreshToken = "Auth_userTokenRefreshToken",
  AuthUserTokenExpiresAt = "Auth_userTokenExpiresAt"
}
/**
 * Class to manage the authentication stuff
 */
export class Auth {
  clientToken?: string;
  userToken?: string;
  private appAuthScope: string;
  private userTokenRefreshToken?: string;
  private userTokenExpiresAt?: DateTime;
  private oauthURL: string;
  private baseToken: string;
  ready: boolean;
  private checkExpiredUserTokenInterval?: number | undefined = undefined;
  private refreshClientTokenInterval?: number | undefined = undefined;

  CHECK_EXPIRED_USER_TOKEN_INTERVAL_MS = 2 * 60 * 1000;
  REFRESH_CLIENT_TOKEN_INTERVAL_MS = 10 * 60 * 1000;

  constructor({ oauthURL, baseToken, appAuthScope }: AuthOptions) {
    this.ready = false;

    this.oauthURL = oauthURL;
    this.baseToken = baseToken;
    this.appAuthScope = appAuthScope;

    const userToken = localStorage.getItem(AuthLocalStorage.AuthUserToken) || null;
    const userTokenRefreshToken =
      localStorage.getItem(AuthLocalStorage.AuthUserTokenRefreshToken) || null;
    const userTokenExpiresAtISO =
      localStorage.getItem(AuthLocalStorage.AuthUserTokenExpiresAt) || null;
    if (userToken && userTokenRefreshToken && userTokenExpiresAtISO) {
      this.userToken = userToken;
      this.userTokenRefreshToken = userTokenRefreshToken;
      this.userTokenExpiresAt = DateTime.fromISO(userTokenExpiresAtISO);
    }
  }

  async start() {
    // Setup things for client token
    this.refreshClientTokenInterval = setInterval(
      this.refreshClientToken.bind(this),
      this.REFRESH_CLIENT_TOKEN_INTERVAL_MS
    ) as unknown as number;
    await this.refreshClientToken();

    // Setup things for user token
    if (this.userToken) {
      this.checkExpiredUserTokenInterval = setInterval(
        this.checkExpiredUserToken.bind(this),
        this.CHECK_EXPIRED_USER_TOKEN_INTERVAL_MS
      ) as unknown as number;
      await this.checkExpiredUserToken();
    }
    this.ready = true;
  }

  private async checkExpiredUserToken() {
    if (this.userTokenExpiresAt) {
      const now = DateTime.local();
      if (now > this.userTokenExpiresAt) {
        await this.refreshUserToken();
      }
    }
  }

  setUserToken(userToken: string) {
    this.userToken = userToken;
  }

  check() {
    return this.userToken != null;
  }

  async login(username: string, password: string) {
    const params = {
      grant_type: "password", // eslint-disable-line @typescript-eslint/camelcase
      scope: "app-user:user", //app-user:app
      username,
      password
    };
    const queryString = new URLSearchParams();
    for(const [param, value] of Object.entries(params)) {
      queryString.append(param, value);
    }
    const response = await axios.post(
      this.oauthURL,
      queryString.toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${this.baseToken}`
        }
      }
    );
    this.checkExpiredUserTokenInterval = setInterval(
      this.checkExpiredUserToken.bind(this),
      this.CHECK_EXPIRED_USER_TOKEN_INTERVAL_MS
    ) as unknown as number;
    localStorage.setItem(AuthLocalStorage.AuthUserToken, response.data.access_token);
    this.userToken = response.data.access_token;
    localStorage.setItem(AuthLocalStorage.AuthUserTokenRefreshToken, response.data.refresh_token);
    this.userTokenRefreshToken = response.data.refresh_token;
    localStorage.setItem(AuthLocalStorage.AuthUserTokenExpiresAt, response.data.expires_at);
    this.userTokenExpiresAt = DateTime.fromISO(response.data.expires_at);
    return response;
  }

  private async refreshUserToken() {
    try {
      const params = {
        grant_type: "refresh_token",
        refresh_token: this.userTokenRefreshToken
      };
      const queryString = new URLSearchParams();
      for(const [param, value] of Object.entries(params)) {
        queryString.append(param, value || '');
      }
      const response = await axios
        .post(
          this.oauthURL,
          queryString.toString(),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Basic ${this.baseToken}`
            }
          }
        )
        .catch(e => {
          const error = e as APIError;
          if (
            error?.response?.status &&
            error.response.status >= 400 &&
            error.response.status < 500
          ) {
            setErrorSnackbar(error as Error);
            this.logout();
            //Timeout di 2 secondi per mostrare l'errore nella snackbar prima che venga ricaricata l'immagine
            setTimeout(() => window.location.reload(), 2000);
          }
        });
      if (response) {
        localStorage.setItem(AuthLocalStorage.AuthUserToken, response.data.access_token);
        this.userToken = response.data.access_token;
        localStorage.setItem(
          AuthLocalStorage.AuthUserTokenRefreshToken,
          response.data.refresh_token
        );
        this.userTokenRefreshToken = response.data.refresh_token;
        localStorage.setItem(AuthLocalStorage.AuthUserTokenExpiresAt, response.data.expires_at);
        this.userTokenExpiresAt = DateTime.fromISO(response.data.expires_at);
      }
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  private async refreshClientToken() {
    const params = {
      grant_type: "client_credentials",
      scope: this.appAuthScope
    };
    const queryString = new URLSearchParams();
    for(const [param, value] of Object.entries(params)) {
      queryString.append(param, value || '');
    }
    const response = await axios.post(
      this.oauthURL,
      queryString.toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${this.baseToken}`
        }
      }
    );
    this.clientToken = response.data.access_token;
  }

  async logout() {
    this.userToken = undefined;
    localStorage.removeItem(AuthLocalStorage.AuthUserToken);
    this.userTokenRefreshToken = undefined;
    localStorage.removeItem(AuthLocalStorage.AuthUserTokenRefreshToken);
    this.userTokenExpiresAt = undefined;
    localStorage.removeItem(AuthLocalStorage.AuthUserTokenExpiresAt);
    clearInterval(this.checkExpiredUserTokenInterval);
  }
}

/*
  Vue plugin definition
*/
interface AuthPluginOptions {
  router: Router;
  axios: AxiosInstance;
}
let pluginInstalled = false;
export const AuthPlugin = {
  install(
    app: App,
    {
      authOptions,
      pluginOptions: { router, axios }
    }: { authOptions: AuthOptions; pluginOptions: AuthPluginOptions }
  ): void {
    if (pluginInstalled) return;
    pluginInstalled = true;
    const auth = new Auth(authOptions);
    
    app.provide('auth', ref(auth));
    app.config.globalProperties.$auth = auth;

    axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
      if (request.headers && !request.headers?.Authorization) {
        // @ts-ignore
        if (request.requiresAuth) { 
          request.headers.Authorization = `Bearer ${auth.userToken}`;
        } else {
          request.headers.Authorization = `Bearer ${auth.clientToken}`;
        }
      }
      return request;
    });

    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(e) {
        const error = e as APIError;
        if (error?.response?.status === 401) {
          setErrorSnackbar(error as Error);
          const { logout } = useLoginLogout();
          logout(auth);
          //Timeout di 2 secondi per mostrare l'errore nella snackbar prima che venga ricaricata la pagina
          setTimeout(() => router.go(0), 2000);
        }
        return Promise.reject(error);
      }
    );
  }
};
