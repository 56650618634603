<template lang="pug">
div#shell.flex-grow-1.d-flex.flex-column.mx-auto.overflow-hidden
  div#shell-content.flex-grow-1.w-100.position-relative.d-flex.flex-column.overflow-hidden
    div#application.flex-grow-1.w-100.d-flex.flex-column.overflow-hidden
      template(v-if="appLoading") 
        main#main.flex-grow-1.bg-white.d-flex.align-center
          Loading.text-primary
      template(v-else)
        header#header.position-relative.flex-grow-0.flex-shrink-0(ref="$topBar")
          router-view(name="topBar")
        main#main.position-relative.flex-grow-1.d-flex.flex-column(v-scrollable="false") 
          slot(name="default")
          router-view
        nav#navigation.position-relative.flex-grow-0.flex-shrink-0(ref="$bottomBar")
          EatNavigation(:navigationItems="navigationItems")

    div#dialog.absolute-blr.w-100.overflow-hidden.z-index-10
    div#age-restriction-dialog.absolute-blr.w-100.overflow-hidden.z-index-10
    div#onboarding-dialog.absolute-blr.w-100.overflow-hidden.z-index-10
    div#date-time-slot-dialog.absolute-blr.w-100.overflow-hidden.z-index-10
    div#network-response-dialog.absolute-blr.w-100.overflow-hidden.z-index-10
    div#more.absolute-blr.w-100.overflow-hidden.z-index-10
      router-view(name="more")

  div#snackbar.flex-shrink-0(ref="$snackbar")
    EatSnackbar

div#mobile-landscape-message.absolute-center.w-100.h-100.bg-primary
  MobileLandscapeMessage
</template>
  
<script setup lang="ts">
/* The base app from which all the pages will inherit from */
import Loading from "@/components/utils/Loading.vue";
import EatNavigation from "@/layout/partials/EatNavigation.vue";
import EatSnackbar from "@/layout/partials/EatSnackbar.vue";
import MobileLandscapeMessage from "@/layout/partials/MobileLandscapeMessage.vue";
import { useRouter } from "vue-router";
import { setAppLocale } from "@/utils/localization";
import { loadGoogleMaps, setGTag } from "@/utils/googleUtils";
import {
  computed,
  onMounted,
  type PropType,
  ref,
  watch,
  nextTick
} from "vue";
import { useApp } from "./store/app";
import { useAppTitle, setAppTitle } from "./store/appTitle";
import type { NavigationItem } from "@/types";
import { useI18n } from "vue-i18n";
import { useAppHeight } from "@/store/layout/layout";
import { initData } from "@/store/init/initData";
import { CartPrefix } from "./models/Cart";
import type { Saletype } from "./models/App";
import { useCart } from "./store/cart";
import { useGlobalActions } from "./store/table";
import type { Auth } from "./plugins/auth";
import { inject } from "vue";
import type { Ref } from "vue";
import { setupColors } from "./styles/setupColors";

const props = defineProps({
  navigationItems: {
    type: Array as PropType<NavigationItem[]>,
    required: false,
    default: () => []
  },
  cartPrefix: {
    type: String as PropType<CartPrefix>,
    required: false
  },
  defaultSaletype: {
    type: String as PropType<Saletype>,
    required: false
  }
});

const { app, loadApp, refreshApp } = useApp();
const { cartPrefix } = useCart();
const { initTable } = useGlobalActions();
const router = useRouter();
const auth = inject('auth') as Ref<Auth>;
const i18n = useI18n();
const appInitializing = ref(true);

const appTitle = useAppTitle();
setAppTitle(i18n.t("app.defaultTitle").toString());

const appHeight = useAppHeight();
const doc = document.documentElement;
const recalculateAppHeight = () => {
  nextTick(() => {
    const height = window.innerHeight;
    doc.style.setProperty("--shell-height", `${height}px`);
    appHeight.value = height;
  });
};
const recalculateStandaloneAppHeight = () => {
  nextTick(() => {
    const height = document.querySelector("body")?.clientHeight || 0;
    appHeight.value = height;
  });
};
const $topBar = ref();
const $bottomBar = ref();
const $snackbar = ref();
// wait for the app to be ready before showin the rest of the components
const appLoading = computed((): boolean => {
  return !auth.value.ready || appInitializing.value;
});

onMounted(async () => {
  // Quando sono nel browser la viewport è più grande della window
  // Con "--shell-height: 100vh" l'applicazione andrebbe in overflow e la bottombar sarebbe nascosta
  // Quindi utilizzo l'innerHeight della window per definire l'altezza dell'app
  const orientation = "screen" in window ? screen.orientation : null;

  if (!window.matchMedia("(display-mode: standalone)").matches) {
    recalculateAppHeight();
    window.onresize = recalculateAppHeight;
    if (orientation) orientation.onchange = recalculateAppHeight;
  } else {
    recalculateStandaloneAppHeight();
    window.onresize = recalculateStandaloneAppHeight;
    if (orientation) orientation.onchange = recalculateStandaloneAppHeight;
  }

  await auth.value.start();

  await loadGoogleMaps();

  if (props.cartPrefix && props.defaultSaletype) {
    // for multilocation, we need the app framework but not the data

    // Definisco il cart prefix
    cartPrefix.value = props.cartPrefix;
    // Recupero i settings dell'applicazione
    await Promise.all([loadApp(), setGTag(app)]);
    if (!app.value) throw Error("app.value null. This should never happen");
    await setAppLocale(i18n, app);
    refreshApp(); //Ricarica le info della location localizzate

    await initData(props.defaultSaletype, auth.value);

    if (cartPrefix.value === CartPrefix.TABLE) {
      await initTable(router, auth.value);
    }

    setupColors(app.value.primaryColor, app.value.secondaryColor)
  }
  appInitializing.value = false;

  nextTick(() => {
    new ResizeObserver(entries => {
      const height = $snackbar.value?.clientHeight;
      doc.style.setProperty("--snackbar-height", `${height}px`);
    }).observe($snackbar.value);
  });
});

// watch the app title to update the HTML document title
watch(
  appTitle,
  newAppTitle => {
    let titleSuffix = "";
    // Do not append the location name to the title if we are on a main view
    if (app.value)
      /* && !route.value.meta.mainView */
      titleSuffix = ` - ${app.value.location.businessName}`;
    document.title = newAppTitle + titleSuffix;
  },
  { immediate: true }
);
</script>

<style lang="scss">
@import "./styles/index.scss";

:root {
  --app-width: 500px;
  --app-max-width: 500px;
  --app-height: calc(var(--shell-height, 100vh) - var(--snackbar-height, 0));
  @media all and (display-mode: standalone) {
    --shell-height: 100vh;
  }
}

html {
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  overscroll-behavior-x: contain;
}

#app {
  overflow: hidden;
  background-color: #000000;
  min-height: var(--shell-height);
  max-height: var(--shell-height);
  will-change: height;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

#shell {
  width: min(var(--app-max-width), 100%);
  max-width: var(--app-max-width);
}

#mobile-landscape-message {
  z-index: 9999;
  display: none;
  // pointer: coarse inputs on mobile devices only. See "https://habr.com/en/sandbox/163605/"
  @media screen and (pointer: coarse) and (min-width: 500px) and (max-width: 1000px) and (orientation: landscape) {
    display: block;
  }
}
</style>