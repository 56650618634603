<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-/-Navigation-24px-/-location---tab02" fill="currentColor">
        <path
          d="M8.70935181,24 L3,24 C1.34314575,24 6.46995335e-16,22.6568542 0,21 L0,9.89529119 C-1.00237787e-15,8.9627819 0.433631532,8.08329603 1.1733548,7.51550815 L10.1733548,0.607396579 C11.2507264,-0.219559365 12.7492736,-0.219559365 13.8266452,0.607396579 L22.8266452,7.51550815 C23.5663685,8.08329603 24,8.9627819 24,9.89529119 L24,21 C24,22.6568542 22.6568542,24 21,24 L15.1861455,24 L15.1861455,24 C15.1861455,19.0809326 15.1861455,16.2779134 15.1861455,15.5909424 C15.1861455,14.5604858 14.4000854,12.4086914 12,12.4086914 C9.59991455,12.4086914 8.70935181,14.5604858 8.70935181,15.5909424 C8.70935181,16.2779134 8.70935181,19.0809326 8.70935181,24 Z"
          id="Location-bold"
        ></path>
      </g>
    </g>
  </svg>
</template>
