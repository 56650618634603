<template lang="pug">
div.eat-nav__container.d-flex.flex-column.border-t(v-if="!$route.meta.hideNavbar")
  div.eat-nav.d-flex.bg-white
    div.eat-nav__content.d-flex.flex-grow-1(:class="{'mx-10':  navigationItems.length === 3, 'justify-center': navigationItems.length <= 2, 'justify-space-around': navigationItems.length >= 3}")
      router-link(v-for="item, i in navigationItems" :key="i" custom :to="item.link ? item.link : {}" v-slot="{ href, route, navigate, isActive, isExactActive }")
        button.eat-nav__link(:class="{ 'mx-5':  navigationItems.length === 2 }")
          div.cart-badge.bg-primary.elevation-1(v-if="item.link && item.link.name == 'cart' && isCartFilled" :class="{ active: $route.name === 'order' }")
            EatIcon.cart-badge__icon(icon="$cartBold" :class="{active: isActive }")
            div.cart-badge__items.font-weight-medium {{ cart ? nItems(cart) : 0 }}
          a.d-flex.flex-column.align-center.flex-shrink-1.pt-3.pb-2.px-2.text-black(:href="href")
            EatIcon.link__content.eat-nav__icon(:icon="isActive ? item.activeIcon : item.icon" :class="{ active: isActive }")
            div.link__content.text-caption {{ $t(item.name || '') }}
  AddToHomeIOSPopOver(v-if="$route.meta.showAddToHomePopOver")
</template>

<script setup lang="ts">
import { type PropType, computed } from "vue";
import { nItems, type CartPrefix } from "@/models/Cart";
import { useCart } from "@/store/cart";
import AddToHomeIOSPopOver from "@/components/modals/AddToHomeIOSPopOver.vue";
import type { NavigationItem } from "@/types";

defineProps({
  navigationItems: {
    type: Array as PropType<NavigationItem[]>,
    required: false,
    default: []
  }
});

const { cart } = useCart();

const isCartFilled = computed(() => {
  return cart.value && cart.value.items && cart.value.items.length;
});
</script>

<style lang="scss" scoped>
.eat-nav__container {
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  .eat-nav {
    width: 100%;

    .eat-nav__link {
      position: relative;
      .link__content {
        z-index: 2;
      }
    }
  }
  .eat-nav__icon.active {
    background-color: transparent !important;
    color: var(--clr-primary) !important;
  }

  .cart-badge {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    z-index: 3;
    transition: all 0.1s;
    .cart-badge__icon {
      transition: opacity 0.3s ease;
      display: none;
      opacity: 0;
    }
    &:not(.active) {
      display: grid;
      place-content: center;
    }
    .cart-badge__items {
      padding: 0;
    }
    &.active {
      top: 0;
      left: 50%;
      transform: translate(-50%, -110%);
      min-width: 70px;
      width: max-content;
      height: max-content;
      border-radius: 8px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .cart-badge__icon {
        display: block;
        padding-right: 0.5rem;
        opacity: 1;
        flex-shrink: 0;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
        width: 20px;
        height: 20px;
        background-color: inherit;
        z-index: -1;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
</style>
