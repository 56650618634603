import type { FidelityCardMovement, User } from "@/models";
import type { Address } from "@/models/Address";
import type { Order, Reservation } from "@/models/Order";
import { useDeliveryDistance } from "@/store/delivery/deliveryDistance";
import { get as apiGet, post as apiPost, put as apiPut } from "./API";

const mapUserPlacesToDeliveryAddresses = async (places: Address[]): Promise<Address[]> => {
  const { getDistanceFromLocation } = useDeliveryDistance();
  for (const place of places) {
    if (!place.distanceKm && !(place.distanceKm === 0)) {
      place.distanceKm = await getDistanceFromLocation(place);
    }
  }
  return places;
};

export async function getProfile(): Promise<User> {
  const user = (await apiGet("user", { requiresAuth: true })) as User;
  return user;
}


export async function getFidelityCardMovementsHistory(): Promise<FidelityCardMovement[]> {
  return (await apiGet("fidelity-card-movements", { requiresAuth: true })).movements;
}

export async function getUserPlaces(): Promise<Address[]> {
  const user = (await apiGet("user", { requiresAuth: true })) as User;
  if (user.places) {
    const addresses = await mapUserPlacesToDeliveryAddresses(user.places);
    return addresses;
  }
  return [];
}

export async function getOrderHistory(): Promise<Order[]> {
  return apiGet("orders", { requiresAuth: true }).then((res: Order[]) =>
    res.map(ord => {
      const index = ord.orderItems.findIndex(o => o.isSubtotal);
      if (index > -1) {
        // Il subtotal lo devo visualizzare sempre alla fine (come riepilogo).
        ord.orderItems.push(ord.orderItems.splice(index, 1)[0]);
      }
      return ord;
    })
  );
}

export async function getReservationHistory(): Promise<Reservation[]> {
  return apiGet("reservations", { requiresAuth: true });
}

export async function updateProfile(data: any): Promise<unknown> {
  return apiPut("user", data, { requiresAuth: true });
}

export async function updatePlaces(places: Address[]): Promise<unknown> {
  for(const address of places) address.distanceKm = undefined;
  return apiPut("user/places", { places }, { requiresAuth: true });
}

export async function updatePassword(data: any): Promise<unknown> {
  return apiPost("user/change-password", data, { requiresAuth: true });
}

export async function resetPassword(data: any): Promise<unknown> {
  return apiPost("reset-password", data);
}

export async function requestResetPassword(data: any): Promise<unknown> {
  return apiPost("reset-password/request", data);
}

export async function register(data: any): Promise<{ confirmed: boolean }> {
  return apiPost("users", data);
}

export async function confirmAccount(data: any): Promise<unknown> {
  return apiPost("confirm-account", data);
}

export async function facebook(data: any): Promise<{ username: string; password: string }> {
  return apiPost("users/facebook", data);
}
