<template>
  <svg
    width="26px"
    height="25px"
    viewBox="0 0 26 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-fidelity-credit07"
        transform="translate(1.000000, 2.000000)"
        fill-rule="nonzero"
      >
        <g id="Group-27" transform="translate(-1.000000, -1.870336)">
          <path
            d="M11.8205912,3.66186837 L11.8205912,5.57012525 L5.72477064,5.57012525 C3.68965113,5.57012525 2.02657359,7.16302626 1.91429847,9.17006782 L1.90825688,9.38663901 L1.908,10.5788684 L10.733945,10.5792996 L10.733945,12.9646207 L1.908,12.9638684 L1.90825688,18.9279234 C1.90825688,20.9630429 3.50115789,22.6261205 5.50819945,22.7383956 L5.72477064,22.7444372 L17.6513761,22.7444372 C19.6864957,22.7444372 21.3495732,21.1515362 21.4618483,19.1444946 L21.4678899,18.9279234 L21.4678899,13.9441782 L23.3761468,13.9441782 L23.3761468,18.9279234 C23.3761468,22.0041755 20.9497516,24.5137126 17.9063799,24.6471162 L17.6513761,24.6526941 L5.72477064,24.6526941 C2.64851857,24.6526941 0.138981449,22.2262989 0.00557787337,19.1829271 L0,18.9279234 L0,9.38663901 C0,6.31038693 2.42639517,3.80084982 5.46976692,3.66744624 L5.72477064,3.66186837 L11.8205912,3.66186837 Z"
            id="Combined-Shape"
            fill="#016FD0"
          ></path>
          <path
            d="M20.3508603,0.0595250481 C20.4642958,0.115508792 20.5561125,0.207325542 20.6120962,0.320760996 L21.991011,3.11474743 C22.0747949,3.28451211 22.2367496,3.40217913 22.4240961,3.42940216 L25.5074432,3.87743872 C25.8218123,3.92311924 26.0396275,4.21499719 25.993947,4.52936637 C25.9757568,4.65454982 25.9168068,4.77024568 25.8262232,4.85854287 L23.5950923,7.03335801 C23.4595271,7.16550138 23.3976659,7.35589062 23.4296685,7.54248006 L23.9563671,10.6133683 C24.0100678,10.9264672 23.7997839,11.223817 23.486685,11.2775177 C23.3620074,11.2989016 23.2337575,11.2785888 23.1217901,11.219724 L20.3639606,9.76984726 C20.1963928,9.68175168 19.9962057,9.68175168 19.828638,9.76984726 L17.0708085,11.219724 C16.7896282,11.3675493 16.4418504,11.2594436 16.2940251,10.9782633 C16.2351604,10.8662958 16.2148476,10.7380459 16.2362315,10.6133683 L16.76293,7.54248006 C16.7949326,7.35589062 16.7330714,7.16550138 16.5975062,7.03335801 L14.3663753,4.85854287 C14.1388956,4.63680498 14.134241,4.2726422 14.3559789,4.04516253 C14.4442761,3.95457893 14.5599719,3.89562894 14.6851554,3.87743872 L17.7685025,3.42940216 C17.9558489,3.40217913 18.1178037,3.28451211 18.2015876,3.11474743 L19.5805023,0.320760996 C19.7210925,0.0358941946 20.0659935,-0.0810651233 20.3508603,0.0595250481 Z"
            id="Path"
            fill="#F79E1B"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
