import { getProfile } from "@/api/User";
import type { User } from "@/models";
import { ref } from "vue";
import type { NullRef } from "@/utils";
import { getFidelityCardMovementsHistory } from "@/api/User";
import type { FidelityCardMovement } from "@/models/User";

const user: NullRef<User> = ref();
const fidelityCardMovements: NullRef<FidelityCardMovement[]> = ref();

export function useUser() {
  const refreshUser = async () => {
    user.value = await getProfile();
  };

  const loadFidelityCardMovements = async () => {
    fidelityCardMovements.value = await getFidelityCardMovementsHistory();
  };

  return { user, fidelityCardMovements, refreshUser, loadFidelityCardMovements };
}

