<template lang="pug">
div.eat-selector  
  div.eat-option.cursor-pointer.d-flex(v-for="option, i in options" :key="i" @click="onInput(option)")
    slot(:option="option" :selected="isSelected(option)" :last="i === options.length - 1" :first="i === 0" :index="i")
</template>

<script setup lang="ts">
import { isEqualObject } from "@/utils";
import { computed } from "vue";
import { type PropType } from "vue";

const props = defineProps({
  modelValue: {},
  options: {
    type: Array as PropType<any[]>,
    required: true
  },
  multiSelect: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);

const localValue = computed({
  get: () => props.modelValue,
  set: value => { if(!props.disabled) emit("update:modelValue", value) }
})

const onInput = (option: any) => {
  const value = option.value || option;
  if (!props.multiSelect) {
    localValue.value = localValue.value !== value ? value : undefined;
  } else {
    const multipleSelected = localValue.value as any[];
    const index = multipleSelected.findIndex((selected: any) =>
      isEqualObject(selected, option)
    );
    if (index >= 0) {
      multipleSelected.splice(index, 1);
    } else {
      multipleSelected.push(value);
    }
    localValue.value = multipleSelected;
  }
};

const isSelected = (option: any) => {
  const value = option.value || option;
  if (!props.multiSelect) {
    return isEqualObject(localValue.value, value);
  } else {
    return (localValue.value as string[]).some((selected: any) => isEqualObject(selected, value));
  }
};
</script>

<style lang="scss" scoped>
.eat-option {
  min-height: 64px;
}
</style>
