import type { CalendarDate, TimeSlot } from "@/models/TimeSlot";
import { type Ref, ref } from "vue";
import { useApp } from "../app";
import { getOrderAvailableSlots, getReservationAvailableSlots } from "@/api/TimeSlots";
import { useCart } from "../cart";
import { DateTime } from "luxon";

const availableSlots = ref<TimeSlot[]>([]);
const currentSelectedTimeSlot: Ref<TimeSlot | undefined> = ref();

export function useTimeSlots() {
  const { cart } = useCart();

  const getCartOrderItems = () => {
    return cart.value?.items.map(item => {
      const mandatoryComponents = item.configuration.mandatoryComponents
        ? item.configuration.mandatoryComponents.map(mc => mc.productId)
        : [];
      return {
        productId: item.product.id,
        quantity: item.quantity,
        addedComponents: item.configuration.addedComponents
          .map(ac => ac.productId)
          .concat(mandatoryComponents),
        removedComponents: item.configuration.removedComponents.map(rc => rc.productId)
      };
    });
  };

  const getDateTimeFromCalendarDate = (date: CalendarDate) => {
    return DateTime.fromObject({ day: date.day, month: date.month, year: date.year });
  };

  const isSameTimeSlot = (timeSlotA: TimeSlot, timeSlotB: TimeSlot) => {
    return (
      timeSlotA.time === timeSlotB.time &&
      timeSlotA.isFull === timeSlotB.isFull &&
      timeSlotA.isPast === timeSlotB.isPast
    );
  };

  const isStillAvailable = (slot: TimeSlot, slots: TimeSlot[]) => {
    return !!slots.some(availableSlot => isSameTimeSlot(availableSlot, slot));
  };

  const getOrderSlots = async (calendarDate: CalendarDate) => {
    const { getFromToTimeSlots } = useApp();
    const date = getDateTimeFromCalendarDate(calendarDate);
    const saleType = cart.value?.saletype;
    const orderItems = getCartOrderItems();
    if (saleType && orderItems) {
      const fromToTimeSlots = getFromToTimeSlots(saleType);
      availableSlots.value = await getOrderAvailableSlots(
        { date, saleType, orderItems },
        fromToTimeSlots
      );
      return availableSlots.value;
    } else {
      return [];
    }
  };

  const getReservationSlots = async (calendarDate: CalendarDate) => {
    const { reservationService } = useApp();
    const fromToTimeSlots = !!reservationService.value?.showTimeSlots;
    const date = getDateTimeFromCalendarDate(calendarDate);
    return await getReservationAvailableSlots(date, fromToTimeSlots);
  };

  return {
    availableSlots,
    currentSelectedTimeSlot,
    getOrderSlots,
    getReservationSlots,
    isStillAvailable,
    getDateTimeFromCalendarDate,
    isSameTimeSlot
  };
}
