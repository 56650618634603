import type { MaybeRef, NullRef } from "@/utils";
import { ref } from "vue";

const appTitle: NullRef<string> = ref(null);

export function useAppTitle() {
  return appTitle;
}

export function setAppTitle(newAppTitle: MaybeRef<string | null> = null) {
  newAppTitle = ref(newAppTitle);
  appTitle.value = newAppTitle.value;
}
