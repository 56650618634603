import { type Ref, ref, type Directive } from "vue";

export enum ScrollTriggerRoles {
  TRIGGER = "trigger",
  SCROLLER = "scroller"
}

export interface ScrollTriggerAnimation<K extends string, T extends string> {
  name: K;
  scroller?: Element | null;
  trigger?: Element | null;
  timeline?: GSAPTimeline;
  elements: Record<T, Element | null>;
}

const animations: Ref<ScrollTriggerAnimation<any, any>[]> = ref([]);

export function useAnimations() {
  const getByName = (name: string) => {
    return animations.value.find(animation => animation.name === name) || null;
  };

  return { getByName };
}

export const scrollTriggerDirectiveOptions: Directive = {
  mounted(el, binding) {
    for (const animationName of binding.value.animations) {
      let animationIndex = animations.value.findIndex(
        animation => animation.name === animationName
      );
      if (animationIndex < 0)
        animationIndex = animations.value.push({ name: animationName, elements: {} }) - 1;
      animations.value[animationIndex][binding.value.role as ScrollTriggerRoles] = el;
    }
  },
  unmounted(el, binding) {
    for (const animationName of binding.value.animations) {
      const animationIndex = animations.value.findIndex(
        animation => animation.name === animationName
      );
      animations.value[animationIndex][binding.value.role as ScrollTriggerRoles] = null;
    }
  }
};

export const animatableDirectiveOptions: Directive = {
  mounted(el, binding) {
    for (const animationName of binding.value.animations) {
      let animationIndex = animations.value.findIndex(
        animation => animation.name === animationName
      );
      if (animationIndex < 0)
        animationIndex = animations.value.push({ name: animationName, elements: {} }) - 1;
      animations.value[animationIndex].elements[binding.value.name] = el;
    }
  },
  unmounted(el, binding) {
    for (const animationName of binding.value.animations) {
      const animationIndex = animations.value.findIndex(
        animation => animation.name === animationName
      );
      animations.value[animationIndex].elements[binding.value.name] = null;
    }
  }
};
