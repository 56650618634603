<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-/-Navigation-24px-/-Grey-/-flag-bold---big" fill="currentColor">
        <path
          d="M23.1543442,2.1 L17.7642442,9.87318827 C17.5028306,10.250179 17.5028306,10.749821 17.7642442,11.1268117 L17.7642442,11.1268117 L23.1543442,18.9 L5.54577874,18.9 L5.54504395,22.2774414 L1.53505859,22.2774414 L1.53505859,5 C1.53505859,3.39837423 2.83343282,2.1 4.43505859,2.1 L4.43505859,2.1 L23.1543442,2.1 Z M19.717,3.9 L4.43505859,3.9 C3.82754537,3.9 3.33505859,4.39248678 3.33505859,5 L3.33505859,5 L3.33505859,17.1 L4.8552652,17.1 L19.716,17.1 L16.2850701,12.1525036 C15.6364287,11.2170828 15.5982733,9.99523132 16.170604,9.02611275 L16.170604,9.02611275 L16.2850701,8.84749636 L19.717,3.9 Z"
          id="Flag"
        ></path>
      </g>
    </g>
  </svg>
</template>
