<template>
  <svg
    width="112px"
    height="112px"
    viewBox="0 0 112 112"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="01.-Login" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="1-2-5.-Account-/-Crea-Account" transform="translate(-254.000000, -169.000000)">
        <g id="Rectangle-220-+-Oval-156" transform="translate(20.000000, 169.000000)">
          <g transform="translate(234.000000, 0.000000)">
            <path
              d="M48,72 L48,67.5 L61.5,67.5 L61.5,36 L66,36 L66,72 L48,72 Z"
              id="Rectangle-220"
              fill="currentColor"
              transform="translate(57.000000, 54.000000) rotate(43.000000) translate(-57.000000, -54.000000) "
            ></path>
            <circle
              id="Oval-156"
              stroke="currentColor"
              stroke-width="4"
              cx="56"
              cy="56"
              r="54"
            ></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
