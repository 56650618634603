<template>
  <svg
    width="32px"
    height="26px"
    viewBox="0 0 32 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <path
          d="M22.7644447,0 C27.3433337,0 31.0700003,3.72666656 31.0700003,8.30555556 L31.0700003,17.6944444 C31.0700003,22.2733334 27.3433337,26 22.7644447,26 L13.0577777,26 C10.7177777,26 8.47888885,25.0033346 6.91888879,23.2844457 L1.81999994,17.6799997 C-0.606666648,15.0222218 -0.606666648,11.0066674 1.81999994,8.33444511 L6.91888879,2.72999981 C8.47888885,0.996666405 10.7177777,0 13.0577777,0 L22.7644447,0 Z M22.7644447,2.16666667 L13.0577777,2.16666667 C11.3388887,2.16666667 9.6777772,2.90333315 8.52222163,4.17444425 L3.40888869,9.79333295 C1.74777762,11.6277774 1.74777762,14.386666 3.40888869,16.2211104 L8.50777754,21.8255564 C9.66333311,23.0966675 11.3244453,23.8333333 13.0433343,23.8333333 L22.7644447,23.8333333 C26.1444446,23.8333333 28.9033336,21.0744443 28.9033336,17.6944444 L28.9033336,8.30555556 C28.9033336,4.92555568 26.1444446,2.16666667 22.7644447,2.16666667 Z M14.949662,8.6521658 L17.756,11.464 L20.5541069,8.66662435 C20.9729958,8.24773547 21.6663298,8.24773547 22.0852187,8.66662435 C22.5041075,9.08551323 22.5041075,9.77884715 22.0852187,10.197736 L19.285,12.997 L22.0852187,15.8021658 C22.5041075,16.2210547 22.5041075,16.9143887 22.0852187,17.3332775 C21.868552,17.5499442 21.5941065,17.6510557 21.3196621,17.6510557 C21.0452177,17.6510557 20.7707736,17.5499442 20.5541069,17.3332775 L17.754,14.528 L14.949662,17.3332913 C14.7329953,17.549958 14.4585513,17.6510695 14.1841068,17.6510695 C13.9096624,17.6510695 13.635217,17.549958 13.4185503,17.3332913 C12.9996614,16.9144024 12.9996614,16.2210685 13.4185503,15.8021796 L16.225,12.995 L13.4185503,10.1832775 C12.9996614,9.7643886 12.9996614,9.07105468 13.4185503,8.6521658 C13.8374392,8.23327692 14.5307731,8.23327692 14.949662,8.6521658 Z"
          id="Combined-Shape"
          fill="currentColor"
        ></path>
      </g>
    </g>
  </svg>
</template>
