import { ref } from "vue";
import type { RouteLocation, RouteLocationNormalized, RouteLocationRaw, RouteRecordNormalized, Router } from "vue-router";

const history = ref<RouteLocationNormalized[]>([]);

const isToBeSaved = (from: RouteLocationNormalized, to: RouteLocationNormalized) => {
  const isAlreadyInHistory = history.value.some(route => route.name === to.name);
  const isNotToBeSaved = to.meta?.doNotSaveToHistory;
  return !isAlreadyInHistory && !isNotToBeSaved;
};

export function setup(router: Router) {
  router.beforeEach((to, from, next) => {
    let redirectRoute: RouteLocationRaw|undefined;
    if (to.meta.mainView) history.value = [];

    if (to.name === "auth") redirectRoute = { name: "auth.login" };
    //Evita che venga visualizzata la view Auth senza le tab Register e Login
    else if (isToBeSaved(from, to)) history.value.push(to);
    redirectRoute ? next(redirectRoute) : next();
  });
}

export function useRouterHistory() {
  return history;
}

export function goBack(router: Router, route: RouteLocation) {
  history.value.pop(); // rimuovo la current route dalla history
  const destinationRoute = history.value.pop() || null;

  if (destinationRoute?.name !== route.name) {
    router.push({ name: destinationRoute?.name || "order" });
  } else {
    const mainRoute = (route.name as string)?.split(".")[0];
    router.push({ name: mainRoute !== "auth" ? mainRoute : "order" });
  }
}
