<template>
  <svg
    width="19px"
    height="20px"
    viewBox="0 0 19 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-calendar"
        transform="translate(-3.000000, -2.000000)"
        fill="currentColor"
      >
        <path
          d="M15.2222222,2 L17.2592593,2 L17.2592593,4 L19.2962963,4 C20.4213208,4 21.3333333,4.8954305 21.3333333,6 L21.3333333,20 C21.3333333,21.1045695 20.4213208,22 19.2962963,22 L5.03703704,22 C3.91201255,22 3,21.1045695 3,20 L3,6 C3,4.8954305 3.91201255,4 5.03703704,4 L7.07407407,4 L7.07407407,2 L9.11111111,2 L9.11111111,4 L15.2222222,4 L15.2222222,2 Z M19.2962963,5.6 L5.03703704,5.6 C4.84015775,5.6 4.67589592,5.7371128 4.6379067,5.91938605 L4.62962963,6 L4.62962963,20 C4.62962963,20.1932997 4.76928155,20.3545749 4.95493023,20.3918734 L5.03703704,20.4 L19.2962963,20.4 C19.4931756,20.4 19.6574374,20.2628872 19.6954266,20.080614 L19.7037037,20 L19.7037037,6 C19.7037037,5.80670034 19.5640518,5.64542508 19.3784031,5.60812658 L19.2962963,5.6 Z M17.2592593,16 L17.2592593,18 L15.2222222,18 L15.2222222,16 L17.2592593,16 Z M13.1851852,16 L13.1851852,18 L11.1481481,18 L11.1481481,16 L13.1851852,16 Z M9.11111111,16 L9.11111111,18 L7.07407407,18 L7.07407407,16 L9.11111111,16 Z M9.11111111,12 L9.11111111,14 L7.07407407,14 L7.07407407,12 L9.11111111,12 Z M13.1851852,12 L13.1851852,14 L11.1481481,14 L11.1481481,12 L13.1851852,12 Z M17.2592593,12 L17.2592593,14 L15.2222222,14 L15.2222222,12 L17.2592593,12 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
</template>
