<template>
  <svg
    width="48px"
    height="19px"
    viewBox="0 0 48 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-googlePay02"
        transform="translate(12.000000, -3.000000)"
      >
        <g id="Google_Pay_(GPay)_Logo-Copy" transform="translate(-11.999729, 3.079622)">
          <path
            d="M22.700727,9.02243845 L22.700727,14.4358125 L20.9282366,14.4358125 L20.9282366,1.06717858 L25.6291894,1.06717858 C26.7625632,1.04424026 27.8584401,1.46088927 28.673249,2.22452062 C29.4976481,2.94298822 29.9612635,3.97165682 29.9448182,5.04587519 C29.9684304,6.12609348 29.5039889,7.16243898 28.673249,7.88322988 C27.8512245,8.64270226 26.836538,9.02208289 25.6291894,9.02137178 L22.700727,9.02137178 L22.700727,9.02243845 Z M22.700727,2.71305763 L22.700727,7.37975942 L25.6732265,7.37975942 C26.3321514,7.39868333 26.9688144,7.147759 27.4270011,6.68855422 C27.8824079,6.25957161 28.1393434,5.67034224 28.1393434,5.05494192 C28.1393434,4.43954161 27.8824079,3.85031223 27.4270011,3.42132963 C26.9743714,2.95225577 26.3357326,2.6939494 25.6732265,2.71199096 L22.700727,2.71199096 L22.700727,2.71305763 Z"
            id="Shape"
            fill="#5F6368"
            fill-rule="nonzero"
          ></path>
          <path
            d="M34.0292527,4.99040811 C35.3393543,4.99040811 36.3734905,5.32961066 37.1316613,6.00801576 C37.8898322,6.68642087 38.2685506,7.6165612 38.2678177,8.79843677 L38.2678177,14.4358125 L36.5723911,14.4358125 L36.5723911,13.1664696 L36.4953263,13.1664696 C35.7613758,14.2118108 34.7852216,14.7344814 33.5668639,14.7344814 C32.526856,14.7344814 31.6567577,14.4358125 30.956569,13.8384747 C30.2760491,13.2832399 29.8901978,12.4603063 29.9062858,11.5984578 C29.9062858,10.6519618 30.2754629,9.89924505 31.0138171,9.34030751 C31.7521713,8.78136997 32.7378668,8.50119008 33.9709036,8.49976785 C35.0233886,8.49976785 35.8901841,8.68643592 36.5712901,9.05977207 L36.5712901,8.66723578 C36.5753545,8.07972458 36.3065261,7.52158566 35.8391745,7.14722434 C35.3704513,6.73749117 34.7595907,6.5139265 34.128336,6.52108629 C33.1382368,6.52108629 32.3547446,6.92571156 31.7778596,7.73496209 L30.2167469,6.78242159 C31.075469,5.58774593 32.3463042,4.99040811 34.0292527,4.99040811 Z M31.7360047,11.6357915 C31.7336131,12.0792522 31.9526192,12.4964848 32.3239187,12.7557999 C32.7159341,13.0545701 33.2025013,13.2128438 33.7011768,13.2038033 C34.4491405,13.2025922 35.166105,12.9141419 35.6949533,12.4016639 C36.2821136,11.8661932 36.5756938,11.2379218 36.5756938,10.5168497 C36.0230291,10.0901798 35.2523811,9.87684488 34.2637498,9.87684488 C33.5437444,9.87684488 32.9433729,10.0450239 32.4626354,10.381382 C31.9771271,10.7227179 31.7360047,11.1376544 31.7360047,11.6357915 L31.7360047,11.6357915 Z"
            id="Shape"
            fill="#5F6368"
            fill-rule="nonzero"
          ></path>
          <polygon
            id="Path"
            fill="#5F6368"
            points="48 5.28907702 42.0814233 18.4678429 40.2516848 18.4678429 42.4480316 13.8566082 38.5562592 5.28907702 40.4828792 5.28907702 43.2957444 11.8597931 43.3342768 11.8597931 46.0700772 5.28907702"
          ></polygon>
          <path
            d="M15.5381115,7.85549634 C15.5387727,7.3321345 15.4931003,6.80968179 15.4015895,6.29388458 L7.9263039,6.29388458 L7.9263039,9.25177351 L12.207804,9.25177351 C12.0307653,10.2063963 11.4588697,11.0494965 10.6246728,11.5856577 L10.6246728,13.5056722 L13.1799214,13.5056722 C14.6760795,12.1691288 15.5381115,10.1925806 15.5381115,7.85549634 Z"
            id="Path"
            fill="#4285F4"
          ></path>
          <path
            d="M7.9263039,15.3606195 C10.0654026,15.3606195 11.866517,14.680081 13.1799214,13.5067389 L10.6246728,11.5867244 C9.91347482,12.0539279 8.99750462,12.3205966 7.9263039,12.3205966 C5.8587654,12.3205966 4.10388981,10.9701864 3.47636215,9.15043941 L0.844048763,9.15043941 L0.844048763,11.129121 C2.18954941,13.723228 4.92982859,15.3604872 7.9263039,15.3606195 Z"
            id="Path"
            fill="#34A853"
          ></path>
          <path
            d="M3.47636215,9.15043941 C3.14461079,8.1968796 3.14461079,7.16424378 3.47636215,6.21068396 L3.47636215,4.2320024 L0.844048763,4.2320024 C-0.281349588,6.40175516 -0.281349588,8.95936821 0.844048763,11.129121 L3.47636215,9.15043941 Z"
            id="Path"
            fill="#FBBC04"
          ></path>
          <path
            d="M7.9263039,3.04052676 C9.05672068,3.0226333 10.1490034,3.43645105 10.9670607,4.19253543 L10.9670607,4.19253543 L13.2294631,2.00051894 C11.7948644,0.694989978 9.89433982,-0.0217672818 7.9263039,0.000503883686 C4.92982859,0.000636202245 2.18954941,1.63789539 0.844048763,4.2320024 L3.47636215,6.21068396 C4.10388981,4.39093693 5.8587654,3.04052676 7.9263039,3.04052676 Z"
            id="Path"
            fill="#EA4335"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
