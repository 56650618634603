<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-/-Navigation-24px-/-Grey-/-facebook" fill="currentColor">
        <path
          d="M12,0 C18.6274173,0 24,5.37258267 24,12 C24,18.6274173 18.6274173,24 12,24 C5.37258267,24 0,18.6274173 0,12 C0,5.37258267 5.37258267,0 12,0 Z M14.8790369,5.6 L13.0765933,5.6 C10.9115687,5.6 10.1495362,6.69139664 10.1495362,8.52678259 L10.1495362,9.87755632 L8.8,9.87755632 L8.8,12.1273596 L10.1495362,12.1273596 L10.1495362,18.6557384 L12.8507775,18.6557384 L12.8507775,12.1271048 L14.6529659,12.1271048 L14.8917948,9.87730153 L12.8507775,9.87730153 L12.8538395,8.75125333 C12.8538395,8.16446999 12.9095917,7.85005807 13.7523821,7.85005807 L14.8790369,7.85005807 L14.8790369,5.6 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
</template>
