<template lang="pug">
button.d-flex.align-center(type="button" @click="localValue = !localValue")
  EatIcon.flex-grow-0.flex-shrink-0(v-if="icon" :icon="icon" :class="localValue ? 'text-primary' : 'text-muted'")
  slot(:on="localValue")
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    required: false
  },
  border: {
    type: Boolean,
    default: false
  },
  background: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const localValue = computed({
  get: () => props.modelValue,
  set: value => { if(!props.disabled) emit("update:modelValue", value) }
});
</script>

<style lang="scss">
.toggled-icon {
  width: 24px; 
  height: 24px;
}
</style>
