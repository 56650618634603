<template>
  <svg
    width="20px"
    height="24px"
    viewBox="0 0 20 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-address---big"
        transform="translate(-2.000000, 0.000000)"
        fill="currentColor"
      >
        <path
          d="M12,0 C17.5228475,0 22,4.4771525 22,10 C22,15.5228475 16.5,24 12,24 C7.5,24 2,15.5228475 2,10 C2,4.4771525 6.4771525,0 12,0 Z M12,1.8 C7.47126505,1.8 3.8,5.47126505 3.8,10 C3.8,14.8912195 8.84005556,22.2 12,22.2 C15.1599444,22.2 20.2,14.8912195 20.2,10 C20.2,5.47126505 16.5287349,1.8 12,1.8 Z M12,5.5 C14.4852814,5.5 16.5,7.51471863 16.5,10 C16.5,12.4852814 14.4852814,14.5 12,14.5 C9.51471863,14.5 7.5,12.4852814 7.5,10 C7.5,7.51471863 9.51471863,5.5 12,5.5 Z M12,7.3 C10.5088312,7.3 9.3,8.50883118 9.3,10 C9.3,11.4911688 10.5088312,12.7 12,12.7 C13.4911688,12.7 14.7,11.4911688 14.7,10 C14.7,8.50883118 13.4911688,7.3 12,7.3 Z"
          id="point"
        ></path>
      </g>
    </g>
  </svg>
</template>
