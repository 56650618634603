<template lang="pug">
div.d-flex.align-start.cursor-pointer.text-left(@click="localValue = !localValue")
  div.px-4
    button(type="button")
      EatToggleIcon(:on="localValue" :size="16" :class="localValue ? 'text-primary' : 'text-muted'" onIcon="$checkboxOn" offIcon="$checkboxOff")
  div.pt-1.text-caption.text-wrap.text-break
    slot
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});


const emit = defineEmits(["update:modelValue"]);

const localValue = computed({
  get: () => props.modelValue,
  set: value => { if(!props.disabled) emit("update:modelValue", value) }
});
</script>