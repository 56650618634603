<template lang="pug">
div.position-relative.overflow-hidden.rounded-xl.bg-white.border.d-flex.align-center.px-4.gap-4(:class="{ 'text-field__error': errored }")
  EatIcon.text-muted(:icon="icon")
  input.text-field__input.py-4.flex-grow-1(:type="type" v-model="localValue" :readonly="readonly" :placeholder="placeholder ? $t(placeholder) : undefined" autocomplete="off" @blur="onBlur")
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String
  },
  type: {
    type: String as PropType<'text'|'tel'|'email'|'password'>,
    default: 'text'
  },
  placeholder: {
    type: String,
  },
  icon: {
    type: String,
    default: null
  },
  errored: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue", "blur"]);

const localValue = computed({
  get: () => props.modelValue,
  set: value => { if(!props.disabled) emit("update:modelValue", value) }
});

const onBlur = (e: FocusEvent) => emit('blur', e);
</script>

<style lang="scss" scoped>
.text-field__error::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--clr-error);
}

.text-field__input {
  &:focus {
    outline: none;
  }
  height: 48px;
  width: 0;
  flex-basis: 0;
}
</style>
