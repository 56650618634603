<template lang="pug">
div
  div {{ formatAddressAndStreetNumber(address) }}
  div {{ formatZipCityAndState(address) }}
</template>

<script setup lang="ts">
import type { Address } from "@/models/Address";
import type { PropType } from "vue";
import countriesList from "@/assets/countries.json";

defineProps({
  address: {
    type: Object as PropType<Address>,
    required: true
  }
});

const formatAddressAndStreetNumber = (address: Address) => {
  return `${address.address || ""}, ${address.streetNumber || ""}`;
};

const formatZipCityAndState = (address: Address) => {
  const countryName =
    address.nation === "IT"
      ? "Italia"
      : countriesList.find(country => country.code === address.nation)?.name;
  return `${address.zipCode || ""} ${address.city || ""}, ${countryName || ""}`;
};
</script>
