let currencyCode: undefined | string = undefined;

export function setCurrencyCode(code: string) {
  currencyCode = code;
}

interface FormatPriceOptions {
  sign: "exceptZero" | "always" | "never" | "never" | undefined;
}

export function formatPrice(price: number, options?: FormatPriceOptions): string {
  const total = price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: currencyCode,
    signDisplay: options?.sign
  });
  return total;
}
