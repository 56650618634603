import type { Calendar, CalendarDate } from "@/models/TimeSlot";
import CalendarGenerator from "@/utils/calendar";
import { ref } from "vue";
import { useApp } from "../app";
import { Saletype } from "@/models/App";
import { computed } from "vue";

const reserveCalendar = ref<Calendar>([]);
const reserveTodaysCalendarDate = ref<CalendarDate>();
const weekDaysLabels = ref(CalendarGenerator.getWeekDaysLabels());

export function useCalendar() {
	const { saletypeOpeningHours, reservationService, activeSaletypes } = useApp();

	weekDaysLabels.value = CalendarGenerator.getWeekDaysLabels();
	if(!reserveCalendar.value.length && activeSaletypes.value.includes(Saletype.RESERVATION)) {
			const reserveCalendarGenerator = new CalendarGenerator(reservationService.value?.slots);
			reserveCalendar.value = reserveCalendarGenerator.getCalendar();
			reserveTodaysCalendarDate.value = reserveCalendarGenerator.generateTodaysDate();
	}
	
	const calendarGenerator = computed(() => new CalendarGenerator(saletypeOpeningHours.value));
	const calendar = computed(() => calendarGenerator.value.getCalendar());
	const todaysCalendarDate = computed(() => calendarGenerator.value.generateTodaysDate());

	const isSameDate = (dateA: CalendarDate, dateB: CalendarDate) => CalendarGenerator.isSameDate(dateA, dateB);

  return {
    calendar,
    todaysCalendarDate,
    reserveCalendar,
    reserveTodaysCalendarDate,
    weekDaysLabels,
    isSameDate
  };
}