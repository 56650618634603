<template lang="pug">
div.input-stepper__container.d-flex.align-center.rounded
  EatIcon.text-muted.mr-3.flex-shrink-0(v-if="icon" :icon="icon")
  slot
  div.d-flex.align-center.ms-auto
    div.number.text-primary.text-h6.mr-3 {{ localValue }}
    div.stepper.d-flex.bg-controls.rounded.border
      div.stepper_btn-container
        EatIconBtn.stepper__btn(type="button" icon="$minus" :size="16" @click="onDecrease(step)")
      div.stepper_btn-container.border-l
        EatIconBtn.stepper__btn(type="button" icon="$plus" :size="16" @click="onIncrease(step)")
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: Number.MAX_SAFE_INTEGER
  },
  min: {
    type: Number,
    default: 0
  },
  step: {
    type: Number,
    default: 1
  },
  icon: {
    type: String,
    required: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const localValue = computed({
  get: () => props.modelValue,
  set: value => { if(!props.disabled) emit("update:modelValue", value) }
});

onMounted(() => {
  if(!localValue.value || 
    localValue.value < props.min || 
    localValue.value > props.max
  ) {
    localValue.value = props.min;
  }
})

const onIncrease = (amount: number) => {
  const newValue = localValue.value + amount;
  if (newValue <= props.max) localValue.value = newValue;
};

const onDecrease = (amount: number) => {
  const newValue = localValue.value - amount;
  if (newValue >= props.min) localValue.value = newValue;
};
</script>

<style lang="scss" scoped>
.input-stepper__container {
  min-height: 64px;
}

.stepper__btn {
  &:focus-visible {
    outline: 1px solid blue;
  }
}
</style>
