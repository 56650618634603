<template>
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Direction" transform="translate(-1.000000, -1.000000)">
        <g transform="translate(1.514719, 1.514719)">
          <path
            d="M11.8994949,0.585786438 L20.3847763,9.07106781 C21.1658249,9.8521164 21.1658249,11.1184464 20.3847763,11.8994949 L11.8994949,20.3847763 C11.1184464,21.1658249 9.8521164,21.1658249 9.07106781,20.3847763 L0.585786438,11.8994949 C-0.195262146,11.1184464 -0.195262146,9.8521164 0.585786438,9.07106781 L9.07106781,0.585786438 C9.8521164,-0.195262146 11.1184464,-0.195262146 11.8994949,0.585786438 Z M12.4852814,4.48528137 L12.4845157,7.51428137 L7.35051575,7.51506653 L7.2012534,7.52055227 C6.16639354,7.59690141 5.35051575,8.46070474 5.35051575,9.51506653 L5.35051575,9.51506653 L5.35051575,13.5150665 L7.35051575,13.5150665 L7.35051575,9.51506653 L12.4845157,9.51428137 L12.4852814,12.4852814 L16.4852814,8.48528137 L12.4852814,4.48528137 Z"
            id="Combined-Shape"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
