import axios, { type AxiosRequestConfig } from "axios";

const cache: Map<string, { expirationDate: Date; response: unknown }> = new Map();
const apiAxios = axios.create();

export type APIError = APIErrorInterface | null | undefined;
export interface APIErrorInterface {
  response?: {
    status?: number;
    data?: any;
  };
}

export interface APIRequestConfig extends AxiosRequestConfig {
  cache?: boolean;
  requiresAuth?: boolean;
}

export function setup(baseURL: string) {
  apiAxios.defaults.baseURL = baseURL;
  return apiAxios;
}

function cleanEndpoint(endpoint: string) {
  if (endpoint.startsWith("/")) {
    endpoint = endpoint.substring(1);
  }
  return endpoint;
}

export async function get(endpoint: string, options: APIRequestConfig = {}) {
  endpoint = cleanEndpoint(endpoint);

  if (options.cache) {
    const cacheResult = cache.get(endpoint);
    if (cacheResult) {
      if (Date.now() < cacheResult.expirationDate.getMilliseconds()) {
        return Promise.resolve(cacheResult.response);
      }
    }
  }
  return apiAxios
    .get(`${endpoint}`, options)
    .then(response => {
      if (response.status === 200) {
        const expirationDate = new Date();
        expirationDate.setMinutes(expirationDate.getMinutes() + 10);
        cache.set(endpoint, {
          expirationDate,
          response: response.data
        });
      }
      return Promise.resolve(response.data);
    })
    .catch(error => {
      throw error;
    });
}

export async function post(endpoint: string, data: unknown = {}, options: APIRequestConfig = {}) {
  endpoint = cleanEndpoint(endpoint);

  return apiAxios
    .post(`${endpoint}`, data, options)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      throw error;
    });
}

export async function patch(endpoint: string, data: unknown = {}, options: APIRequestConfig = {}) {
  endpoint = cleanEndpoint(endpoint);

  return apiAxios
    .patch(`${endpoint}`, data, options)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      throw error;
    });
}

export async function put(endpoint: string, data: unknown = {}, options: APIRequestConfig = {}) {
  endpoint = cleanEndpoint(endpoint);

  return apiAxios
    .put(`${endpoint}`, data, options)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      throw error;
    });
}

export async function delete_(endpoint: string, options: APIRequestConfig = {}) {
  endpoint = cleanEndpoint(endpoint);

  return apiAxios
    .delete(`${endpoint}`, options)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      throw error;
    });
}
