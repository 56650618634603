import { type OpeningHours } from "@/models/AppLocation";
import { DateTime, Info } from "luxon";
import type { Calendar, CalendarDate, CalendarMonth } from "@/models/TimeSlot";


export default class CalendarGenerator {
  private now: DateTime;
  private static locale = "en";
  private openingDays: OpeningHours[] = [];
  private calendar: Calendar;

  constructor(openingHours?: OpeningHours[]) {
    this.now = DateTime.now();
    if (openingHours) this.openingDays.push(...openingHours);
    this.calendar = this.generateYear();
  }

  public getCalendar() {
    return this.calendar;
  }

  public static setLocale(locale: string) {
    CalendarGenerator.locale = locale;
  }

  public static getWeekDaysLabels() {
    return Info.weekdays("short", { locale: CalendarGenerator.locale }).map(name => name.toUpperCase())
  }

  private isClosed(weekday: number): boolean {
    return !this.openingDays?.some(day => day.weekday === weekday && day.slots?.length);
  }

  private isPast(day: number, month: number, year: number): boolean {
    if ((this.now.year === year && this.now.month < month) || this.now.year < year) {
      return false;
    } else {
      const date = DateTime.fromObject({ day, month, year });
      return date.startOf("day") < this.now.startOf("day");
    }
  }

  private generateId(day: number, weekday: number, month: number, year: number) {
    return `${day}${weekday}${month}${year}`;
  }

  public generateDate(day: number, weekday: number, month: number, year: number) {
    return {
      id: this.generateId(day, weekday, month, year),
      day,
      weekday,
      month,
      year,
      isClosed: this.isClosed(weekday),
      isPast: this.isPast(day, month, year)
    };
  }

  private generatePaddingDates(weekday: number): CalendarDate[] {
    const padding = [];
    for (let i = 0; i < weekday - 1; i++) {
      padding.push({ isPadding: true });
    }
    return padding;
  }

  private generateMonth(month: number, year: number): CalendarMonth {
    const dates: CalendarDate[] = [];

    const firstDay = DateTime.fromObject({ day: 1, month: month, year: year }).setLocale(
      CalendarGenerator.locale
    );
    const monthName = firstDay.setLocale(CalendarGenerator.locale).toFormat("MMMM");

    const padding = this.generatePaddingDates(firstDay.weekday);
    if (padding.length) {
      dates.push(...padding);
    }

    for (let i = 0; i < (firstDay.daysInMonth || 0); i++) {
      const weekday = (i + firstDay.weekday) % 7;
      const day = i + firstDay.day;

      const calendarDate: CalendarDate = this.generateDate(day, weekday, month, year);
      dates.push(calendarDate);
    }

    return { name: `${monthName} ${year}`, number: month, year, dates };
  }

  public generateYear(firstMonth?: number, year?: number): Calendar {
    this.now = DateTime.now();
    const startMonth = firstMonth || this.now.month;
    const startYear = year || this.now.year;
    const months: CalendarMonth[] = [];
    for (let i = 0; i < 12; i++) {
      const month = ((i + startMonth - 1) % 12) + 1;
      const maybeNextYear = startYear + Math.floor((i + startMonth - 1) / 12);
      months.push(this.generateMonth(month, maybeNextYear));
    }
    return months;
  }

  public generateTodaysDate() {
    this.now = DateTime.now();
    const { day, weekday, month, year } = this.now;
    return this.generateDate(day, weekday, month, year);
  }

  public static isSameDate(dateA: CalendarDate, dateB: CalendarDate): boolean {
    return dateA.id === dateB.id;
  }
}
