<template>
  <svg
    width="12px"
    height="20px"
    viewBox="0 0 12 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-back"
        transform="translate(-6.000000, -2.000000) rotate(180 12 12)"
        fill="currentColor"
      >
        <path
          d="M6.90380578,11.3852814 L15.3890872,2.9 C15.7796114,2.50947571 16.4127764,2.50947571 16.8033007,2.9 C17.193825,3.29052429 17.193825,3.92368927 16.8033007,4.31421356 L9.01564566,12.1011068 L16.8033009,19.8890873 C17.1938252,20.2796116 17.1938252,20.9127766 16.8033009,21.3033009 C16.4127766,21.6938252 15.7796116,21.6938252 15.3890873,21.3033009 L6.90380592,12.8180195 C6.72356394,12.6377775 6.62651057,12.4058487 6.6126458,12.1699441 L6.61264566,12.0333568 C6.62651043,11.7974521 6.7235638,11.5655234 6.90380578,11.3852814 Z"
          id="back"
        ></path>
      </g>
    </g>
  </svg>
</template>
