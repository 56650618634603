import { type CalendarDate, type TimeSlot } from "@/models/TimeSlot";
import { useCalendar } from "@/store/timeSlots/calendar";
import { ref } from "vue";
import { DateTime } from "luxon";
import { useTimeSlots } from "@/store/timeSlots/timeSlots";

export interface DateTimeSlot {
  timeSlot: TimeSlot | null;
  date: CalendarDate;
}

const currentSelectedDate = ref<CalendarDate>();

export function useDateTimeSlots() {
  const { todaysCalendarDate, reserveTodaysCalendarDate } = useCalendar();

  const getDateTimeFromCalendarDate = (date: CalendarDate) => {
    return DateTime.fromObject({ day: date.day, month: date.month, year: date.year });
  };

  const isDateTimeStillValid = async (dateTime?: DateTimeSlot) => {
    if (!dateTime) return;
    const { isStillAvailable, getOrderSlots } = useTimeSlots();
    const { date, timeSlot } = dateTime;
    if (date && timeSlot) {
      const isDatePast =
        DateTime.now().toFormat("yyyy-MM-dd") >
        getDateTimeFromCalendarDate(date).toFormat("yyyy-MM-dd");
      const slots = await getOrderSlots(date);
      return !isDatePast && !timeSlot.isPast && isStillAvailable(timeSlot, slots);
    } else {
      return false;
    }
  };

  const getFirstAvailableSlot = (slots: TimeSlot[]) => {
    return slots.find(slot => !slot.isFull && DateTime.fromISO(slot.from) > DateTime.now())
  };

  const getInitialOrderDateTimeSlots = async (date: CalendarDate): Promise<DateTimeSlot | null> => {
    
    const { getOrderSlots } = useTimeSlots();
    const slots = await getOrderSlots(date);
    const timeSlot = todaysCalendarDate.value ? getFirstAvailableSlot(slots) || null : null;
    return { date, timeSlot };
  };

  const getInitialReserveDateTimeSlots = async () => {
    const { getReservationSlots } = useTimeSlots();
    let date: CalendarDate | null = null;
    let timeSlot: TimeSlot | null = null;
    if (reserveTodaysCalendarDate.value) {
      date = { ...reserveTodaysCalendarDate.value };
      const slots = await getReservationSlots(reserveTodaysCalendarDate.value);
      timeSlot = getFirstAvailableSlot(slots) || null;
    }
    return { date, timeSlot };
  };

  const getTodaysFirstAvailableOrderDateTimeSlot = async () => {
    return todaysCalendarDate.value
      ? await getInitialOrderDateTimeSlots(todaysCalendarDate.value)
      : null;
  };

  return {
    currentSelectedDate,
    getInitialOrderDateTimeSlots,
    getFirstAvailableSlot,
    isDateTimeStillValid,
    getDateTimeFromCalendarDate,
    getTodaysFirstAvailableOrderDateTimeSlot,
    getInitialReserveDateTimeSlots
  };
}
