import config from "@/config";
import { Loader } from "@googlemaps/js-api-loader";
import type { App } from "@/models";
import type { Address } from "@/models/Address";
import { setOptions, bootstrap } from "vue-gtag";
import type { NullRef } from ".";
import type { Autocomplete } from "@/store/delivery/autocompletes";
import { ref } from "vue";

export async function loadGoogleMaps() {
  const loader = new Loader({ apiKey: config.mapsAPIKey, libraries: ["places"] });
  await loader.load();
}

export async function getDeliveryDistanceMatrix(restaurantAddress: any, address: Address) {
  const service = new window.google.maps.DistanceMatrixService();

  const distanceMatrixOptions = {
    origins: [restaurantAddress],
    destinations: [
      `${address.address} ${address.streetNumber || 1}, ${address.city} ${address.nation}`
    ],
    travelMode: "DRIVING",
    unitSystem: window.google.maps.UnitSystem.METRIC
  };

  return new Promise<number>((resolve, reject) => {
    service.getDistanceMatrix(distanceMatrixOptions, (result: any, resultString: string) => {
      if (result && result.rows[0]?.elements[0]?.distance) {
        resolve(result.rows[0].elements[0].distance.value / 1000.0);
      } else {
        reject(new Error("Non è stato possibile trovare l'indirizzo richiesto."));
      }
    });
  });
}

interface AddressComponents {
  [streetNumber: string]: string;
  address: string;
  city: string;
  nation: string;
  zipCode: string;
}

const addressComponentTypes: AddressComponents = {
  streetNumber: "street_number",
  address: "route",
  city: "administrative_area_level_3",
  nation: "country",
  zipCode: "postal_code"
};

const addressComponentsToAddress = (googleAddressComponents: any): Address => {
  const address: Address = googleAddressComponents.reduce(
    (componentsObj: AddressComponents, component: any) => {
      for (const type in addressComponentTypes) {
        if (!componentsObj[type]) {
          componentsObj[type] = component.types.some(
            (compType: string) => compType === addressComponentTypes[type]
          )
            ? component["short_name"]
            : "";
        }
      }
      return componentsObj;
    },
    {}
  );
  return address;
};

const sessionToken = ref();

export async function getPlacesAutocompletes(
  address: string,
  locale: string
): Promise<Autocomplete[]> {
  if (!sessionToken.value) {
    sessionToken.value = new window.google.maps.places.AutocompleteSessionToken();
  }

  const europeanCountries = [
    "it",
    "fr",
    "de",
    "bg",
    "at",
    "be",
    "es",
    "hu" /* "hr", "el", "lt", "pt", "lu", "ro", "cz", "si", "dk", "mt", "sk", "nl", "fi", "ee", "cy", "se", "ie", "lv", "pl" */
  ];
  const options = {
    input: address,
    language: locale,
    componentRestrictions: { country: europeanCountries },
    sessionToken: sessionToken.value
  };

  const autocomplete = new window.google.maps.places.AutocompleteService();
  return new Promise<any[]>((resolve, reject) => {
    autocomplete.getPlacePredictions(options, (predictions: any[], status: string) => {
      if (status === "OK") {
        const autocompletes = predictions.map((prediction: any) => {
          return {
            placeId: prediction["place_id"],
            address:
              prediction["structured_formatting"]["main_text"] +
              " " +
              prediction["structured_formatting"]["secondary_text"]
          };
        });
        resolve(autocompletes);
      }
      reject(new Error("Autocomplete service error"));
    });
  });
}

const geocoderResultsToFirstAddress = (predictions: any): Address => {
  const prediction = predictions[0];
  return addressComponentsToAddress(prediction["address_components"]);
};

export async function getFullAddress(placeId: string) {
  sessionToken.value = null;
  const geocoder = new window.google.maps.Geocoder();

  return new Promise<Address>((resolve, reject) => {
    geocoder.geocode({ placeId }, function(results: any, status: string) {
      if (status == "OK") {
        resolve(geocoderResultsToFirstAddress(results));
      } else {
        reject(new Error("Geocoder error"));
      }
    });
  });
}

export async function getGeocodingFromGeolocation(location: {
  lat: number;
  lng: number;
}): Promise<Address> {
  const geocoder = new window.google.maps.Geocoder();
  const bounds = new window.google.maps.LatLngBounds(
    { lat: -10.770507, lng: 34.899943 },
    { lat: 20.958009, lng: 55.288844 }
  );

  return new Promise<Address>((resolve, reject) =>
    geocoder.geocode({ location, bounds }, function(results: any, status: string) {
      if (status == "OK") {
        resolve(geocoderResultsToFirstAddress(results));
      } else {
        reject(new Error("Geocoder error"));
      }
    })
  );
}

export async function setGTag(app: NullRef<App>) {
  if (app.value?.GTMContainerId) {
    setOptions({
      config: { id: app.value.GTMContainerId }
    });
    await bootstrap();
  }
}
