<template>
  <svg
    width="24px"
    height="18px"
    viewBox="0 0 24 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g>
        <path
          d="M14.5108084,13.9819063 L21.560352,8.07323949 C21.8386014,7.8458153 22,7.50544813 22,7.14608082 C22,6.78671399 21.8386014,6.44634682 21.560352,6.21892295 L14.5108084,0.310255301 C14.1348064,-0.0111643859 13.6084043,-0.0899603377 13.1547671,0.107273033 C12.7011302,0.304506372 12.3997302,0.743216961 12.3783451,1.23741327 L12.3783451,3.56787982 C2.4813261,1.85342324 0,10.7101395 0,15.7105061 C2.29589457,11.9013007 8.24542026,4.99633167 12.3783451,10.7101395 L12.3783451,13.046891 C12.3968739,13.542503 12.6971922,13.9838681 13.1514051,14.1830255 C13.6056191,14.3821835 14.1337196,14.3040523 14.5108084,13.9819063 Z"
          id="Polygon_16"
          stroke="currentColor"
          stroke-width="1.8"
        ></path>
      </g>
    </g>
  </svg>
</template>
