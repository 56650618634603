import "core-js";
import config from "./config";
import { setup as apiSetup } from "./api/API";
import { setErrorSnackbar } from "./store/layout/snackbar";
import { setup as setupRouterHistory } from "./store/routerHistory";
import { EAT_UP_PAGES, type EatUpPage } from './types';

// Components
import icons from "@/assets/icons";
import EatIcon from "@/components/utils/EatIcon.vue";
import EatIconBtn from "@/components/utils/EatIconBtn.vue";
import EatImage from "@/components/EatImage.vue";
import Loading from "@/components/utils/Loading.vue";
import EatView from "@/layout/EatView.vue";
import FormView from "@/layout/FormView.vue";
import EatCta from "@/layout/partials/EatCta.vue";
import EatCtaButton from "@/layout/partials/EatCtaButton.vue";
import SimpleFormView from "@/layout/SimpleFormView.vue";
import EatBtn from "@/components/buttons/EatBtn.vue";
import EatFloatBtn from "@/components/buttons/EatFloatBtn.vue";
import EatUl from "@/components/utils/EatUl.vue";
import EatLi from "@/components/utils/EatLi.vue";
import EatField from "@/components/inputs/EatField.vue";
import EatFieldMessage from "@/components/inputs/EatFieldMessage.vue";
import EatSelect from "@/components/inputs/EatSelect.vue";
import EatSelector from "@/components/inputs/EatSelector.vue";
import EatTextInput from "@/components/inputs/EatTextInput.vue";
import EatTextArea from "@/components/inputs/EatTextArea.vue";
import EatStepper from "@/components/inputs/EatStepper.vue";
import EatNumberInput from "@/components/inputs/EatNumberInput.vue";
import EatCheckbox from "@/components/inputs/EatCheckbox.vue";
import EatToggleButton from "@/components/inputs/EatToggleButton.vue";
import EatToggleIcon from "@/components/inputs/EatToggleIcon.vue";
import EatRecaptcha from "@/components/inputs/EatRecaptcha.vue";
import RoundedContainer from "@/components/utils/RoundedContainer.vue";
import AddressFormatted from "@/components/utils/AddressFormatted.vue";

import { scrollableDirectiveOptions } from "./store/layout/scrollables";
import {
  animatableDirectiveOptions,
  scrollTriggerDirectiveOptions
} from "./store/animations/animations";

// Vue
import { createApp, type Component } from 'vue';
import { createPinia } from 'pinia';
import { type Router, isNavigationFailure, NavigationFailureType } from 'vue-router';

// GTag
import VueGtag from "vue-gtag";

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// Axios
import { type AxiosInstance } from "axios";

// IWC
import 'ipratico-web-components/dist/style.css';

// i18n
import { i18n } from "./i18n";
import { AuthPlugin, getOAuthBaseToken } from './plugins/auth';
import { VueRecaptchaPlugin } from 'vue-recaptcha/head';

// Vee validate
import { setLocale } from 'yup';
import { configure } from 'vee-validate';

configure({ 
  validateOnInput: true,
  validateOnBlur: true,
});
setLocale({
  mixed: {
    default: ({ label }) => ({ key: 'form.errors.invalid', params: { label } }),
    required: ({ label }) => ({ key: 'form.errors.required', params: { label } }),
  },
  string: {
    email: ({ label }) => ({ key: 'form.errors.string.email', params: { label } }),
    length: ({ length, label }) => ({ key: 'form.errors.string.length', params: { length, label } }),
    min: ({ min, label }) => ({ key: 'form.errors.string.min', params: { min, label } }),
    max: ({ max, label }) => ({ key: 'form.errors.string.max', params: { max, label } })
  },
  number: {
    min: ({ min, label }) => ({ key: 'form.errors.number.min', params: { min, label } }),
    max: ({ max, label }) => ({ key: 'form.errors.number.max', params: { max, label } }),
  },
});

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js");
}

let apiAxios: AxiosInstance | null = null;

export default function createEatUp(
  component: Component,
  router: Router,
  page: EatUpPage
) {

  // Per retrocompatibilità gestisco i QRCode che rimandano all'url /tavolo/#/paga?tavolo=table%3A2d698622-a895-4e6b-ac0a-794d056bc996
  if(location.href.match(/tavolo\/#\/paga/)) page = EAT_UP_PAGES.PAYMENT_FROM_TABLE;
  apiAxios = apiSetup(`${config.apiURL}/api/v1/${page}`);
  
  const app = createApp(component);

  // Componenti
  for (const [name, { component }] of Object.entries(icons.values)) {
    app.component(`icon-${name}`, component);
  }
  app.component("EatIcon", EatIcon);
  app.component("EatIconBtn", EatIconBtn);
  app.component("EatImage", EatImage);
  app.component("Loading", Loading);
  app.component("EatBtn", EatBtn);
  app.component("EatView", EatView);
  app.component("FormView", FormView);
  app.component("EatCta", EatCta);
  app.component("EatCtaButton", EatCtaButton);
  app.component("SimpleFormView", SimpleFormView);
  app.component("EatUl", EatUl);
  app.component("EatLi", EatLi);
  app.component("EatField", EatField);
  app.component("EatFieldMessage", EatFieldMessage);
  app.component("EatSelect", EatSelect);
  app.component("EatSelector", EatSelector);
  app.component("EatTextInput", EatTextInput);
  app.component("EatTextArea", EatTextArea);
  app.component("EatFloatBtn", EatFloatBtn);
  app.component("EatStepper", EatStepper);
  app.component("EatNumberInput", EatNumberInput);
  app.component("EatCheckbox", EatCheckbox);
  app.component("EatToggleIcon", EatToggleIcon);
  app.component("EatToggleButton", EatToggleButton);
  app.component("EatRecaptcha", EatRecaptcha);
  app.component("RoundedContainer", RoundedContainer);
  app.component("AddressFormatted", AddressFormatted);

  app.directive("scrollable", scrollableDirectiveOptions);
  app.directive("animatable", animatableDirectiveOptions);
  app.directive("scroll-trigger", scrollTriggerDirectiveOptions);

  app.config.errorHandler = (error, instance, info) => {
    setErrorSnackbar(error as Error);
    console.log(error);
    /* throw error; */
  }
  window.addEventListener("error", event => {
    setErrorSnackbar(event.error as Error);
    throw event.error;
  });
  window.addEventListener("unhandledrejection", event => {
    if (isNavigationFailure(event.reason, NavigationFailureType.aborted)) {
      console.log(event.reason);
    } else if (isNavigationFailure(event.reason, NavigationFailureType.cancelled)) {
      console.log(event.reason);
    } else if (isNavigationFailure(event.reason, NavigationFailureType.duplicated)) {
      console.log(event.reason);
    } else {
      throw event.reason;
    }
  });

  /* app.use(createPinia()); */

  setupRouterHistory(router);
  app.use(router);

  app.use(i18n);

  app.use(VueRecaptchaPlugin, {
    v2SiteKey: '6Lc_fKIUAAAAAF4bXjFfkT655LuVSyVlMzhGHYui',
  });

  app.use(AuthPlugin, {
    authOptions: {
      oauthURL: `${config.authURL}/oauth2/token`,
      baseToken: getOAuthBaseToken(),
      appAuthScope: page === EAT_UP_PAGES.MULTILOCATION ? "app-user:company" : "app-user:app"
    },
    pluginOptions: {
      router,
      axios: apiAxios
    }
  });

  // Setup vue-gtag to auto-track the router
  app.use(
    VueGtag,
    {
      bootstrap: false
    },
    router
  );
  
  app.mount('#app');

}

export { apiAxios };