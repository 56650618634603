<template>
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-history---big"
        transform="translate(-1.000000, -1.000000)"
        fill="currentColor"
      >
        <path
          d="M22.8933578,11.6194679 C23.1034494,17.6357045 18.3966389,22.6831408 12.3804023,22.8932324 C10.2988546,22.9659216 8.28109243,22.449964 6.51939512,21.4233245 C6.0899405,21.1730573 5.94468041,20.6220339 6.19494762,20.1925792 C6.44521482,19.7631246 6.99623823,19.6178645 7.42569284,19.8681317 C8.89594088,20.7249274 10.5781145,21.1550725 12.3175832,21.0943289 C17.3403128,20.9189313 21.2698519,16.7050166 21.0944543,11.682287 C20.9190567,6.65955732 16.705142,2.73001828 11.6824124,2.90541586 C7.1256984,3.06453982 3.46873022,6.54754777 2.96032547,10.9428781 L5.21318066,9.53522749 C5.58787188,9.30109443 6.08142149,9.41503933 6.31555455,9.78973056 C6.54968761,10.1644218 6.43574271,10.6579714 6.06105148,10.8921044 L2.32963986,13.2237492 C1.95494864,13.4578823 1.46139903,13.3439374 1.22726597,12.9692461 C1.12565377,12.8066326 1.08960214,12.6216321 1.11197237,12.4451658 C1.10898262,12.4244144 1.10741218,12.4024534 1.10663776,12.3802769 C0.896546146,6.36404029 5.60335665,1.31660398 11.6195933,1.10651237 C17.6358299,0.896420759 22.6832662,5.60323126 22.8933578,11.6194679 Z M12.5998695,6.49974411 L12.5998695,11.8877441 L16.4033009,15.6907344 L15.3426407,16.7513945 L11.0998695,12.4997441 L11.0998695,6.49974411 L12.5998695,6.49974411 Z"
          id="History"
        ></path>
      </g>
    </g>
  </svg>
</template>
