export const EAT_UP_PAGES = {
    RESTAURANT: 'restaurant',
    ORDER_FROM_TABLE: 'tavolo',
    PAYMENT_FROM_TABLE: 'pagaTavolo',
    MULTILOCATION: 'multilocation',
    TAKE_AWAY_ON_SITE: 'takeawayOnSite',
    MENU: 'menu'
} as const;
export type EatUpPage = Enum<typeof EAT_UP_PAGES>;

export interface NavigationItem {
    icon: string;
    activeIcon: string;
    link?: { name: string };
    name?: string;
}

export interface SelectData {
    label: string;
    value: string;
}
