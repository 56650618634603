<template>
  <svg
    width="16px"
    height="22px"
    viewBox="0 0 16 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-people---tab"
        transform="translate(-4.000000, -1.000000)"
        fill="currentColor"
      >
        <path
          d="M15,12.5 C17.7614237,12.5 20,14.7385763 20,17.5 L20,19.5 C20,21.1568542 18.6568542,22.5 17,22.5 L7,22.5 C5.34314575,22.5 4,21.1568542 4,19.5 L4,17.5 C4,14.7385763 6.23857625,12.5 9,12.5 L15,12.5 Z M15,14.5 L9,14.5 C7.40231912,14.5 6.09633912,15.74892 6.00509269,17.3237272 L6,17.5 L6,19.5 C6,20.0128358 6.38604019,20.4355072 6.88337887,20.4932723 L7,20.5 L17,20.5 C17.5128358,20.5 17.9355072,20.1139598 17.9932723,19.6166211 L18,19.5 L18,17.5 C18,15.9023191 16.75108,14.5963391 15.1762728,14.5050927 L15,14.5 Z M12,1.4 C14.7614237,1.4 17,3.63857625 17,6.4 C17,9.16142375 14.7614237,11.4 12,11.4 C9.23857625,11.4 7,9.16142375 7,6.4 C7,3.63857625 9.23857625,1.4 12,1.4 Z M12,3.4 C10.3431458,3.4 9,4.74314575 9,6.4 C9,8.05685425 10.3431458,9.4 12,9.4 C13.6568542,9.4 15,8.05685425 15,6.4 C15,4.74314575 13.6568542,3.4 12,3.4 Z"
          id="People"
        ></path>
      </g>
    </g>
  </svg>
</template>
