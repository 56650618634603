let config: {
  authURL: string;
  apiURL: string;
  mapsAPIKey: string;
  oauthClientSecret: string;
  locales: string[];
};

if (import.meta.env.VITE_APP_BUILD_PRODUCTION != null) {
  // production config here
  config = {
    authURL: "https://authservercb.ipraticocloud.com",
    apiURL: "https://customerapi.ipraticocloud.com",

    mapsAPIKey: "AIzaSyAFkniaEsfTc0mrQaQIGVs9FWM30QtEfQQ",

    oauthClientSecret: "ipratico",

    locales: ["it", "en"]
  };
} else {
  // staging config here
  config = {
    authURL: "https://authserverdev.ipraticocloud.com",
    apiURL: "https://customerapidev.ipraticocloud.com",
    /* apiURL: "http://localhost:3002", */

    mapsAPIKey: "AIzaSyAFkniaEsfTc0mrQaQIGVs9FWM30QtEfQQ",

    oauthClientSecret: "ipratico",

    locales: ["it", "en"]
  };
}

export default config;
