<template>
  <svg
    width="20px"
    height="21px"
    viewBox="0 0 20 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-position---big"
        transform="translate(-2.000000, -2.000000)"
        fill="currentColor"
      >
        <path
          d="M9.96333801,17.3753658 L9.21580145,15.1339893 L6.97319275,14.3868634 C2.86174314,13.0171332 2,11.2842164 2,10.0597606 C2,8.84568161 2.86174314,7.10238905 6.97319275,5.72228205 L15.7878912,2.78566345 C17.9889702,2.04891465 19.8266621,2.26682613 20.9583491,3.38751453 C22.090036,4.50820292 22.3080675,6.35526332 21.5709137,8.55513293 L18.6326828,17.3649889 C17.2518171,21.494933 15.5179479,22.3562012 14.2928191,22.3562012 C13.0676902,22.3562012 11.3338213,21.4949332 9.96333801,17.3753658 Z M3.54698458,10.0597606 C3.54698458,10.8068862 4.57484698,11.9483293 7.46116761,12.9029897 L10.0775446,13.7746354 C10.3059584,13.8472726 10.4928428,14.0340543 10.5655199,14.2623426 L11.4376458,16.8772826 C12.392831,19.7620174 13.5452828,20.7893146 14.2928191,20.7893146 C15.0403554,20.7893146 16.1824237,19.7620174 17.1479914,16.8772826 L20.0862232,8.06742618 C20.6157281,6.46940765 20.5222865,5.16193779 19.8474274,4.48744945 C19.1725683,3.81296112 17.8643801,3.72994725 16.2758656,4.25916118 L7.46116761,7.20615651 C4.57484698,8.17119371 3.54698458,9.31263502 3.54698458,10.0597606 Z"
          id="position"
        ></path>
      </g>
    </g>
  </svg>
</template>
