<template>
  <svg
    width="90px"
    height="119px"
    viewBox="0 0 90 119"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="01.-Login" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="1-3-5.-Account-/-reset-password"
        transform="translate(-142.000000, -163.000000)"
        fill="currentColor"
      >
        <path
          d="M220.75,202.375 L215.125,202.375 L215.125,191.125 C215.125,175.6 202.525,163 187,163 C171.475,163 158.875,175.6 158.875,191.125 L169.5625,191.125 C169.5625,181.50625 177.38125,173.6875 187,173.6875 C196.61875,173.6875 204.4375,181.50625 204.4375,191.125 L204.4375,202.375 L153.25,202.375 C147.0625,202.375 142,207.4375 142,213.625 L142,269.875 C142,276.0625 147.0625,281.125 153.25,281.125 L220.75,281.125 C226.9375,281.125 232,276.0625 232,269.875 L232,213.625 C232,207.4375 226.9375,202.375 220.75,202.375 L220.75,202.375 Z M153.25,213.625 L220.75,213.625 L220.75,269.875 L153.25,269.875 L153.25,213.625 Z"
          id="Fill-123"
        ></path>
      </g>
    </g>
  </svg>
</template>
