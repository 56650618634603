import { setStaticSnackbar } from "@/store/layout/snackbar";
import { getEnabledLocale } from "@/i18n";
import { setLocale as setValidateLocale } from "@vee-validate/i18n";
import type { App } from "@/models";
import type { NullRef } from ".";
import { LS, LSKeys } from "./localStorage";
import { apiAxios } from "@/app";
import type { Composer } from "vue-i18n";
import it from "@/i18n/translations/it.json";
import en from "@/i18n/translations/en.json";
import de from "@/i18n/translations/de.json";
import fr from "@/i18n/translations/fr.json";
import CalendarGenerator from "./calendar";

async function loadAppLocale(i18n: Composer, locale: string) {
  let messages = {};
  if(locale === 'it') messages = it;
  if(locale === 'en') messages = en;
  if(locale === 'de') messages = de;
  if(locale === 'fr') messages = fr;

  i18n.locale.value = locale;
  i18n.setLocaleMessage(locale, messages);
}

const loadLocale = async (i18n: any, locale: string) => {
  await loadAppLocale(i18n, locale);
};

const setLocale = (i18n: Composer, locale: string) => {
  i18n.locale.value = locale;
  document.querySelector("html")?.setAttribute("lang", locale);
  if (apiAxios) apiAxios.defaults.headers["Accept-Language"] = locale;
  setValidateLocale(locale);
};

export function saveLocaleToLocalStorage(locale: string) {
  LS.set(LSKeys.LOCALE, locale);
}

export async function setNewLanguage(locale: string) {
  saveLocaleToLocalStorage(locale);
  location.reload();
}

export async function chooseLocale(i18n: any, locale: string) {
  try {
    await loadLocale(i18n, locale);
    setLocale(i18n, locale);
    CalendarGenerator.setLocale(locale);
  } catch {
    try {
      // Fallback to English in case of errors
      locale = "it";
      await loadLocale(i18n, locale);
      setLocale(i18n, locale);
    } catch {
      setStaticSnackbar(i18n.t("app.snackbar.errorLoading").toString());
    }
  }
  saveLocaleToLocalStorage(locale);
}

export async function setAppLocale(i18n: Composer, app: NullRef<App>) {
  // set locale for the app, depending on the available languages and browser
  if (app.value) {
    const locale = getEnabledLocale(i18n.locale.value, app.value.availableLanguages);
    await chooseLocale(i18n, locale);
  }
}
