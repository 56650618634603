export interface Address {
  address: string;
  streetNumber: string;
  city: string;
  zipCode: string;
  nation: string;
  distanceKm?: number;
  isDefaultDeliveryAddress?: boolean;
}

export function addressToString(address: Address) {
  return `${address.address} ${address.streetNumber}, ${address.city}, ${address.zipCode}`;
}

export function isEqualAddress(oldAddress: Address, newAddress: Address) {
  if (!oldAddress || !newAddress) return false;
  return (
    oldAddress.address.toLowerCase() === newAddress.address.toLowerCase() &&
    oldAddress.city.toLowerCase() === newAddress.city.toLowerCase() &&
    oldAddress.nation.toLowerCase() === newAddress.nation.toLowerCase() &&
    oldAddress.streetNumber.toLowerCase() === newAddress.streetNumber.toLowerCase() &&
    oldAddress.zipCode.toLowerCase() === newAddress.zipCode.toLowerCase()
  );
}

export function isNotDuplicateAddress(places: Address[], address: Address) {
  return !(places.length && places.some(place => isEqualAddress(place, address)));
}

export function isAddressComplete(value: Address | null) {
  if (!value) return false;
  if (value.city && value.address && value.streetNumber && value.zipCode && value.nation) {
    return true;
  } else {
    return false;
  }
}
