import type { DateTimeSlot } from "@/store/timeSlots/dateTimeSlots";
import type { Product } from ".";
import type { Address } from "./Address";
import type { DeliveryPrice, Saletype } from "./App";
import type { CartPriceVariation, AffiliationDiscount } from "./PriceVariation";
import type { ProductConfiguration } from "./Product";
import type { AppliedPromoCode } from "./PromoCode";

export enum CartPrefix {
  RESTAURANT = "restaurant",
  TABLE = "table",
  TAKEAWAY_ON_SITE = "takeAwayOnSite",
  DIGITAL_MENU = "digitalMenu"
}

export interface CartItem {
  product: Product;
  configuration: ProductConfiguration;
  quantity: number;
  variation?: CartPriceVariation;
}

export interface Cart {
  createdAt: string;
  prefix: CartPrefix;
  saletype: Saletype;
  items: CartItem[];
  priceList: string | null;
  deliveryAddress: Address | null;
  deliveryPrice: DeliveryPrice | null | undefined;
  subtotalVariation: CartPriceVariation | null;
  affiliationDiscount: AffiliationDiscount | null;
  promoCodes: AppliedPromoCode[];
  orderTimeSlot: DateTimeSlot | null;
  total: number;
  subTotal: number;
}

export interface InitCartOptions {
  prefix: CartPrefix;
  saletype: Saletype;
  priceList?: string | null;
  deliveryAddress?: Address | null;
  deliveryPrice?: DeliveryPrice | null;
}

export function nItems(cart: Cart) {
  return cart.items.map(i => i.quantity).reduce((prev, curr) => prev + curr, 0);
}
