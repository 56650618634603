import type { Category, Product } from "@/models";
import type { CartItem } from "@/models/Cart";
import { computed } from "vue";
import { useCart } from "../cart";
import type { AppliedPromoCode } from "@/models/PromoCode";
import type { CartPriceVariation, ItemPriceVariation } from "@/models/PriceVariation";

export function useCartItems() {
  const {
    cart,
    setCartSubtotalVariation,
    setCartMembershipDiscount,
    setCartPromoCodes
  } = useCart();

  const addPromoCode = (
    promoCodes: AppliedPromoCode[],
    itemsVariation: ItemPriceVariation[],
    subtotalVariation: CartPriceVariation | null
  ) => {
    if (!cart.value) throw new Error("Cart can't be null");
    if (itemsVariation?.length) {
      cart.value.items.map(item => {
        item.variation =
          itemsVariation.find(variation => variation.productId === item.product.id) || undefined;
        return item;
      });
    }

    if (subtotalVariation) {
      setCartSubtotalVariation(subtotalVariation);
      setCartMembershipDiscount(null);
    }
    setCartPromoCodes(promoCodes);
  };

  const clearPromoCodes = () => {
    if (!cart.value) throw new Error("Cart can't be null");

    for (const item of cart.value.items) {
      item.variation = undefined;
    }

    if (cart.value.subtotalVariation?.promoCode?.code) cart.value.subtotalVariation = null;
    setCartPromoCodes([]);
  };

  const removePromoCodeById = (id: string) => {
    if (!cart.value) throw new Error("Cart can't be null");

    for (const item of cart.value.items) {
      if (item.variation?.promoCode?.promoCodeId === id) item.variation = undefined;
    }
    if (cart.value.subtotalVariation?.promoCode?.promoCodeId === id) {
      setCartSubtotalVariation(null);
    }
    setCartPromoCodes(cart.value.promoCodes.filter(promoCode => promoCode.promoCodeId !== id));
  };

  const removePromoCodeByCode = (code: string) => {
    if (!cart.value) throw new Error("Cart can't be null");

    for (const item of cart.value.items) {
      if (item.variation?.promoCode?.code === code) item.variation = undefined;
    }

    if (cart.value.subtotalVariation?.promoCode?.code === code) cart.value.subtotalVariation = null;
    setCartPromoCodes(cart.value.promoCodes.filter(promoCode => promoCode.code !== code));
  };

  const clearCartItems = () => {
    if (!cart.value) throw new Error("Cart can't be null");

    clearPromoCodes();

    cart.value.items = [];
  };

  const updateCartItems = (items: CartItem[]) => {
    if (!cart.value) throw new Error("Cart can't be null");
    cart.value.items = items;
    clearPromoCodes();
  };

  const addToCart = (itemToAdd: CartItem) => {
    if (!cart.value) throw new Error("Cart can't be null");

    const itemIndex = cart.value.items.findIndex(item => {
      return (
        JSON.stringify(itemToAdd.configuration) == JSON.stringify(item.configuration) &&
        JSON.stringify(itemToAdd.product) == JSON.stringify(item.product)
      );
    });

    if (itemIndex >= 0) {
      cart.value.items[itemIndex].quantity += itemToAdd.quantity;
    } else {
      cart.value.items.push(itemToAdd);
    }

    cart.value = JSON.parse(JSON.stringify(cart.value));
    clearPromoCodes();
  };

  const removeFromCart = (itemToRemove: CartItem) => {
    if (!cart.value) throw new Error("Cart can't be null");

    cart.value.items.splice(cart.value.items.indexOf(itemToRemove), 1);
    clearPromoCodes();
  };

  const updateQuantity = (itemToEdit: CartItem, newQuantity: number) => {
    if (!cart.value) throw new Error("Cart can't be null");

    cart.value.items = cart.value.items.map(item => {
      if (item == itemToEdit) {
        item.quantity = newQuantity;
      }
      return item;
    });

    clearPromoCodes();
  };

  const getSameProductCartItems = (product: Product) => {
    return cart.value?.items.filter(item => item.product.id === product.id) || null;
  };

  const flattenedProductsInCart = computed(() => {
    if (!cart.value) return {};
    const productsQuantities: { [productId: string]: number } = {};

    for (const item of cart.value?.items) {
      if (item.product.isModular) {
        const products = [
          ...Object.values(item.configuration.modules).flat(),
          ...Object.values(item.configuration.upsellingModules).flat()
        ];
        for (const product of products) {
          const productQuantity = productsQuantities[product.id];
          if (productQuantity) {
            productsQuantities[product.id] += item.quantity;
          } else {
            productsQuantities[product.id] = item.quantity;
          }
        }
      } else {
        const productQuantity = productsQuantities[item.product.id];
        if (productQuantity) {
          productsQuantities[item.product.id] += item.quantity;
        } else {
          productsQuantities[item.product.id] = item.quantity;
        }
      }
    }
    return productsQuantities;
  });

  const isCategoryOverloaded = (category: Category) => {
    if (!category.productionRate) return false;
    let productionRate = category.productionRate;
    for (const item of cart.value?.items || []) {
      if (item.product.categoryId === category.id) productionRate -= item.quantity;
    }
    if (productionRate < 0) return true;
    else return false;
  };

  const getOverloadedCategories = (categories: Category[]) => {
    if (!cart.value) return [] as Category[];
    return categories.filter(category => isCategoryOverloaded(category));
  };

  return {
    flattenedProductsInCart,
    getOverloadedCategories,
    clearCartItems,
    addToCart,
    removeFromCart,
    updateQuantity,
    getSameProductCartItems,
    updateCartItems,
    isCategoryOverloaded,
    addPromoCode,
    removePromoCodeById,
    removePromoCodeByCode
  };
}
