<template>
  <svg
    width="14px"
    height="9px"
    viewBox="0 0 14 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="collapsed" transform="translate(-5.000000, -7.000000)">
      <g transform="translate(5.161444, 7.838556)">
        <path
          d="M3.129716,3.11119159 L8.78657025,-2.54566266 C9.17709454,-2.93618695 9.81025952,-2.93618695 10.2007838,-2.54566266 C10.5913081,-2.15513837 10.5913081,-1.52197339 10.2007838,-1.1314491 L5.24155588,3.82744412 L10.200784,8.78657039 C10.5913082,9.17709468 10.5913082,9.81025966 10.200784,10.200784 C9.81025966,10.5913082 9.17709468,10.5913082 8.78657039,10.200784 L3.12971614,4.5439297 C2.94947416,4.36368772 2.85242079,4.13175897 2.83855602,3.89585431 L2.83855588,3.75926699 C2.85242064,3.52336233 2.94947402,3.29143357 3.129716,3.11119159 Z"
          id="back"
          fill="currentColor"
          transform="translate(6.666117, 3.827561) scale(1, -1) rotate(180.000000) translate(-6.666117, -3.827561) "
        ></path>
      </g>
    </g>
  </svg>
</template>
