import { type Address, isEqualAddress } from "@/models/Address";
import type { Auth } from "@/plugins/auth";
import { ref } from "vue";
import { useDeliveryDistance } from "@/store/delivery/deliveryDistance";
import { useLocalStorageAddressesService } from "@/store/delivery/localStorageAddressesService";
import { useCart } from "../cart";
import { Saletype } from "@/models/App";
import { useDeliveryAddressSelector } from "./deliveryAddress";

const deliveryAddresses = ref<Address[]>([]);
const defaultDeliveryAddress = ref<Address>();

export function useDeliveryAddresses(auth?: Auth) {
  const getDefaultDeliveryAddress = () => {
    return deliveryAddresses.value?.find(address => {
      return address.isDefaultDeliveryAddress;
    });
  };

  const saveNewAddress = async (address: Address) => {
    const { deliveryAddress } = useDeliveryAddressSelector();
    const { updateLocalStorageAddresses } = useLocalStorageAddressesService(auth);
    const { getDistanceFromLocation } = useDeliveryDistance();
    const { cart, setInitialCartDeliveryAddress } = useCart();
    
    const isDuplicate = deliveryAddresses.value.some(oldAddress => isEqualAddress(oldAddress, address));
    if(!isDuplicate) {
      const distance = await getDistanceFromLocation(address);
      const completeAddress: Address = {
        ...address,
        isDefaultDeliveryAddress: false,
        distanceKm: distance
      };
      deliveryAddresses.value?.push(completeAddress);
      updateLocalStorageAddresses(deliveryAddresses.value);
      defaultDeliveryAddress.value = getDefaultDeliveryAddress();
      if (cart.value && !cart.value.deliveryAddress) {
        setInitialCartDeliveryAddress();
        deliveryAddress.value = cart.value.deliveryAddress;
      }
    }

  };

  const modifyAddress = async (index: number, newValue: Address) => {
    const { updateLocalStorageAddresses } = useLocalStorageAddressesService(auth);
    const { getDistanceFromLocation } = useDeliveryDistance();
    const distance = await getDistanceFromLocation(newValue);
    if (
      newValue.isDefaultDeliveryAddress &&
      defaultDeliveryAddress.value &&
      !isEqualAddress(defaultDeliveryAddress.value, newValue)
    ) {
      deliveryAddresses.value = deliveryAddresses.value.map(place => {
        place.isDefaultDeliveryAddress = false;
        return place;
      });
    }
    deliveryAddresses.value[index] = {
      ...newValue,
      distanceKm: distance
    };
    updateLocalStorageAddresses(deliveryAddresses.value);
    defaultDeliveryAddress.value = getDefaultDeliveryAddress();
  };

  const deleteAddress = async (index: number) => {
    const { cart, setInitialCartDeliveryAddress } = useCart();
    const { updateLocalStorageAddresses } = useLocalStorageAddressesService(auth);
    const oldAddress = deliveryAddresses.value[index];
    deliveryAddresses.value.splice(index, 1);

    updateLocalStorageAddresses(deliveryAddresses.value);
    if (cart.value?.deliveryAddress) {
      if (!deliveryAddresses.value.length) {
        cart.value.deliveryAddress = null;
        cart.value.deliveryPrice = null;
      } else if(isEqualAddress(oldAddress, cart.value.deliveryAddress)) {
        cart.value.deliveryAddress = null;
        cart.value.deliveryPrice = null;
        setInitialCartDeliveryAddress();
      }
    }

    defaultDeliveryAddress.value = getDefaultDeliveryAddress();
    return oldAddress;
  };

  const getInitialDeliveryAddress = (): Address | null => {
    const { isAddressInRange } = useDeliveryDistance();
    let addressesInRange: Address[] | undefined;
    if (isAddressInRange(defaultDeliveryAddress.value)) {
      return defaultDeliveryAddress.value as Address;
    } else {
      addressesInRange = deliveryAddresses.value?.filter(place => isAddressInRange(place));
      return addressesInRange?.length ? addressesInRange[0] : null;
    }
  };

  const initLocalStorageAddressService = async () => {
    const { getLocalStorageAddresses } = useLocalStorageAddressesService(auth);
    const { getDefaultDeliveryAddress } = useDeliveryAddresses();
    deliveryAddresses.value = await getLocalStorageAddresses();
    defaultDeliveryAddress.value = getDefaultDeliveryAddress();
  };

  const onLoginGetUserDeliveryAddresses = async () => {
    const {
      mergeLocalStorageAndApiAddresses,
      getLocalStorageAddresses
    } = useLocalStorageAddressesService(auth);
    const addresses = await getLocalStorageAddresses();
    deliveryAddresses.value = await mergeLocalStorageAndApiAddresses(addresses);
  };

  const onLogoutKeepCartDeliveryPrice = () => {
    const { clearAddresses, updateLocalStorageAddresses } = useLocalStorageAddressesService(auth);
    const { cart } = useCart();
    clearAddresses();
    if (cart.value?.saletype === Saletype.DELIVERY && cart.value?.deliveryAddress) {
      updateLocalStorageAddresses([cart.value.deliveryAddress]);
    }
  };

  return {
    deliveryAddresses,
    defaultDeliveryAddress,
    saveNewAddress,
    modifyAddress,
    deleteAddress,
    getInitialDeliveryAddress,
    getDefaultDeliveryAddress,
    initLocalStorageAddressService,
    onLoginGetUserDeliveryAddresses,
    onLogoutKeepCartDeliveryPrice
  };
}
