<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-/-Navigation-24px-/-Grey-/-search---big" fill="currentColor">
        <path
          d="M19.0867933,18.5393122 L23.0702244,21.8818078 C23.4509916,22.2013094 23.5006572,22.7689894 23.1811556,23.1497566 C22.8616539,23.5305238 22.2939739,23.5801894 21.9132067,23.2606878 L17.9297756,19.9181922 C17.5490084,19.5986906 17.4993428,19.0310106 17.8188444,18.6502434 C18.1383461,18.2694762 18.7060261,18.2198106 19.0867933,18.5393122 Z M10.1,0.1 C12.7521648,0.1 15.2957041,1.1535685 17.1710679,3.02893233 C19.0464317,4.90429569 20.1,7.44783499 20.1,10.1 C20.1,15.6228472 15.6228472,20.1 10.1,20.1 C4.57715265,20.1 0.1,15.6228473 0.1,10.1 C0.1,4.57715259 4.57715259,0.1 10.1,0.1 Z M10.1,1.61260504 C5.41254129,1.61260504 1.61260504,5.41254129 1.61260504,10.1 C1.61260504,14.7874586 5.41254136,18.587395 10.1,18.587395 C14.7874585,18.587395 18.587395,14.7874585 18.587395,10.1 C18.587395,7.84900278 17.6931899,5.69020056 16.1014947,4.09850567 C14.5097993,2.50681025 12.350997,1.61260504 10.1,1.61260504 Z"
          id="Search"
        ></path>
      </g>
    </g>
  </svg>
</template>
