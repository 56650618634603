<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>order</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect
        id="Rectangle"
        stroke="currentColor"
        fill="currentColor"
        x="5.5"
        y="12.5"
        width="12.5"
        height="1"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle"
        stroke="currentColor"
        fill="currentColor"
        x="5.5"
        y="16.5"
        width="12.5"
        height="1"
        rx="0.5"
      ></rect>
      <path
        d="M6.9,24 C2.51999989,24 0,21.4800001 0,17.1 L0,6.9 C0,2.51999989 2.51999989,0 6.9,0 L16.5,0 C20.8800001,0 23.4,2.51999989 23.4,6.9 L23.4,17.1 C23.4,21.4800001 20.8800001,24 16.5,24 L6.9,24 Z M1.8,6.9 L1.8,17.1 C1.8,20.5319999 3.46800013,22.2 6.9,22.2 L16.5,22.2 C19.9319999,22.2 21.6,20.5319999 21.6,17.1 L21.6,6.9 C21.6,3.46800013 19.9319999,1.8 16.5,1.8 L6.9,1.8 C3.46800013,1.8 1.8,3.46800013 1.8,6.9 Z"
        id="Vector"
        fill="currentColor"
      ></path>
    </g>
  </svg>
</template>
