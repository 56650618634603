<template lang="pug">
div.flex-shrink-0
  transition(name="fade")
    div.ios-bottom-spacer-bg-white(v-if="isDeviceIOS && !showSnackbar")
  div.snackbar.d-flex.flex-column(v-if="showSnackbar" :class="snackbar ? 'bg-'+snackbar.type : 'white'" ref="$snackbar")
    transition(name="fade")
      div.snackbar__text.flex-grow-1.text-center.py-1.px-5.text-caption(v-if="snackbar" :class="snackbarTextColorClass" ref="$content") {{ snackbar.message }}
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useOnline } from "@vueuse/core";
import { isDeviceIOS } from "@/utils/userAgentData";
import {
  useSnackbar,
  TypeSnackbar,
  setSnackbar,
  setStaticSnackbar,
  freezeSnackbar,
  unfreezeSnackbar
} from "@/store/layout/snackbar";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const online = useOnline();
const snackbar = useSnackbar();
const showSnackbar = ref(false);

const $snackbar = ref();
const $content = ref();

const snackbarTextColorClass = computed(() => {
  return snackbar.value?.type === TypeSnackbar.WARNING ? "text-black" : "text-white";
});

watch(snackbar, () => {
  if (snackbar.value) {
    showSnackbar.value = true;
    nextTick(() => {
      if (!$snackbar.value?.style) return;
      $snackbar.value.style.paddingBottom = `${$content.value?.clientHeight || 0}px`;
    });
  } else {
    if (!$snackbar.value?.style) return;
    $snackbar.value.style.paddingBottom = "0px";
    $snackbar.value?.addEventListener(
      "transitionend",
      () => {
        $snackbar.value?.style?.removeProperty("padding-bottom");
        showSnackbar.value = false;
      },
      { once: true }
    );
  }
});

watch(online, () => {
  if (!online.value) {
    freezeSnackbar();
    setStaticSnackbar(i18n.t("app.snackbar.noConnection").toString());
  }
  if (online.value) {
    const text = i18n.t("app.snackbar.backOnline").toString();
    unfreezeSnackbar();
    setSnackbar(text, TypeSnackbar.SUCCESS);
  }
});
</script>

<style lang="scss" scoped>
.snackbar {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  transition: background-color 0.8s, padding-bottom 0.5s;
  .snackbar__text {
    position: absolute;
    min-width: 100%;
    max-width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    word-wrap: break-word;
  }
}

.ios-bottom-spacer {
  padding-bottom: 16px;
}

.fade-enter-active,
.fade-leave-active {
  .snackbar__text {
    opacity: 1;
    transition: opacity 0.5s;
  }
}
.fade-enter,
.fade-leave-to {
  .snackbar__text {
    opacity: 0;
  }
}
</style>
