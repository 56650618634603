import { getOrderInfo, type TableData, TableStatus } from "@/api/Table";
import { PaymentFlow, PaymentMethod, Saletype } from "@/models/App";
import { CartPrefix } from "@/models/Cart";
import type { Order } from "@/models/Order";
import type { Auth } from "@/plugins/auth";
import { useCart } from "@/store/cart";
import { computed } from "vue";
import type { Router } from "vue-router";
import { useGlobalState } from "..";
import { usePaymentGetters } from "./payment";
import { useSyncActions } from "./sync";
import { useTableGetters } from "./table";

export const useOrderGetters = () => {
  const order = computed(() => {
    const { order } = useGlobalState();
    return order.value;
  });
  const items = computed(() => {
    const { order } = useGlobalState();
    return order.value?.orderItems || [];
  });
  const payments = computed(() => {
    const { order } = useGlobalState();
    return order.value?.payments || [];
  });
  const leftToPayAmount = computed(() => {
    const { order } = useGlobalState();
    return order.value?.leftToPayAmount || 0;
  });
  const finalPrice = computed(() => {
    const { order } = useGlobalState();
    return order.value?.finalPrice || 0;
  });
  const serviceChargeItem = computed(() => {
    const { order } = useGlobalState();
    return order.value?.serviceChargeItem || null;
  });
  const subtotalItem = computed(() => {
    const { order } = useGlobalState();
    return order.value?.subtotalItem || null;
  });
  const status = computed(() => {
    const { order } = useGlobalState();
    return order.value?.status || null;
  });
  const saletype = computed(() => {
    const { order } = useGlobalState();
    return order.value?.saleType || null;
  });
  const paymentFlow = computed(() => {
    const { order } = useGlobalState();
    let orderPaymentFlow = null;
    if (order.value?.isDeposit) orderPaymentFlow = PaymentFlow.DEPOSIT;
    if (order.value?.isDutch) orderPaymentFlow = PaymentFlow.DUTCH;
    if (order.value?.isSplit) orderPaymentFlow = PaymentFlow.SPLIT;
    return orderPaymentFlow;
  });
  const dutchDivider = computed(() => {
    const { order } = useGlobalState();
    return order.value?.dutchDivider || null;
  });
  const dutchPaid = computed(() => {
    const { order } = useGlobalState();
    return order.value?.dutchPaying || null;
  });

  return {
    order,
    saletype,
    items,
    status,
    serviceChargeItem,
    subtotalItem,
    payments,
    leftToPayAmount,
    finalPrice,
    paymentFlow,
    dutchDivider,
    dutchPaid
  };
};

const useOrderMutations = () => {
  const UPDATE_TABLE_ORDER = (newOrder: Order) => {
    const { order } = useGlobalState();
    order.value = newOrder;
  };

  return {
    UPDATE_TABLE_ORDER
  };
};

export const useOrderActions = () => {
  const mustNewOrdersBeAvoided = (order: Order, router: Router) => {
    return order.payments?.length && !/(checkout|payment)/.test(router.currentRoute.value.name as string || "");
  };

  const mustFurtherPaymentsBeAvoided = (order: Order, router: Router) => {
    const { payments, orderItems, leftToPayAmount } = order;
    const isOrderPaid = orderItems.length && payments.length && leftToPayAmount === 0;
    const isPaymentsCompletionDipendentRoute = !/(payment|checkout(?!\.))/.test(
      router.currentRoute.value.name as string || ""
    );
    return isOrderPaid && isPaymentsCompletionDipendentRoute;
  };

  const mustCurrentPaymentFlowBeDiscarded = (router: Router) => {
    const { paymentFlow: orderPaymentFlow } = useOrderGetters();
    const { paymentFlow } = usePaymentGetters();
    if (!(orderPaymentFlow.value && paymentFlow.value)) return false;
    const paymentFlowMismatch = orderPaymentFlow.value !== paymentFlow.value;
    const isPaymentFlowDipendentRoute = /(splitPayment|dutchPayment|personalizedAmount|paymentMethod)/.test(
      router.currentRoute.value.name as string || ""
    );
    return paymentFlowMismatch && isPaymentFlowDipendentRoute;
  };

  const isTableClosed = (order: Order, tableData: TableData | null) => {
    return (
      tableData?.status === TableStatus.PAYING &&
      order.paymentMethod === PaymentMethod.CASH_ON_DELIVERY
    );
  };

  const handleOrderChanges = (router: Router, auth: Auth) => {
    const { startAutoAPISync, stopAutoAPISync } = useSyncActions();
    const { table, order } = useGlobalState();
    const { cart, clearCart, initCart } = useCart();
    if (!cart.value) throw new Error("This should never happen");
    if (!order.value || !Object.keys(order.value).length) return;

    stopAutoAPISync();
    if (isTableClosed(order.value, table.value.data)) return router.push({ name: "tableClosed" });
    startAutoAPISync(router, auth);

    if (mustNewOrdersBeAvoided(order.value, router)) {
      clearCart();
      const priceList = table.value.data?.priceLists[0].id || "";
      initCart({ prefix: CartPrefix.TABLE, saletype: Saletype.ORDER_FROM_TABLE, priceList });
      return router.push({ name: "checkout" });
    } else if (mustFurtherPaymentsBeAvoided(order.value, router)) {
      return router.push({ name: "checkout" });
    } else if (mustCurrentPaymentFlowBeDiscarded(router)) {
      setTimeout(() => {
        throw new Error(
          "Un utente al tavolo o in cassa ha iniziato un'altra tipologia di pagamento"
        );
      }, 500);
      return router.push({ name: "checkout" });
    }
  };

  const updateOrder = (order: Order, router: Router, auth: Auth) => {
    const { UPDATE_TABLE_ORDER } = useOrderMutations();
    UPDATE_TABLE_ORDER(order);
    handleOrderChanges(router, auth);
  };

  const setTableOrder = async (router: Router, auth: Auth) => {
    const { id } = useTableGetters();
    if (!id.value) return;
    const order = await getOrderInfo(id.value);
    updateOrder(order, router, auth);
  };

  return {
    setTableOrder,
    updateOrder
  };
};
