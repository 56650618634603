<template lang="pug">
div.loading-container.d-flex.flex-column.align-center.justify-center
  div.progress-circular__container(role="progressbar" aria-valuemin="0" aria-valuemax="100" :style="`height: ${size}px; width: ${size}px;`")
    svg.progress-circular(xmlns="http://www.w3.org/2000/svg" :viewBox="`${minViewBox} ${minViewBox} ${svgSize} ${svgSize}`" style="transform: rotate(0deg);")
      circle.progress-circular__overlay(fill="transparent" :cx="svgSize" :cy="svgSize" :r="radius" :stroke-width="strokeWidth" stroke-dasharray="125.664" stroke-dashoffset="125.66370614359172px")
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps({
  width: {
    default: 7,
    type: Number
  },
  size: {
    default: 70,
    type: Number
  }
});

const radius = ref(20);
const strokeWidth = computed(() => 0.5 * props.width);
const minViewBox = computed(() => radius.value + strokeWidth.value / 2);
const svgSize = computed(() => radius.value * 2 + strokeWidth.value);
</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
  height: 100%;
}

.progress-circular__overlay {
  stroke: currentColor;
  transition: all 0.6s ease-in-out;
  animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0px;
}

.progress-circular {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  animation: progress-circular-rotate 1.4s linear infinite;
  transform-origin: center center;
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.progress-circular__container {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
</style>
