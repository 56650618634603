<template>
  <svg
    width="23px"
    height="24px"
    viewBox="0 0 23 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-address-plus---big"
        transform="translate(-1.000000, 0.000000)"
        fill="currentColor"
      >
        <path
          d="M10.5076923,1.23219477 L10.5076923,2.87512112 L10.2516601,2.87924453 C6.17147422,3.01084536 2.89230769,6.27580547 2.89230769,10.2682897 C2.89230769,13.6224611 5.98876522,17.9068976 10.3889396,21.7251576 L10.5076923,21.8262766 L10.6263778,21.7251113 C14.9165272,18.001726 17.9674349,13.836342 18.1172989,10.5215725 L18.1230769,10.2682897 L19.8153846,10.2682897 C19.8153846,14.3325264 16.3282975,19.0691234 11.4044148,23.2505053 L10.5077472,24 L9.95686508,23.541366 C4.85120858,19.2906783 1.2,14.4269941 1.2,10.2682897 C1.2,5.37424812 5.22712161,1.37724384 10.2336556,1.23605428 L10.5076923,1.23219477 Z M10.5076923,6.16097383 C12.8442816,6.16097383 14.7384615,7.9998818 14.7384615,10.2682897 C14.7384615,12.5366977 12.8442816,14.3756056 10.5076923,14.3756056 C8.17110298,14.3756056 6.27692308,12.5366977 6.27692308,10.2682897 C6.27692308,7.9998818 8.17110298,6.16097383 10.5076923,6.16097383 Z M10.5076923,7.80390019 C9.10573871,7.80390019 7.96923077,8.90724497 7.96923077,10.2682897 C7.96923077,11.6293345 9.10573871,12.7326793 10.5076923,12.7326793 C11.9096459,12.7326793 13.0461538,11.6293345 13.0461538,10.2682897 C13.0461538,8.90724497 11.9096459,7.80390019 10.5076923,7.80390019 Z M19.8153846,0 L19.8153846,3.28585271 L23.2,3.28585271 L23.2,4.92877907 L19.8153846,4.92877907 L19.8153846,8.21463178 L18.1230769,8.21463178 L18.1230769,4.92877907 L14.7384615,4.92877907 L14.7384615,3.28585271 L18.1230769,3.28585271 L18.1230769,0 L19.8153846,0 Z"
          id="point-plus"
        ></path>
      </g>
    </g>
  </svg>
</template>
