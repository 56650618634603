<template>
  <svg
    width="22px"
    height="27px"
    viewBox="0 0 22 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-/-Navigation-24px-/-Grey-/-paypal04" transform="translate(-1.000000, 1.000000)">
        <g id="Group-23" transform="translate(1.000000, -1.000000)">
          <path
            d="M4.76829037,0 L12.0937626,0 C14.1569381,0 16.603925,0.0670536744 18.2409791,1.51069095 C19.3344262,2.47513056 19.9082545,4.01169856 19.7756582,5.66121895 C19.3264931,11.2548176 15.9806091,14.3878297 11.4938683,14.3878297 L7.88090304,14.3878297 C7.26608696,14.3878297 6.85847617,14.7952516 6.68508104,15.8988984 L5.67682043,22.3122518 C5.61146671,22.7283624 5.43108288,22.9735333 5.10185879,23.003188 L0.58924094,23.003188 C0.0885105434,23.003188 -0.0894177982,22.6201321 0.0414785296,21.7905553 L3.29103181,1.21697697 C3.4211726,0.3925001 3.87222662,0 4.76829037,0"
            id="Fill-1"
            fill="#1B3D92"
          ></path>
          <path
            d="M9.47945357,6.44083737 L16.7975594,6.44083737 C20.726338,6.44083737 22.2056743,8.42959269 21.9773141,11.3563439 C21.6006802,16.1809974 18.683562,18.8487893 14.8144706,18.8487893 L12.8608476,18.8487893 C12.3306514,18.8487893 11.9738503,19.1993572 11.8293543,20.152086 L10.9914667,25.6829753 C10.9370682,26.0422319 10.7481846,26.2532149 10.4642926,26.278903 L5.87555468,26.278903 C5.44376679,26.278903 5.29020443,25.9485456 5.40315682,25.2330546 L8.20543376,7.49046348 C8.31763061,6.77931676 8.70654192,6.44083737 9.47945357,6.44083737"
            id="Fill-1"
            fill="#00A2D3"
          ></path>
          <path
            d="M16.7975594,6.44083737 C17.9388806,6.44083737 18.8734887,6.60867215 19.6195685,6.91858123 C18.7999219,11.7225482 15.6321637,14.3878297 11.4938683,14.3878297 L7.88090304,14.3878297 C7.53544572,14.3878297 7.25540669,14.5164598 7.04557753,14.824953 L8.20543376,7.49046348 C8.31763061,6.77931676 8.70654192,6.44083737 9.47945357,6.44083737 L16.7975594,6.44083737 Z"
            id="Combined-Shape"
            fill="#1B2E7F"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
