import type { Directive } from "vue";

export const scrollableDirectiveOptions: Directive = {
  mounted(el: HTMLElement, binding: any, vnode: any) {
    if (binding.value === true) {
      el.style.overflow = "auto";
      el.style.scrollBehavior = "smooth";
      // @ts-ignore
      el.style.scrollbarWidth = "none";
    } else {
      el.style.overflow = "hidden";
    }
  },
  updated(el, binding, vnode) {
    if (binding.value === true) {
      el.style.overflow = "auto";
      el.style.scrollBehavior = "smooth";
      // @ts-ignore
      el.style.scrollbarWidth = "none";
    } else {
      el.style.overflow = "hidden";
    }
  }
};
