<template>
  <svg
    width="16px"
    height="20px"
    viewBox="0 0 16 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-Grey-/-lock-open"
        transform="translate(-4.000000, -2.000000)"
        fill="currentColor"
      >
        <path
          d="M17.3333333,8.66666667 L16.3809524,8.66666667 L16.3809524,6.76190476 C16.3809524,4.13333333 14.247619,2 11.6190476,2 C8.99047619,2 6.85714286,4.13333333 6.85714286,6.76190476 L8.66666667,6.76190476 C8.66666667,5.13333333 9.99047619,3.80952381 11.6190476,3.80952381 C13.247619,3.80952381 14.5714286,5.13333333 14.5714286,6.76190476 L14.5714286,8.66666667 L5.9047619,8.66666667 C4.85714286,8.66666667 4,9.52380952 4,10.5714286 L4,20.0952381 C4,21.1428571 4.85714286,22 5.9047619,22 L17.3333333,22 C18.3809524,22 19.2380952,21.1428571 19.2380952,20.0952381 L19.2380952,10.5714286 C19.2380952,9.52380952 18.3809524,8.66666667 17.3333333,8.66666667 L17.3333333,8.66666667 Z M5.9047619,10.5714286 L17.3333333,10.5714286 L17.3333333,20.0952381 L5.9047619,20.0952381 L5.9047619,10.5714286 Z"
          id="Fill-123"
        ></path>
      </g>
    </g>
  </svg>
</template>
