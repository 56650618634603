<template>
  <svg
    width="16px"
    height="22px"
    viewBox="0 0 16 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Icons-/-Navigation-24px-/-bold-/-people---tab02"
        transform="translate(-4.000000, -1.000000)"
        fill="currentColor"
      >
        <path
          d="M15,12.5 C17.7614237,12.5 20,14.7385763 20,17.5 L20,19.5 C20,21.1568542 18.6568542,22.5 17,22.5 L7,22.5 C5.34314575,22.5 4,21.1568542 4,19.5 L4,17.5 C4,14.7385763 6.23857625,12.5 9,12.5 L15,12.5 Z M12,1.4 C14.7614237,1.4 17,3.63857625 17,6.4 C17,9.16142375 14.7614237,11.4 12,11.4 C9.23857625,11.4 7,9.16142375 7,6.4 C7,3.63857625 9.23857625,1.4 12,1.4 Z"
          id="people-bold"
        ></path>
      </g>
    </g>
  </svg>
</template>
