import { type Address } from "@/models/Address";
import { getDeliveryDistanceMatrix } from "@/utils/googleUtils";
import { computed } from "vue";
import { useApp } from "../app";

export function useDeliveryDistance() {
  const { deliveryService, locationAddress } = useApp();

  const maxDeliveryDistance = computed(() => deliveryService.value?.maxDeliveryDistance);

  const getDistanceFromLocation = async (address: Address) => {
    const distanceMatrix = await getDeliveryDistanceMatrix(locationAddress.value, address);
    return distanceMatrix;
  };

  const isAddressInRange = (address?: Address) => {
    const distance = address?.distanceKm;
    if(!distance && !(distance === 0)) return false;
    if(!maxDeliveryDistance.value) return true;
    return distance < maxDeliveryDistance.value;
  };

  return { getDistanceFromLocation, isAddressInRange, maxDeliveryDistance };
}
