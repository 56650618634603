<template>
  <svg
    width="112px"
    height="78px"
    viewBox="0 0 112 78"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="01.-Login" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="1-2-5.-Account-/-Crea-Account"
        transform="translate(-131.000000, -186.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <path
          d="M233,186 C238.522847,186 243,190.477153 243,196 L243,254 C243,259.522847 238.522847,264 233,264 L141,264 C135.477153,264 131,259.522847 131,254 L131,196 C131,190.477153 135.477153,186 141,186 L233,186 Z M135.004138,195.775062 L135,196 L135,254 C135,257.238397 137.565575,259.877572 140.775062,259.995862 L141,260 L233,260 C236.238397,260 238.877572,257.434425 238.995862,254.224938 L239,254 L239,196 C239,195.232811 238.856011,194.499253 238.593583,193.824875 L194.359931,227.91008 C190.112071,231.183434 184.221537,231.234047 179.924217,228.070946 L179.657738,227.869068 L135.550299,193.486412 C135.226709,194.186819 135.034172,194.960186 135.004138,195.775062 Z M233,190 L141,190 C140.054642,190 139.160348,190.218634 138.364925,190.608096 L182.116923,224.714323 C184.917559,226.897474 188.81037,226.967126 191.681532,224.917383 L191.918385,224.741665 L235.976871,190.789383 C235.160585,190.322025 234.223956,190.040959 233.224938,190.004138 L233,190 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
</template>
