import type { PaidItem } from "@/store/table";
import { PaymentMethod, Saletype } from "@/models/App";
import type { Order } from "@/models/Order";
import type { ArticleAttributeValue } from "@/models/Product";
import { get as apiGet, post as apiPost } from "./API";

export async function callWaiter(tableId: string) {
  return apiPost(`tables/${tableId}/call-waiter`, null);
}

export enum TableStatus {
  AVAILABLE = "AVAILABLE",
  TAKEN = "TAKEN",
  PAYING = "PAYING"
}

export interface TableData {
  orderId: string;
  currentSittings: number;
  maxSittings: number;
  name: string;
  status: TableStatus;
  priceLists: Array<{
    id: string;
    name: string;
  }>;
}
export async function check(tableID: string): Promise<TableData> {
  return apiGet(`tables/${tableID}/check`);
}

interface CloseData {
  paymentMethod: PaymentMethod;
  paidAmount?: number | undefined;
  dutchDivider?: number | undefined;
  dutchPaying?: number | undefined;
  payingItems?: PaidItem[] | undefined;
  isDeposit: boolean;
  isSplit: boolean;
  isDutch: boolean;
}
export interface CloseResponse {
  paymentGateway?: PaymentMethod;
  id: string;
  qrCode: string;
  paymentUrl?: string;
  paymentBody?: Record<string, any>;
  sdkApiUrl?: string;
  easyApiKey?: string;
  paymentId?: string;
}
export async function close(tableID: string, data: CloseData): Promise<CloseResponse> {
  return apiPost(`tables/${tableID}/close`, data, { requiresAuth: true });
}

export async function closeUnauthenticated(tableID: string, data: CloseData): Promise<CloseResponse> {
  return apiPost(`tables/${tableID}/close/unauthenticated`, data);
}

export async function getOrderInfo(tableID: string): Promise<Order> {
  return apiGet(`tables/${tableID}/order`);
}

interface OpenData {
  currentSittings: number;
}
export async function open(tableID: string, data: OpenData): Promise<void> {
  return apiPost(`tables/${tableID}/open`, data);
}

interface CreateTableOrderData {
  saleType: Saletype;
  note: string | null;
  priceListId: string;
  orderItems: Array<{
    productId: string;
    quantity: number;
    removedComponents: string[];
    addedComponents: string[];
    modules: Array<{
      name: string;
      choosenProducts: string[];
      upsellingProducts: string[];
    }>;
    articleAttributes: Array<{
      attributeTypeId: string;
      attributeValue: ArticleAttributeValue;
    }>;
  }>;
}
export async function order(tableID: string, data: CreateTableOrderData): Promise<void> {
  return apiPost(`tables/${tableID}/order`, data);
}
