<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M16.46 13.472c2.582.137 4.878 1.773 6 4.249l.116.269c1.27 2.665-1.175 5.188-4.099 5.3l-.2.003H5.562c-2.942 0-5.48-2.464-4.38-5.122l.08-.181c1.038-2.536 3.282-4.254 5.841-4.498l.276-.02h9.082zm-.077 1.963H7.45l-.16.013c-1.746.166-3.304 1.313-4.143 3.133l-.11.252c-.52 1.094.572 2.402 2.333 2.491l.192.005H18.24l.163-.003c1.756-.066 2.846-1.296 2.45-2.373l-.095-.22c-.759-1.855-2.36-3.092-4.165-3.281l-.21-.017zM11.647 1c1.52 0 2.976.575 4.05 1.598a5.328 5.328 0 0 1 1.679 3.858c0 2.937-2.435 5.331-5.487 5.452l-.242.005c-3.164 0-5.73-2.443-5.73-5.457 0-2.936 2.436-5.33 5.488-5.451L11.647 1zm0 1.964c-2.1 0-3.765 1.586-3.765 3.492 0 1.907 1.665 3.492 3.765 3.492s3.765-1.585 3.765-3.492c0-.905-.381-1.78-1.069-2.435a3.914 3.914 0 0 0-2.696-1.057z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
