<template>
  <svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd" transform="translate(-2 -2)">
      <path d="M0 0h24v24H0z" />
      <path
        d="M17 4a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h10zm0 1.4H7a2.6 2.6 0 0 0-2.595 2.436L4.4 8v8a2.6 2.6 0 0 0 2.436 2.595L7 18.6h10a2.6 2.6 0 0 0 2.595-2.436L19.6 16V8a2.6 2.6 0 0 0-2.436-2.595L17 5.4zM12 9a3.001 3.001 0 0 1 0 6 3.001 3.001 0 0 1 0-6zm-5.074-.06c.354 0 .648.313.648.691v4.616c0 .378-.294.692-.648.692-.355 0-.649-.314-.649-.692V9.63c0-.378.294-.692.649-.692zm10.243 0c.355 0 .649.313.649.691v4.616c0 .378-.294.692-.65.692-.354 0-.648-.314-.648-.692V9.63c0-.378.294-.692.649-.692zM12 10.2c-.992 0-1.8.808-1.8 1.8s.808 1.8 1.8 1.8 1.8-.808 1.8-1.8-.808-1.8-1.8-1.8z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
