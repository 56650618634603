<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="currentColor"
      d="M 23.127 18.819 C 23.609 18.819 24 19.21 24 19.692 C 24 20.174 23.609 20.564 23.127 20.564 L 0.873 20.564 C 0.391 20.564 0 20.174 0 19.692 C 0 19.21 0.391 18.819 0.873 18.819 L 23.127 18.819 Z M 20.727 16.419 C 21.209 16.419 21.6 16.81 21.6 17.292 C 21.6 17.774 21.209 18.164 20.727 18.164 L 3.273 18.164 C 2.791 18.164 2.4 17.774 2.4 17.292 C 2.4 16.81 2.791 16.419 3.273 16.419 L 20.727 16.419 Z M 10.8 4.528 C 10.499 4.528 10.255 4.284 10.255 3.982 C 10.255 3.681 10.499 3.437 10.8 3.437 L 13.2 3.437 C 13.501 3.437 13.745 3.681 13.745 3.982 C 13.745 4.284 13.501 4.528 13.2 4.528 L 12.764 4.528 L 12.764 5.21 C 15.291 5.393 17.679 6.478 19.482 8.282 C 21.447 10.246 22.559 12.904 22.582 15.68 L 22.582 15.764 L 1.418 15.764 C 1.418 10.14 5.806 5.54 11.345 5.202 L 11.345 4.528 L 10.8 4.528 Z M 12 6.71 C 7.549 6.71 3.849 9.921 3.089 14.153 L 3.074 14.237 L 20.925 14.237 L 20.924 14.234 C 20.614 12.425 19.759 10.741 18.457 9.417 L 18.403 9.362 C 16.704 7.664 14.401 6.71 12 6.71 Z"
    ></path>
  </svg>
</template>
