<template lang="pug">
Checkbox(v-model="verified" ref="$recaptcha")
</template>

<script setup lang="ts">
import { Checkbox } from "vue-recaptcha/head";
import { computed } from "vue";
import { ref } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  }
})

const emit = defineEmits(["update:modelValue"]);

const verified = computed({
  get: () => props.modelValue,
  set: (value: string) => emit("update:modelValue", value)
})

const $recaptcha = ref<{ reset(): Promise<void> } | null>(null);

const reset = async () => {
  if (!$recaptcha.value) return;
  console.log("resetting recaptcha");
  $recaptcha.value.reset();
};
</script>

<style lang="scss">
// recaptcha fix
@media screen and (max-width: 414px) {
    .g-recaptcha {
        position: relative;
        left: 50%;
        -o-transform: scale(0.8) translateX(-50%);
        -ms-transform: scale(0.8) translateX(-50%);
        -moz-transform: scale(0.8) translateX(-50%);
        -webkit-transform: scale(0.8) translateX(-50%);
        transform: scale(0.8) translateX(-50%);
        -o-transform-origin: left center;
        -ms-transform-origin: left center;
        -moz-transform-origin: left center;
        -webkit-transform-origin: left center;
        transform-origin: left center;
    }
}
</style>
