<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g>
        <path
          d="M12.137 1.126c6.02 0 10.9 4.88 10.9 10.9 0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9zm0 1.8a9.1 9.1 0 1 0 0 18.2 9.1 9.1 0 0 0 0-18.2zM12.6 6.5v5.388l3.803 3.803-1.06 1.06L11.1 12.5v-6h1.5z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
